import { WarningFilled } from '@ant-design/icons'
import { Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
  gap: 24px;

  .ant-typography {
    line-height: normal;
  }
`

export const WarningIcon = styled(WarningFilled)`
  color: #ff8b27;
  font-size: ${rem(45)};
`

export const Text = styled(Typography.Text)`
  color: #6c6c6c;
  font-size: ${rem(16)};

  strong {
    font-weight: 700;
  }
`
