import { useMutation, useQueryClient } from '@tanstack/react-query'

import { categoryQueries } from '../queries'

import { api } from '@/services'

interface CreateCategory {
  name: string
  keywords: string
  title: string
  description: string
  google_global_category_id: number
  product_type_vtex_id: number
  anymarket_id: number
}

export const createCategoriesErrorMessages = new Map([
  [
    'Value error, Keywords must be separated by comma',
    'Palavras Similares precisam ser separadas por vírgula.',
  ],
  ['Category name already exists.', 'O nome desta categoria já existe.'],
])

export const useCreateCategoriesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: CreateCategory) => {
      return api.post('/categories', data)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: categoryQueries.all() }),
  })
}
