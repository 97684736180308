import { ReactNode } from 'react'

import type { ModalProps as AntdModalProps } from 'antd'

import * as S from './styles'

interface ModalProps extends AntdModalProps {
  title?: string | ReactNode
}

export const BaseModal = ({ title, ...rest }: ModalProps) => {
  return <S.Modal centered title={<S.Header>{title}</S.Header>} maskClosable={false} {...rest} />
}
