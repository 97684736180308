import { useMutation, useQueryClient } from '@tanstack/react-query'

import { categoryQueries } from '../queries'

import { useCategoryFilters } from '@/app'
import { api } from '@/services'

interface ChangeCategoryVisibilityMutation {
  category_id: string
  status: boolean
}

export const useChangeCategoryVisibiltyMutation = () => {
  const queryClient = useQueryClient()
  const { filters } = useCategoryFilters()

  return useMutation({
    mutationFn: async ({ category_id, status }: ChangeCategoryVisibilityMutation) => {
      return api.patch(`/categories/${category_id}/visibility`, {
        is_active: status,
      })
    },
    onMutate: async (editedCategory) => {
      await queryClient.cancelQueries({ queryKey: categoryQueries.all() })

      const cachedCategoryList = queryClient.getQueryData(categoryQueries.list(filters).queryKey)

      if (cachedCategoryList) {
        const updatedCategoriesList = cachedCategoryList.categories.map((category) => {
          if (category.id === editedCategory.category_id) {
            return {
              ...category,
              is_active: !category.is_active,
            }
          }

          return category
        })

        queryClient.setQueryData(categoryQueries.list(filters).queryKey, {
          ...cachedCategoryList,
          categories: updatedCategoriesList,
        })
      }

      return cachedCategoryList
    },
    onError: (error, _, cachedCategories) => {
      console.log(error)

      if (cachedCategories) {
        queryClient.setQueryData(categoryQueries.list(filters).queryKey, cachedCategories)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: categoryQueries.all() }),
  })
}
