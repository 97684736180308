import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../../queries'
import type { CreateProductMutation } from './create-product'

import { api } from '@/services'

export interface UpdateProductMutation extends CreateProductMutation {
  productId: string
}

export const useUpdateProductMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, ...data }: UpdateProductMutation) => {
      return api.put(`/products/${productId}/draft`, data)
    },
    onSettled: (_, __, { productId }) => queryClient.invalidateQueries(bgQueries.detail(productId)),
  })
}
