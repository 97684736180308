/**
 * Truncates a string to a specified maximum length and adds an ellipsis if necessary.
 * @param str The input string to truncate.
 * @param maxLength The maximum allowed length of the string.
 * @returns The truncated string with an ellipsis if it exceeds the maximum length.
 */

export function truncateString(str: string, maxLength: number): string {
  // Check if the string is already shorter than or equal to the maximum length
  if (str.length <= maxLength) {
    return str
  }

  // Calculate the length of the truncated string (leaving room for the ellipsis)
  const truncatedLength = maxLength - 3

  // Truncate the string and add the ellipsis
  return str.slice(0, truncatedLength) + '...'
}
