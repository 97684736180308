import { CameraOutlined } from '@ant-design/icons'
import { Typography, Divider as DividerAntd } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  .ant-typography {
    line-height: normal;
  }
`

export const Content = styled.div`
  display: flex;
  gap: 12px;
`

// export const ImageContainer = styled.div`
//   display: flex;
//   align-items: center;

//   width: ${rem(90)};
//   height: ${rem(80)};
//   border-radius: 6px;

//   box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
//   background-color: #fff;
// `

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;

  max-height: 250px;
  overflow-y: auto;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: #f6f8fa;
  border: 1px solid #dce0e5;
  /* box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25); */

  padding: ${rem(14)};
  border-radius: 6px;
  gap: 8px;

  line-height: initial;

  li {
    line-height: normal;
  }

  img {
    align-items: center;

    width: ${rem(90)} !important;
    height: ${rem(80)} !important;
    border-radius: 6px;
  }
`
export const Title = styled.span`
  display: flex;
  color: #00000099;
  padding-bottom: 10px;
`

export const Divider = styled(DividerAntd)`
  align-items: center;
  height: ${rem(65)};
`

export const Summary = styled.ul`
  width: 100%;
  color: #434343;
  font-size: ${rem(12)};

  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ProductCategory = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;
`

export const PartNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 26%;
`

export const SummaryDisplayer = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
  overflow-y: auto;
`

export const DisplayName = styled(Typography.Text)`
  width: 100%;
  font-weight: 700;

  color: #434343;
`

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-bottom: 32px;
`

export const FallbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f6f6f6;

  height: ${rem(152)};
  width: ${rem(174)};
`

export const FallbackIcon = styled(CameraOutlined)`
  font-size: 34px;
  color: ${({ theme }) => theme.colors.darkGreen};
`
