const productTypesRemap = new Map([
  ['NOTEBOOK', 'Notebook'],
  ['NOTEBOOKS', 'Notebook'],
  ['COMPUTADOR', 'Computador'],
  ['MONITOR', 'Monitor'],
  ['MONITORES', 'Monitor'],
])

export const formatProductType = (productType: string) => {
  return productTypesRemap.get(productType) || productType
}
