import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, toastError } from '@/common'
import { BaseModal } from '@/components'
import {
  type SpecificationFormData,
  useCreateSpecificationsMutation,
  SpecificationForm,
} from '@/features/specifications'

type CreateSpecificationsModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateSpecificationsModal = ({ isOpen, onClose }: CreateSpecificationsModalProps) => {
  const [form] = Form.useForm<SpecificationFormData>()

  const createSpecificationMutation = useCreateSpecificationsMutation()

  async function handleCreateSpecifications(data: SpecificationFormData) {
    try {
      const promise = createSpecificationMutation.mutateAsync(data)

      toast.promise(promise, {
        loading: 'Salvando Especificação...',
        success: () => {
          onClose()

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Especificação <span style={{ fontWeight: 'bold' }}>salva</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Erro ao salvar especificação.',
          }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Criar Especificação'}
      width={717}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={onClose}
            disabled={createSpecificationMutation.isPending}
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
            disabled={createSpecificationMutation.isPending}
          >
            Salvar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        requiredMark
        layout="vertical"
        onFinish={handleCreateSpecifications}
        disabled={createSpecificationMutation.isPending}
      >
        <SpecificationForm />
      </Form>
    </BaseModal>
  )
}

export { CreateSpecificationsModal }
