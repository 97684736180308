import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '@/services'
import { notificationQueries } from '../queries'

export const useClearAllNotificationsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      return api.patch('/notifications/mark_all_as_read')
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: notificationQueries.all() }),
  })
}
