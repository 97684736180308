import { BsExclamationTriangleFill } from 'react-icons/bs'

import { Button as AntdButton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const ExclamationIcon = styled(BsExclamationTriangleFill)`
  font-size: ${rem(40)};
  color: #ff8b27;
`

export const ModalMessage = styled.span`
  font-size: 16px;
  text-align: center;
  color: #6c6c6c;
  strong {
    font-weight: 700;
  }
`

export const CancelButton = styled(AntdButton)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
`
