import { useMutation, useQueryClient } from '@tanstack/react-query'


import { specificationQueries } from '../queries'

import { useSpecificationFilters } from '@/app'
import { api } from '@/services'

interface ChangeSpecificationVisibilityMutation {
  specification_id: string
  status: boolean
}

export const useChangeSpecificationVisibilityMutation = () => {
  const queryClient = useQueryClient()
  const { filters } = useSpecificationFilters()

  return useMutation({
    mutationFn: async ({ specification_id, status }: ChangeSpecificationVisibilityMutation) => {
      return api.patch(`specifications/${specification_id}/visibility`, {
        is_active: status,
      })
    },
    onMutate: async (editedSpecification) => {
      await queryClient.cancelQueries({ queryKey: specificationQueries.all() })

      const cachedSpecificationsList = queryClient.getQueryData(
        specificationQueries.list(filters).queryKey,
      )

      if (cachedSpecificationsList) {
        const updatedSpecificationsList = cachedSpecificationsList.specifications.map(
          (specification) => {
            if (specification.id === editedSpecification.specification_id) {
              return {
                ...specification,
                is_active: !specification.is_active,
              }
            }

            return specification
          },
        )

        queryClient.setQueryData(specificationQueries.list(filters).queryKey, {
          ...cachedSpecificationsList,
          specifications: updatedSpecificationsList,
        })
      }

      return cachedSpecificationsList
    },

    onError: (error, _, previousSpecifications) => {
      console.log(error)

      if (previousSpecifications) {
        queryClient.setQueryData(
          specificationQueries.list(filters).queryKey,
          previousSpecifications,
        )
      }
    },
    onSettled: () => queryClient.invalidateQueries(specificationQueries.list()),
  })
}
