import { Drawer as AntdDrawer, Button, Input, Select as AntdSelect, Skeleton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-header {
    padding: 0px 20px;
  }

  .ant-drawer-body {
    padding: 24px;
  }

  .ant-drawer-title {
    font-size: 15px;
    font-weight: 800;
  }

  .ant-drawer-extra {
    color: ${({ theme }) => theme.colors.brand};

    :hover {
      color: rgba(94, 163, 32, 0.7);
    }
  }

  .ant-drawer-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  .ant-tabs-tab {
    font-size: ${rem(16)};
    line-height: ${rem(18)};
  }
`

export const CloseButton = styled(Button)`
  color: ${({ theme }) => theme.colors.brand};
  border: 1px solid ${({ theme }) => theme.colors.brand};

  :hover {
    color: rgba(94, 163, 32, 0.6);
  }
`

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .ant-input-search-button {
    border-left: none;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-color: ${({ theme }) => theme.colors.brand};
  }

  .ant-input-group > .ant-input:first-child {
    border-color: ${({ theme }) => theme.colors.brand};
    border-right: none;
    box-shadow: none;

    :hover {
      border-right: none;
    }
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;

  line-height: 32px;

  .copy-button {
    color: transparent;
    background-color: transparent;
  }

  &:hover .copy-button {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const TextKey = styled.span`
  color: #00000099;
  flex: 1;

  line-height: 13px;
`

export const TextDescription = styled.span`
  color: #434343;
  flex: 1;

  font-weight: 600;
  padding-left: 4px;
`

export const SearchInput = styled(Input.Search)`
  height: 30px;
  margin-bottom: 30px;
  color: #434343 !important;
  transition: all 0.6s ease-in-out;
  width: 100%;
`

export const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .select,
  .input {
    width: 100%;
    margin-bottom: 0;
  }

  .ant-form-item-label > label {
    color: #6c6c6c;
  }

  .ant-form-item .ant-form-item-control-input-content {
    height: 34px;
  }
`

export const DescriptionText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ul {
    li {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin: 8px 0;
    }
  }
`

export const ExampleText = styled.strong`
  color: #5f5f5f;
`

export const ValuesContainer = styled.div``

export const Select = styled(AntdSelect)`
  width: 100%;
`

export const SearchContent = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 12px;
`

export const SearchValueTitle = styled.h3`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
`

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 30%;
`

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SkeletonContent = styled.div`
  display: flex;
  gap: 12px;
`

export const SkeletonInput = styled(Skeleton.Input)`
  width: 334px !important;
`

export const ListValueTitle = styled(SearchValueTitle)`
  margin: 12px 0;
`
