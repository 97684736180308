import { persist, devtools, createJSONStorage } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface ProductTypeFilters {
  per_page?: number
  page?: number
}

interface ProductTypeFilterStore {
  filters: ProductTypeFilters
  resetFilters: () => void
  setFilters: (filters: ProductTypeFilters) => void
}

const productTypeFiltersStore = createWithEqualityFn<ProductTypeFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          per_page: 10,
          page: 1,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-filters',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                per_page: 10,
                page: 1,
              },
            },
            false,
            'reset-filters',
          )
        },
      }),
      { name: 'product-types-filter-store', storage: createJSONStorage(() => sessionStorage) },
    ),
    { name: 'product-types-filter-store' },
  ),
  shallow,
)

export const resetProductTypeFilters = productTypeFiltersStore.getState().resetFilters

export { productTypeFiltersStore, productTypeFiltersStore as useProductTypeFilters }
