import { ReactElement } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Form, Image, Input, Spin, Tooltip, Upload, UploadFile, UploadProps } from 'antd'
// import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { toast } from 'sonner'

import * as S from './styles'

import UploadImage from '@/assets/upload-image.svg'
import { MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_IMAGE_COUNT, useUploadImagePreview } from '@/common'
import { BgFileUploadData, UploadStatus, useS3Url, validateFilesBeforeUpload } from '@/features/BG'

type KitImageUploadProps = {
  kitImagesData?: BgFileUploadData[]
  setKitImagesData: React.Dispatch<React.SetStateAction<BgFileUploadData[]>>
  kitUploadStatus: UploadStatus
  setKitUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
}

export const KitImageUpload = ({
  kitImagesData = [],
  setKitImagesData,
  kitUploadStatus,
  setKitUploadStatus,
}: KitImageUploadProps) => {
  // const form = useFormInstance()

  const fetchS3Url = useS3Url()
  const { handlePreview, previewImage, previewOpen, togglePreview } = useUploadImagePreview()

  async function handleUploadFile(file: File) {
    if (kitUploadStatus === 'uploading') return

    setKitUploadStatus('uploading')

    try {
      const result = await fetchS3Url(file)

      setKitImagesData((oldData) => [...oldData, result])

      toast.success(
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            {file.name} <span style={{ fontWeight: 'bold' }}>carregado</span> com{' '}
            <span style={{ fontWeight: 'bold' }}>sucesso</span>
          </div>
        </S.ToastContainer>,
        {
          position: 'bottom-right',
          className: 'toast-sucess',
        },
      )
    } catch (error) {
      console.error('Error uploading kit image:', error)
      toast.error(`Erro ao carregar arquivo ${file.name}`)
    } finally {
      setKitUploadStatus('done')
    }
  }

  const uploaderProps: UploadProps = {
    multiple: true,
    action: '',
    showUploadList: true,
    maxCount: MAX_UPLOAD_IMAGE_COUNT,
    listType: 'picture-card',
    accept: '.jpg, .png, .jpeg',
    defaultFileList: kitImagesData.map((image) => {
      const url = image.file ? URL.createObjectURL(image.file as File) : image.url

      return {
        ...image,
        name: image.file?.name || image.filename,
        uid: image.filename,
        thumbUrl: url,
      }
    }),
    onPreview: handlePreview,
    beforeUpload: (file: File, fileList: UploadFile[]) => {
      const validationResult = validateFilesBeforeUpload(
        file,
        fileList,
        kitImagesData,
        MAX_UPLOAD_IMAGE_COUNT,
        MAX_UPLOAD_FILE_SIZE,
      )

      if (validationResult) {
        handleUploadFile(file)

        return false
      }

      return Upload.LIST_IGNORE
    },
    onChange: ({ fileList }) => {
      const hasMaximunFiles = fileList.length >= MAX_UPLOAD_IMAGE_COUNT

      if (hasMaximunFiles) {
        toast.info(`Limite de ${MAX_UPLOAD_IMAGE_COUNT} imagens atingido.`)
      }
    },
    onRemove: (file: UploadFile) => {
      let newKitImagesData: BgFileUploadData[] = []

      // if (hasInitialValues) {
      //   newKitImagesData = kitImagesData.filter((image) => image.filename !== file.uid)

      //   const excludedFiles = form.getFieldValue('excludedFiles') || []

      //   form.setFieldValue('excludedFiles', [...excludedFiles, file.uid])
      // } else {
      if (file.thumbUrl) {
        URL.revokeObjectURL(file.thumbUrl as string)
      }

      newKitImagesData = kitImagesData.filter((image) => {
        return image?.file?.name !== file.name
      })
      // }

      setKitImagesData(newKitImagesData)
    },
  }

  const ImageRender = (originNode: ReactElement, file: UploadFile) => {
    return (
      <S.UploadedImageContainer>
        {originNode}
        <Tooltip title={file.name}>
          <S.ImageName>{file.name}</S.ImageName>
        </Tooltip>
      </S.UploadedImageContainer>
    )
  }

  return (
    <S.Card>
      {previewImage && (
        <Image
          src={previewImage}
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: togglePreview,
            // afterOpenChange: (visible) => !visible && resetPreviewImage(),
          }}
        />
      )}

      <S.CardContent>
        <S.SecondStepFormContainer>
          <S.UploaderIntruction>
            Adicione um total de 10 imagens para o kit.{' '}
            <strong>({kitImagesData?.length}/10)</strong>
          </S.UploaderIntruction>

          <S.UploadContainer>
            <Form.Item
              name="kitImage"
              rules={[{ required: true, message: 'Insira pelo menos uma imagem para o kit.' }]}
            >
              <Upload
                itemRender={ImageRender}
                disabled={kitUploadStatus === 'uploading'}
                {...uploaderProps}
              >
                {(!kitUploadStatus || kitUploadStatus === 'done') && (
                  <S.UploadStatusContainer $isDone={kitImagesData?.length >= 10}>
                    <img src={UploadImage} alt="upload kit" />
                  </S.UploadStatusContainer>
                )}

                {kitUploadStatus === 'uploading' && (
                  <S.LoadingStatusContainer>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                  </S.LoadingStatusContainer>
                )}
              </Upload>
            </Form.Item>
          </S.UploadContainer>

          <Form.Item
            label={<S.Label>Dealer page (URL)</S.Label>}
            name="dealer_page_url"
            rules={[
              { required: true, message: 'Por favor, insira uma dealer page.' },
              { type: 'url', message: 'Insira uma URL válida.' },
            ]}
          >
            <Input placeholder="Ex: https://www.acer/packimages" />
          </Form.Item>
        </S.SecondStepFormContainer>
      </S.CardContent>
    </S.Card>
  )
}
