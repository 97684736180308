import { Typography } from 'antd'
import styled from 'styled-components'

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`

export const DescriptionTitle = styled(Typography.Text)`
  color: rgba(48, 48, 48, 0.85);
  font-weight: 700;

  span {
    font-weight: 400;
    color: #666666;
  }
`

export const DescriptionText = styled(Typography.Text)`
  color: rgba(48, 48, 48, 0.85);
  padding-bottom: 4px;
`

export const ExampleText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.blue};
`
