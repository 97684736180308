import { Skeleton } from 'antd'

import * as S from './styles'

export const ProductPageSkeleton = () => {
  return (
    <>
      <S.Container>
        <Skeleton.Input active style={{ height: 322, width: 300 }} />

        <S.InfoContainer>
          <S.StatusContainer>
            <Skeleton.Input active />
          </S.StatusContainer>

          <S.SummaryContainer>
            <S.DisplayName>
              <Skeleton.Input active block />
            </S.DisplayName>

            <S.Summary>
              <Skeleton.Input active style={{ height: 200 }} block />
            </S.Summary>
          </S.SummaryContainer>
        </S.InfoContainer>
      </S.Container>

      <S.SkeletonTab active />

      <Skeleton active />
    </>
  )
}
