import { useCallback, useMemo } from 'react'

import { useRolesStore } from '@/app'

export const useAuth = () => {
  const userRoles = useRolesStore((state) => state.userRoles)
  const userAllowedModules = useRolesStore((state) => state.allowedModules)
  const isSystemAdmin = useRolesStore((state) => state.isSystemAdmin)
  const getUserRoles = useRolesStore((state) => state.getUserRoles)

  const roles = useMemo(() => userRoles || getUserRoles(), [getUserRoles, userRoles])

  const formatPagePermissions = useCallback(
    (permissions: string[] | undefined) => {
      let isAdmin = false
      let canRead = false // G
      let canCreate = false // P
      let canUpdate = false // U
      let canDelete = false // D

      if (permissions && permissions.length > 0) {
        isAdmin = isSystemAdmin || permissions.includes('*')

        canRead = isSystemAdmin || isAdmin || permissions.includes('g')
        canCreate = isSystemAdmin || isAdmin || permissions.includes('p')
        canUpdate = isSystemAdmin || isAdmin || permissions.includes('u')
        canDelete = isSystemAdmin || isAdmin || permissions.includes('d')
      }

      return { isAdmin, canRead, canCreate, canUpdate, canDelete }
    },
    [isSystemAdmin],
  )

  return {
    userRoles: roles,
    userAllowedModules,
    formatPagePermissions,
  }
}
