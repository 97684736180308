import type { Category } from '..'

interface GroupedCategory {
  [key: string]: Category[]
}

const categoryGroupName = new Map([
  ['MONITOR', 'Monitor'],
  ['MONITORES', 'Monitor'],
  ['NOTEBOOK', 'Notebook'],
  ['NOTEBOOKS', 'Notebook'],
  ['COMPUTADOR', 'Computador'],
])

export const formatCategories = (categories: Category[]) => {
  const formattedCategories = categories.reduce((grouped: GroupedCategory, category) => {
    const productTypeName =
      categoryGroupName.get(category.product_type.name) || category.product_type.name

    if (!grouped[productTypeName]) {
      grouped[productTypeName] = []
    }

    grouped[productTypeName].push(category)
    return grouped
  }, {})

  return formattedCategories
}
