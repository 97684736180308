import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import { PlatformsHealth } from '../types'

import { api } from '@/services'

const PLATFORM_REFRESH_INTERVAL = Number(import.meta.env.VITE_VTEX_STATUS_REFRESH_INTERVAL) || 60000 // 1 minute

export const fetchPlatformStatus = async () => {
  const { data } = await api.get<PlatformsHealth>('/health/integrations')

  return data
}

export const platformQueries = {
  all: () => ['platform'],
  statuses: () => [...platformQueries.all(), 'status'],
  status: () =>
    queryOptions({
      queryKey: [...platformQueries.statuses()],
      queryFn: fetchPlatformStatus,
      placeholderData: keepPreviousData,
      refetchInterval: PLATFORM_REFRESH_INTERVAL,
    }),
}
