import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Typography } from 'antd'

import * as S from './styles'

const PageErrorBoundary = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const isHome = location.pathname === '/dashboard'

  function returnToHome() {
    navigate('/dashboard')
  }

  return (
    <S.ErrorContainer>
      <S.ExclamationIcon />

      <Typography.Text>Ocorreu um erro ao carregar a página</Typography.Text>

      {!isHome ? (
        <Button type="primary" onClick={returnToHome}>
          Voltar para a página inicial
        </Button>
      ) : (
        <Button type="primary" onClick={() => window.location.reload()}>
          Recarregar página
        </Button>
      )}
    </S.ErrorContainer>
  )
}

export { PageErrorBoundary }
