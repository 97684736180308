import { useQuery } from '@tanstack/react-query'
import { Form, Select } from 'antd'

import * as S from './styles'

import { categoryQueries } from '@/features/categories'

type BGDataFormProps = {
  productType: {
    vtex_id: number | undefined
    name: string | undefined
  }
}

const BGDataForm = ({ productType }: BGDataFormProps) => {
  const form = Form.useFormInstance()

  const { data: categoriesResponse, isLoading } = useQuery({
    ...categoryQueries.list({
      page: 1,
      per_page: 100,
      product_type_vtex_id: productType.vtex_id,
    }),
    enabled: !!productType.vtex_id,
  })

  const categorySelectOptions = [
    {
      label: productType.name,
      options: categoriesResponse?.categories.map((category) => ({
        key: category.id,
        label: category.name,
        value: category.id,
      })),
    },
  ]

  const productNameLength = Form.useWatch('productName', form)?.length || 0
  const skuNameLength = Form.useWatch('skuName', form)?.length || 0
  const descriptionTitleTagLength = Form.useWatch('descriptionTitleTag', form)?.length || 0

  return (
    <S.FormBox>
      <S.Disclaimer>Todos os campos são de preenchimento obrigatório</S.Disclaimer>

      <S.RowBox>
        <S.FormItem
          name="productId"
          label={<S.Label>Id do Produto</S.Label>}
          width="15%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <S.Input type="text" disabled />
        </S.FormItem>

        <S.FormItem
          name="productName"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            {
              max: 150,
              message: 'O nome do produto deve ter no máximo 150 caracteres.',
            },
          ]}
          label={
            <S.Label>
              Nome do Produto <span>({productNameLength}/150)</span>
            </S.Label>
          }
          width="30%"
        >
          <S.Input type="text" maxLength={150} />
        </S.FormItem>

        <S.FormItem
          name="skuName"
          label={
            <S.Label>
              Nome do SKU <span>({skuNameLength}/150)</span>
            </S.Label>
          }
          width="25%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            {
              max: 150,
              message: 'O nome do SKU deve ter no máximo 150 caracteres.',
            },
          ]}
        >
          <S.Input type="text" placeholder="Nome do SKU" maxLength={150} />
        </S.FormItem>

        <S.FormItem
          name="sku"
          label={<S.Label>SKU</S.Label>}
          width="15%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <S.Input type="text" disabled />
        </S.FormItem>

        <S.FormItem
          name="partNumber"
          label={<S.Label>Part Number</S.Label>}
          width="15%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <S.Input type="text" disabled />
        </S.FormItem>
      </S.RowBox>

      <S.RowBox>
        <S.FormItem
          name="similarWords"
          label={<S.Label>Palavras Similares</S.Label>}
          width="25%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <S.Input type="text" placeholder="Selecione Categorias do Produto" />
        </S.FormItem>

        <S.FormItem
          name="textLink"
          label={<S.Label>TextLink</S.Label>}
          width="60%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <S.Input type="text" />
        </S.FormItem>

        <S.FormItem
          name="pageTitle"
          label={<S.Label>Título da Página (Title Tag)</S.Label>}
          width="40%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <S.Input type="text" />
        </S.FormItem>
      </S.RowBox>

      <S.RowBox>
        <S.FormItem
          name="brand"
          label={<S.Label>Marca</S.Label>}
          width="15%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <S.Input type="text" disabled />
        </S.FormItem>

        <S.FormItem
          name="category"
          label={<S.Label>Categoria</S.Label>}
          width="30%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Select className="typeSelect" options={categorySelectOptions} loading={isLoading} />
        </S.FormItem>

        <S.FormItem
          name="descriptionTitleTag"
          label={
            <S.Label>
              Descrição (Meta Tag Description) <span>({descriptionTitleTagLength}/150)</span>
            </S.Label>
          }
          width="60%"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            {
              max: 150,
              message: 'A descrição deve ter no máximo 150 caracteres.',
            },
          ]}
        >
          <S.Input type="text" maxLength={150} />
        </S.FormItem>
      </S.RowBox>

      <S.TextAreaBox>
        <S.FormItem
          name="descriptionTechnicalSheet"
          label={<S.Label>Descrição (Ficha Técnica)</S.Label>}
          width="100%"
          required={false}
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            {
              min: 5,
              message: 'A descrição técnica deve ter no mínimo 5 caracteres.',
            },
          ]}
        >
          <S.TextArea rows={8} />
        </S.FormItem>
      </S.TextAreaBox>
    </S.FormBox>
  )
}

export { BGDataForm }
