import { useState } from 'react'

import { Checkbox } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { truncateString } from '@/common'
import { BaseTable, TooltipTable } from '@/components'
import { Agency } from '@/features/agency'

interface SelectAgenciesTableProps {
  data: Agency[] | undefined
  isLoading?: boolean
  onSelectAgencies: (agencies: string[]) => void
}

export const SelectAgenciesTable = ({
  data,
  isLoading,
  onSelectAgencies,
}: SelectAgenciesTableProps) => {
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>([])

  const handleCheckboxChange = (agencyId: string) => {
    setSelectedAgencies((prevSelected) => {
      const newSelect = prevSelected.includes(agencyId)
        ? prevSelected.filter((id) => id !== agencyId)
        : [...prevSelected, agencyId]
      onSelectAgencies(newSelect)
      return newSelect
    })
  }

  const handleSelectAll = () => {
    const currentPageIds = data?.map((agency) => agency._id) || []
    const allSelected = currentPageIds.every((id) => selectedAgencies.includes(id))

    setSelectedAgencies((prevSelected) => {
      const updatedSelection = allSelected
        ? prevSelected.filter((id) => !currentPageIds.includes(id))
        : [...prevSelected, ...currentPageIds.filter((id) => !prevSelected.includes(id))]

      onSelectAgencies(updatedSelection)
      return updatedSelection
    })
  }

  const columns: ColumnsType<Agency> = [
    {
      title: (
        <S.Row>
          <Checkbox
            checked={data?.every((agency) => selectedAgencies.includes(agency._id))}
            onChange={handleSelectAll}
            style={{ marginRight: 8 }}
          />
          Agência
        </S.Row>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (_, agency) => (
        <S.Row>
          <Checkbox
            checked={selectedAgencies.includes(agency._id)}
            onChange={() => handleCheckboxChange(agency._id)}
            style={{ marginRight: 8 }}
          />
          {truncateString(agency.name, 30)}
        </S.Row>
      ),
    },
    {
      title: 'Responsável',
      dataIndex: 'responsibles',
      key: 'responsibles',
      render: (_, agency) => {
        return (
          <div style={{ display: 'flex' }}>
            <TooltipTable
              displayItem={agency.responsibles[0]?.name}
              data={agency.responsibles}
              record={agency}
              columns={[
                {
                  title: 'Responsável',
                  key: 'name',
                  dataIndex: 'name',
                  width: 150,
                  render: (name) => truncateString(name, 30),
                },
                {
                  title: 'Email',
                  key: 'email',
                  dataIndex: 'email',
                  width: 150,
                  render: (email) => truncateString(email, 32),
                },
              ]}
            />
          </div>
        )
      },
    },
  ]

  return (
    <BaseTable
      columns={columns}
      pagination={false}
      loading={isLoading}
      rowKey={(record) => record._id}
      dataSource={data}
    />
  )
}
