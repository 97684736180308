import { useQueries } from '@tanstack/react-query'
import { Image, Skeleton } from 'antd'

import { BgStatusTags, BgHistoryTable } from '../..'
import * as S from './styles'

import { BaseModalProps, imgFallback, truncateString } from '@/common'
import { BaseModal } from '@/components'
import { bgQueries, formatProductType, hasHeroImage } from '@/features/BG'

interface BgHistoryModalProps extends BaseModalProps {
  productId: string
}

export const BgHistoryModal = ({ isOpen, onClose, productId }: BgHistoryModalProps) => {
  const { product, history, isPending } = useQueries({
    queries: [bgQueries.detail(productId), bgQueries.timeline(productId)],
    combine: (result) => {
      const [product, history] = result

      return {
        product: product.data,
        history: history.data,
        isPending: result.some((r) => r.isPending),
      }
    },
  })

  const SkeletonLoading = () => {
    return (
      <S.SkeletonContainer>
        <Skeleton active />
        <S.Content>
          <S.SummaryContainer>
            <Skeleton.Image active style={{ height: 120, width: 120 }} />
            <Skeleton active />
          </S.SummaryContainer>
        </S.Content>
        <Skeleton active />
      </S.SkeletonContainer>
    )
  }

  return (
    <BaseModal open={isOpen} onCancel={onClose} title={'Histórico do BG'} width={917} footer={null}>
      {isPending ? (
        <SkeletonLoading />
      ) : (
        <S.Container>
          <strong>{truncateString(product?.name || '--', 90)}</strong>
          <S.Content>
            <S.SummaryContainer>
              <Image
                src={
                  hasHeroImage(product?.status)
                    ? product?.partner_files?.hero_image_partner_url
                    : product?.sku?.sku_files?.[0]?.filename_url
                }
                fallback={imgFallback}
                style={{ objectFit: 'contain', borderRadius: 6 }}
              />
              <S.Divider type="vertical" />

              <S.StatusContainer>
                <S.Title>Status</S.Title>
                <BgStatusTags status={product?.status} />
              </S.StatusContainer>
              <S.Divider type="vertical" />

              <S.ProductCategory>
                <S.Title>Produto</S.Title>
                <S.DisplayName>
                  {formatProductType(product?.product_type_name as string) || '--'}
                </S.DisplayName>
              </S.ProductCategory>
              <S.Divider type="vertical" />

              <S.ProductCategory>
                <S.Title>Categoria</S.Title>
                <S.DisplayName>{product?.category_name}</S.DisplayName>
              </S.ProductCategory>
              <S.Divider type="vertical" />

              <S.PartNumberContainer>
                <S.Title>Part Number</S.Title>
                <S.DisplayName>{product?.ref_id}</S.DisplayName>
              </S.PartNumberContainer>

              {/* {product?.overview !== null && product?.overview !== '<p></p>' ? (
                <S.Divider type="vertical" />
              ) : (
                <></>
              )}
              {product?.overview !== null && product?.overview !== '<p></p>' ? (
                <S.SummaryDisplayer>
                  <S.Title>Resumo</S.Title>
                  <S.Summary>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncateString(
                          DOMPurify.sanitize(
                            String(
                              product?.overview === null || product?.overview === '<p></p>'
                                ? '-'
                                : product?.overview,
                            ),
                          ),
                          120,
                        ),
                      }}
                      style={{ fontWeight: 700, color: '#434343' }}
                    />
                  </S.Summary>
                </S.SummaryDisplayer>
              ) : (
                <></>
              )} */}
            </S.SummaryContainer>
          </S.Content>
          <BgHistoryTable
            data={history || []}
            rowKey={(record, i) => `${record.id}-${i}`}
            loading={isPending}
            pagination={false}
          />
        </S.Container>
      )}
    </BaseModal>
  )
}
