import { Typography } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-table table {
    font-size: 14px;
  }

  .ant-table th {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  .ant-table td {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
    border: 1px solid #dce0e5;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0px;
    background-color: #fcfcfc;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 700;
    font-size: 14px;

    &::before,
    &::after {
      display: none !important;
    }
  }

  .ant-table-wrapper .ant-table-container table > thead {
    tr:first-child > *:first-child {
      border-top-left-radius: 4px !important;
    }

    tr:first-child > *:last-child {
      border-top-right-radius: 4px !important;
    }
  }

  .ant-table-column-title {
    white-space: nowrap;
  }

  .ant-table-cell {
    padding: 14px !important;
  }

  .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #eaf0f9;
  }

  .ant-table-wrapper .ant-table-column-sort {
    background: none;
  }

  .ant-table-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 0;
  }

  .status-column {
    background-color: #f6f8fa;
    border-right: 1px solid #dce0e5;
  }

  .custom-row .ant-table-cell:not(.status-column) {
    background-color: #ffffff;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dce0e5;
  }
`

export const TableDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 89%;
`
export const DescriptionDate = styled(Typography.Text)`
  color: #303030;
  font-size: 12px;
  font-weight: 700;
`
export const DescriptionText = styled(Typography.Text)`
  color: #666666;
  font-size: 12px;
  font-weight: 400;
`
