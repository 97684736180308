import * as S from './styles'

export const BgRegisterFirstStepHelp = () => {
  return (
    <>
      <S.DescriptionContent>
        <S.DescriptionTitle>1. Campo tipo de Produto:</S.DescriptionTitle>

        <S.DescriptionText>Selecione o tipo que produto que vai ser cadastrado.</S.DescriptionText>

        <S.ExampleText>Ex: Notebook</S.ExampleText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>2. Campo Part Number</S.DescriptionTitle>

        <S.DescriptionText>
          Informe manualmente qual o Part Number (PN) do produto que está sendo cadastrado. O PN
          deve seguir o formato utilizado pela Acer: campo numérico separado por pontos.
        </S.DescriptionText>

        <S.ExampleText>Ex: NH.QM1AL.00A</S.ExampleText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>3. Campo Categoria:</S.DescriptionTitle>

        <S.DescriptionText>Selecione a categoria do produto.</S.DescriptionText>

        <S.ExampleText>Ex: Gamer</S.ExampleText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>4. Campo Modelo:</S.DescriptionTitle>

        <S.DescriptionText>
          Informe manualmente qual o modelo do produto. Siga as regras utilizadas pela Acer: número
          alfa numérico separado por hífen.
        </S.DescriptionText>

        <S.ExampleText>Ex: A515-54G-79TC</S.ExampleText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>5. Campo Família:</S.DescriptionTitle>

        <S.DescriptionText>
          Informe manualmente qual a família que o produto pertence. Separe os nomes por hífen.
        </S.DescriptionText>

        <S.ExampleText>Ex: Acer-Nitro-5</S.ExampleText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>
          6. Campo Template <span>(Opcional)</span>:
        </S.DescriptionTitle>

        <S.DescriptionText>
          Selecione, se for o caso, um template previamente preenchimento durante o cadastro do
          produto selecionado.
        </S.DescriptionText>
      </S.DescriptionContent>
    </>
  )
}
