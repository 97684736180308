import { CheckCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const Typography = styled.span`
  font-size: ${rem(14)};
  color: rgba(0, 0, 0, 0.6);
`

export const UploadFilesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .ant-upload-list-item-action.ant-btn {
    width: 100px;
  }

  .ant-upload-list-item.ant-upload-list-item-undefined {
    height: 32px;
    text-align: center;
    width: 588px;
  }

  .ant-upload-wrapper .ant-upload-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 592px;
  }

  .ant-upload-list-item-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 34px !important;
  }

  .ant-upload-list-item-name {
    text-align: start;
    padding-left: 8px;
    text-overflow: ellipsis;
  }

  .ant-upload-list.ant-upload-list-picture .ant-upload-list-item {
    height: 60px;
    margin: 0;
  }
  .ant-btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px !important;
    width: ${rem(672)};
  }

  .ant-btn-icon {
    color: ${({ theme }) => theme.colors.linkActive};
  }
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
