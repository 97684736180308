import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: ${rem(20)};
  padding-right: ${rem(16)};
  padding-left: ${rem(16)};
  padding-top: ${rem(12)};
  padding-bottom: ${rem(12)};
  border: 1px solid #d9d9d9;
  border-bottom: 0px transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  background-color: white;

  .is-button-active {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.darkGreen};
    border: 0px transparent;
  }
  .is-button-disabled {
    background-color: transparent;
    border: 0px transparent;
  }
`

export const ToolbarBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  flex-wrap: wrap;
`
