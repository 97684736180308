import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, toastError } from '@/common'
import { BaseModal } from '@/components'
import {
  createCategoriesErrorMessages,
  useCreateCategoriesMutation,
  CategoryForm,
  type CategoryFormData,
} from '@/features/categories'

type CreateCategoryModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const CreateCategoryModal = ({ isOpen, onClose }: CreateCategoryModalProps) => {
  const [form] = Form.useForm<CategoryFormData>()
  const createCategoriesMutation = useCreateCategoriesMutation()

  async function handleCreateCategory(data: CategoryFormData) {
    try {
      const promise = createCategoriesMutation.mutateAsync({
        ...data,
        google_global_category_id: data.google_global_category_id.value,
      })

      toast.promise(promise, {
        loading: 'Cadastrando categoria...',
        success: () => {
          onClose()

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Categoria <span style={{ fontWeight: 'bold' }}>criada</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            errorMessages: createCategoriesErrorMessages,
            defaultMessage: 'Erro ao criar categoria.',
          }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Cadastrar Categoria'}
      width={717}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={onClose}
            disabled={createCategoriesMutation.isPending}
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
            disabled={createCategoriesMutation.isPending}
          >
            Cadastrar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        requiredMark
        layout="vertical"
        name="create-category"
        onFinish={handleCreateCategory}
      >
        <CategoryForm />
      </Form>
      <S.Text>Os campos de * são de preenchimento obrigatório</S.Text>
    </BaseModal>
  )
}
