import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Empty, Form, FormInstance, Input, Select } from 'antd'

import * as S from './styles'

import { SelectWithLabel, capitalizeString, filtersRemap } from '@/common'
import { bgQueries, type ProductExistsError } from '@/features/BG'
import { categoryQueries } from '@/features/categories'
import { productTypeQueries } from '@/features/product-types'

export type GeneralInfoFormData = {
  product_type: SelectWithLabel<number>
  category: SelectWithLabel<string>
  model: string
  family: string
  template: string
  ref_id: string
  external_characteristics?: string
}

type GeneralInfoProps = {
  form: FormInstance
  productExistsError: ProductExistsError
}

export const GeneralInfo = ({ form, productExistsError }: GeneralInfoProps) => {
  const { data: types, isLoading: isProductTypesLoading } = useQuery(
    productTypeQueries.list({
      page: 1,
      per_page: 100,
    }),
  )

  const selectedProductType = Form.useWatch<SelectWithLabel<number>>('product_type')
  const selectedCategory = Form.useWatch<SelectWithLabel<string>>('category')

  const { data: productTemplates, isLoading: isTemplateLoading } = useQuery({
    ...bgQueries.templateList(selectedProductType?.value),
    enabled: !!selectedProductType?.value,
  })

  const { data: categories, isLoading: isCategoriesLoading } = useQuery({
    ...categoryQueries.list({
      page: 1,
      per_page: 100,
      product_type_vtex_id: selectedProductType?.value,
    }),
    enabled: !!selectedProductType,
  })

  const productTypesSelectOptions = types?.product_types?.map((productType) => ({
    label: filtersRemap.get(productType.name) || productType.name,
    value: productType.vtex_id,
  }))

  const categorySelectOptions =
    selectedProductType?.label && categories && categories?.categories.length > 0
      ? [
          {
            label: capitalizeString(selectedProductType?.label),
            options: categories?.categories?.map((category) => ({
              value: category.id,
              label: category.name,
            })),
          },
        ]
      : []

  const templateSelectOptions = productTemplates?.templates.map((template) => {
    return {
      label: capitalizeString(template.model),
      value: template.model,
    }
  })

  useEffect(() => {
    if (selectedCategory && categories?.categories) {
      const selectedCategoryOption = categories.categories.find(
        (category) => category.name === selectedCategory?.label,
      )?.name

      if (!selectedCategoryOption) {
        form.setFieldsValue({ category: '' })
      }
    }
  }, [categories?.categories, form, selectedCategory])

  return (
    <S.Container>
      <S.Row>
        <Form.Item
          name="product_type"
          label={<S.Label>Tipo de produto</S.Label>}
          rules={[{ required: true, message: 'Informe o tipo do produto.' }]}
        >
          <Select
            options={productTypesSelectOptions}
            loading={isProductTypesLoading}
            labelInValue
            placeholder="Ex: Notebook"
          />
        </Form.Item>

        <Form.Item
          name="ref_id"
          label={<S.Label>Part Number</S.Label>}
          rules={[
            { required: true, message: 'Insira o part number do produto.' },
            { max: 26, message: 'O part number deve ter no máximo 26 caracteres.' },
          ]}
          validateStatus={productExistsError.refIdError.status ? 'error' : undefined}
          help={productExistsError.refIdError.message || undefined}
        >
          <Input max={26} maxLength={24} showCount placeholder="Ex: NH.QM1AL.00A" />
        </Form.Item>

        <Form.Item
          name="category"
          label={<S.Label>Categoria</S.Label>}
          rules={[{ required: true, message: 'Selecione a categoria do produto.' }]}
        >
          <Select
            labelInValue
            disabled={!selectedProductType?.value && !form.getFieldValue('category')?.value}
            loading={!!selectedProductType && isCategoriesLoading}
            options={categorySelectOptions}
            placeholder="Ex: Gamer"
            notFoundContent={
              <Empty
                description="Por favor, crie uma categoria para cadastrar esse tipo de produto."
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            }
          />
        </Form.Item>

        <Form.Item
          name="model"
          label={<S.Label>Modelo</S.Label>}
          rules={[{ required: true, message: 'Insira o modelo do produto.' }]}
          validateStatus={productExistsError.modelError.status ? 'error' : undefined}
          help={productExistsError.modelError.message || undefined}
        >
          <Input placeholder="Ex: A515-54G-79TC" />
        </Form.Item>

        <Form.Item
          name="family"
          label={<S.Label>Familia</S.Label>}
          rules={[{ required: true, message: 'Insira a familia do produto.' }]}
        >
          <Input placeholder="Ex: Acer-Nitro-5" />
        </Form.Item>
      </S.Row>

      <Form.Item name="template" label={<S.Label>Usar template</S.Label>}>
        <Select
          allowClear
          loading={!!selectedProductType?.value && isTemplateLoading}
          options={templateSelectOptions}
          disabled={!selectedProductType?.value}
          style={{ textTransform: 'uppercase' }}
          dropdownStyle={{ textTransform: 'uppercase' }}
          notFoundContent={
            <Empty
              description="Não há templates disponíveis para esse tipo de produto."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ textTransform: 'none' }}
            />
          }
        />
      </Form.Item>
    </S.Container>
  )
}
