import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(tz)
dayjs.extend(utc)

interface formatDateProps {
  date: string
  dateFormat?: string
  useUTC?: boolean
}

export function formatDate({
  date,
  dateFormat = 'DD/MM/YYYY HH:mm:ss',
  useUTC = false,
}: formatDateProps) {
  if (useUTC) return dayjs(date).utc().subtract(3, 'hour').format(dateFormat)

  return dayjs.utc(date).format(dateFormat)
}
