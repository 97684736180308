import { ReactNode } from 'react'

import { AcerLinksHeader } from '@fitec/acer-header'
import { Content } from 'antd/es/layout/layout'

import * as S from './styles'

import { authStore } from '@/app'
import { useDocumentTitle, useUserId } from '@/common'
import { Header } from '@/components/Layout/PageLayout'

interface PageLayoutProps {
  title?: string
  children: ReactNode
  sidebar?: ReactNode
}

const PageLayout = ({ title, sidebar, children }: PageLayoutProps) => {
  useDocumentTitle(title)
  const userId = useUserId()
  const auth = authStore((state) => state)

  return (
    <S.Container>
      <AcerLinksHeader
        idToken={auth.idToken as string}
        redirectInfo={{
          refreshToken: auth.refreshToken as string,
          userId: userId,
        }}
      />
      <Header />

      <S.SidebarContainer>
        {sidebar}

        <S.ContentContainer>
          <Content>{children}</Content>
        </S.ContentContainer>
      </S.SidebarContainer>
    </S.Container>
  )
}

export { PageLayout }
