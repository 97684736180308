import { useMutation } from '@tanstack/react-query'

import type { CreateProductResponse } from '../../types'

import { api } from '@/services'

export type UpdateProductImagePackMutation = {
  files_excludeds: string[]
  files_includeds: string[]
  productId: string
  saveAsDraft: boolean
  image_cover_bg: string
}

export const useUpdateProductImagePackMutation = () => {
  return useMutation({
    mutationFn: async ({ productId, saveAsDraft, ...data }: UpdateProductImagePackMutation) => {
      return api.patch<CreateProductResponse>(`/skus/sku_files_draft?is_draft=${saveAsDraft}`, {
        product_id: productId,
        ...data,
      })
    },
  })
}
2
