import { useState } from 'react'

import { GetProp, UploadFile, UploadProps } from 'antd'

import { useToggle } from './useToggle'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const useUploadImagePreview = () => {
  const [previewOpen, togglePreview] = useToggle(false)
  const [previewImage, setPreviewImage] = useState('')

  function getBase64(file: FileType): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  async function handlePreview(file: UploadFile) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    togglePreview()
  }

  return {
    previewOpen,
    previewImage,
    handlePreview,
    togglePreview,
  }
}
