import { BrowserRouter, Route, Routes as ReactRoutes, Navigate } from 'react-router-dom'

import { AuthenticatedRoute } from './AuthenticatedRoutes'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

import {
  LoginPage,
  DashboardPage,
  ProductPage,
  CategoriesPage,
  CreateProductPage,
  SpecificationsPage,
  ProductTypesPage,
  RedirectLoginPage,
  AgenciesPage,
  CreateKitPage,
  KitPage,
} from '@/pages'

export const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        {/* Public Routes */}
        <Route path="/auth" element={<PublicRoute />}>
          <Route index element={<Navigate to="login" replace={false} />} />
          <Route path="login" element={<LoginPage />} />
        </Route>

        {/* Redirect login */}
        <Route path="/redirect-login">
          <Route index element={<RedirectLoginPage />} />
        </Route>

        {/* Private Routes */}
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Navigate to="/dashboard" replace={false} />} />

          <Route path="dashboard" element={<DashboardPage />} />

          <Route path="product/:id" element={<ProductPage />} />

          <Route path="product/kit/:id" element={<KitPage />} />

          <Route path="agencies" element={<AuthenticatedRoute allowedRoles={['*', 'produto']} />}>
            <Route index element={<AgenciesPage />} />
          </Route>

          <Route path="categories" element={<AuthenticatedRoute allowedRoles={['*']} />}>
            <Route index element={<CategoriesPage />} />
          </Route>

          <Route path="product-types" element={<AuthenticatedRoute allowedRoles={['*']} />}>
            <Route index element={<ProductTypesPage />} />
          </Route>

          <Route path="specifications" element={<AuthenticatedRoute allowedRoles={['*']} />}>
            <Route index element={<SpecificationsPage />} />
          </Route>

          <Route
            path="create-product/:id?"
            element={<AuthenticatedRoute allowedRoles={['*', 'produto']} />}
          >
            <Route index element={<CreateProductPage />} />
          </Route>

          <Route path="create-kit" element={<AuthenticatedRoute allowedRoles={['*', 'produto']} />}>
            <Route index element={<CreateKitPage />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/auth" replace={false} />} />
      </ReactRoutes>
    </BrowserRouter>
  )
}
