import { ReactNode } from 'react'

import { Image } from 'antd'

import * as S from './styles'

import acerBgLogo from '@/assets/acer-bg-logo.svg'

type AccessLayoutProps = {
  children: ReactNode
}

const AccessLayout = ({ children }: AccessLayoutProps) => {
  return (
    <S.Container>
      <S.Layout>
        <S.Content>
          <header>
            <Image src={acerBgLogo} width={'auto'} height={48} preview={false} />
          </header>

          <main>{children}</main>
        </S.Content>
      </S.Layout>

      <S.ImageContainer>
        <S.IllustrationImage />
      </S.ImageContainer>
    </S.Container>
  )
}

export { AccessLayout }
