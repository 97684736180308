import { useMutation, useQueryClient } from '@tanstack/react-query'

import { agenciesQueries } from '../queries'

import { api } from '@/services'

export const useDeleteAgencyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (agencyId: string) => {
      return api.delete(`/agencies/${agencyId}`)
    },
    onMutate: async (agencyId) => await queryClient.cancelQueries(agenciesQueries.detail(agencyId)),
    onSettled: () => queryClient.invalidateQueries({ queryKey: agenciesQueries.all() }),
  })
}
