import { Button } from 'antd'

import * as S from './styles'

import { BaseModalProps } from '@/common'
import { BaseModal } from '@/components'

interface CreateProductConfirmationModalProps extends BaseModalProps {
  onConfirm: () => void
  productName: string
  isLoading: boolean
}

const CreateProductConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  productName,
  isLoading,
}: CreateProductConfirmationModalProps) => {
  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Confirmação de Cadastro do Produto'}
      width={717}
      footer={
        <>
          <Button onClick={onClose} type="link" disabled={isLoading}>
            Cancelar
          </Button>

          <Button type="primary" htmlType="submit" onClick={onConfirm} loading={isLoading}>
            Confirmar
          </Button>
        </>
      }
    >
      <S.Container>
        <S.CkeckIcon />

        <S.Text>
          Você confirma o <strong>cadastro</strong> do produto{' '}
          <strong>&quot;{productName}&quot;</strong> ?
        </S.Text>

        <S.ObsText>
          <S.ExclamationTriangle /> <strong>OBSERVAÇÃO:</strong> Em casos de{' '}
          <strong>produtos novos</strong>, é necessário <br /> <strong>aprovação NPI</strong> para
          finalizar o cadastro.
        </S.ObsText>
      </S.Container>
    </BaseModal>
  )
}

export { CreateProductConfirmationModal }
