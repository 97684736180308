import { RxExclamationTriangle } from 'react-icons/rx'

import { CameraOutlined, MoreOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { rem } from 'polished'
import styled, { css } from 'styled-components'

interface ProductPriceProps {
  $hasMarketplacePrice: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  position: relative;

  width: 100%;
  min-width: ${rem(240)};
  height: auto;

  padding: 8px;

  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.2s ease;
  color: #434343;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  background-color: ${({ theme }) => theme.colors.white};

  .ant-dropdown-trigger {
    position: absolute;
    top: 24px;
    right: -4px;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.brand};
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonContent = styled.div`
  .ant-btn {
    padding: 0;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    object-fit: cover !important;
  }
`

export const DropdownIcon = styled(MoreOutlined)`
  color: ${({ theme }) => theme.colors.darkGreen};
  font-size: ${rem(30)};
  height: 0;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease;

  :hover {
    filter: brightness(0.8);
  }
`

export const ProductCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #434343;

  span {
    font-size: ${rem(12)};
  }

  strong {
    font-size: ${rem(12)};
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`

export const ProductFamily = styled.div`
  font-size: ${rem(12)};
  margin-top: -6px;
  color: #434343;
`

export const BGName = styled.strong`
  display: flex;

  font-weight: 700;
  font-size: ${rem(12)};
  padding: 0;
  white-space: normal;
  text-align: left;
  line-height: normal;
  height: 50px;
  color: #434343;
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const ProductPrice = styled.div<ProductPriceProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  font-weight: 700;
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.colors.black};

  img {
    border: 1px solid rgba(153, 153, 153, 0.6);
    border-radius: 50%;
    padding: 2px;
  }

  ${({ $hasMarketplacePrice }) =>
    !$hasMarketplacePrice &&
    css`
      color: ${({ theme }) => theme.colors.lightGray};

      img {
        opacity: 0.2;
      }
    `}
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-weight: 400;
    font-size: ${rem(12)};
    color: #00000099;
  }
`

export const Date = styled.div`
  font-weight: 700;
  font-size: ${rem(10)};
  text-align: end;
  color: ${({ theme }) => theme.colors.blue};
`

export const DeactivatedButton = styled(Button)`
  color: ${({ theme }) => theme.colors.lightGray};
  pointer-events: none;
  cursor: default;
`

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 0px 0px 6px 6px;
`

export const ExclamationTriangle = styled(RxExclamationTriangle)`
  font-size: 18px;
  color: #faad14;
  margin-left: 8px;
`

export const AlertButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 4px 6px;

  height: ${rem(21)};
  width: ${rem(116)};

  font-size: 12px;
`

export const FallbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f6f6f6;

  height: ${rem(152)};
  width: ${rem(174)};
`

export const FallbackIcon = styled(CameraOutlined)`
  font-size: 34px;
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BadgesContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 32px;

  top: -6px;
  left: 0;
  z-index: 100;
`
