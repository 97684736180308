import * as S from './styles'

export const BgRegisterSecondStepHelp = () => {
  return (
    <>
      <S.DescriptionContent>
        <S.DescriptionTitle>1. Resumo do Produto:</S.DescriptionTitle>

        <S.DescriptionText>
          Informe manualmente o resumo das especificações do produto. Para melhorar a visualização,
          cada tópico deve ser separado por linhas (&quot;Enter&quot;).
        </S.DescriptionText>

        <S.ExampleText>
          Ex:
          <ul>
            <li>- Processador XXX XXXXXXXX</li>
            <li>- Tela de 15,6” IPS de XXXHz com resolução Full HD</li>
            <li>- GPU XXX XXXX 1650 com 4 GB de memória dedicada GDDR6 (TGP de 50W)</li>
            <li>- Memória RAM DDR4</li>
          </ul>
        </S.ExampleText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>2. Especificações</S.DescriptionTitle>

        <S.DescriptionText>
          Informe manualmente cada especificação do produto, de acordo com cada categoria. Para
          melhorar a visualização cada tópico dentro da mesma categoria, deve ser separado por
          linhas (&quot;Enter&quot;).
        </S.DescriptionText>

        <S.ExampleText>
          Ex:
          <ul>
            <span>Sistema operacional</span>
            <li>- XXXXX 11 Home 64-bits</li>
            <span>CPU e Chipset</span>
            <li>- XXXX Core i5-XXXX</li>
            <li>- 6 núcleos (12 threads)</li>
            <li>- Frequência: até 4.40 GHz</li>
            <span>Memória</span>
            <li>- 8 GB RAM DDR4 de 3200 MHz (8 GB em módulo SO-DIMM)</li>
            <li>
              - Expansível até 64GB DDR4 de 3200 MHz (2 Slots SO-DIMM com capacidade para até 32 GB
              cada)
            </li>
            <span>Tela</span>
            <li>- 15.6 LED com design ultrafino</li>
            <li>- Painel: IPS (In-Plane Switching)</li>
            <li>- Resolução: Full HD (1920 x 1080)</li>
            <li>- Proporção: 16:9</li>
            <li>- Taxa de atualização: 144 Hz</li>
          </ul>
        </S.ExampleText>
      </S.DescriptionContent>
    </>
  )
}
