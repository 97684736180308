import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa'

import { Typography, Divider as AntdDivider } from 'antd'
import styled from 'styled-components'

import type { HealthStatus } from '../../types'

interface StatusProps {
  $textStatus?: HealthStatus
}

export const Container = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;

  .ant-typography {
    line-height: initial;
  }
`

export const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
`

export const Status = styled(Typography.Text)<StatusProps>`
  font-size: 12px;
  font-weight: 700;

  color: ${({ $textStatus }) => ($textStatus === 'Operacional' ? '#80C343' : '#ff8b27')};
`

export const OfflineIcon = styled(FaExclamationTriangle)`
  width: 16px;
  height: 16px;

  color: #ff8b27;
`

export const OnlineIcon = styled(FaCheckCircle)`
  width: 16px;
  height: 16px;

  color: ${({ theme }) => theme.colors.brand};
`

export const MarketplaceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Divider = styled(AntdDivider)`
  margin: 0;
  background: #fff;
  height: 24px;
`

export const AnyMarketImageContainer = styled.div`
  margin: 0 6px;
`
