import { useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Upload, UploadFile } from 'antd'
import { toast } from 'sonner'

import { BgFileManagementTable } from '../../Table'
import { SupportFilesUploader } from '../../Uploader'
import * as S from './styles'

import { ApiError, BaseModalProps } from '@/common'
import { BaseModal } from '@/components'
import {
  bgQueries,
  SupportFile,
  useS3Url,
  validateFilesBeforeUpload,
  useUpdateSupportFilesMutation,
  UpdateSupportFilesMutation,
  useUploadFiles,
  translateFileExistsError,
  ProductDownloadType,
} from '@/features/BG'

interface BgFileManagementModalProps extends BaseModalProps {
  productId: string
  handleDownloadFiles: (downloadType: ProductDownloadType) => void
  isDownloading: boolean
}

export const BgFileManagementModal = ({
  isOpen,
  onClose,
  productId,
  handleDownloadFiles,
  isDownloading,
}: BgFileManagementModalProps) => {
  const fetchS3Url = useS3Url()
  const { uploadFiles } = useUploadFiles()
  const isUploadingRef = useRef(false)
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)

  const { data: bgFiles, isLoading: isSupportFilesLoading } = useQuery(
    bgQueries.supportFiles(productId),
  )

  const updateSupportFilesMutation = useUpdateSupportFilesMutation()

  const handleUploadFile = async (_: File, fileList: UploadFile[]) => {
    if (isUploadingRef.current) {
      console.log('Already running uploader.')
      return
    }

    isUploadingRef.current = true
    setIsUploadingFiles(true)

    const hasMultipleFiles = fileList.length > 1

    const loadingMessage = hasMultipleFiles
      ? `Fazendo upload de ${fileList.length} arquivos`
      : 'Fazendo upload de 1 arquivo'

    const successMessage = hasMultipleFiles
      ? 'Arquivos enviados com sucesso!'
      : 'Arquivo enviado com sucesso!'

    const errorMessage = hasMultipleFiles
      ? 'Falha ao fazer upload dos arquivos'
      : 'Falha ao fazer upload do arquivo'

    const toastId = toast.loading(loadingMessage)

    try {
      const s3Results = await Promise.all(
        fileList.map(async (file) => fetchS3Url(file as unknown as File)),
      )

      const filesToUpload = s3Results.map((result) => ({ url: result.url, file: result.file }))

      await uploadFiles(filesToUpload)

      const payload: UpdateSupportFilesMutation = {
        productId,
        files_excludeds: [],
        files_includeds: s3Results.map((r) => ({
          filename: r.file.name,
          s3_filename: r.filename,
        })),
      }

      await updateSupportFilesMutation.mutateAsync(payload)

      toast.success(successMessage, { id: toastId })
    } catch (error) {
      console.error('Error uploading file:', error)

      const { success, message } = translateFileExistsError(
        (error as ApiError).response?.data.detail as string,
      )

      toast.error(success ? message : errorMessage, { id: toastId })
    } finally {
      isUploadingRef.current = false
      setIsUploadingFiles(false)
    }
  }

  async function handleRemoveFile(file: SupportFile) {
    const payload: UpdateSupportFilesMutation = {
      productId,
      files_excludeds: [{ filename: file.filename, s3_filename: file.s3_filename }],
      files_includeds: [],
    }

    const promise = updateSupportFilesMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Removendo arquivo',
      success: 'Arquivo removido com sucesso!',
      error: 'Falha ao remover arquivo',
    })
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Arquivos do Buyers Guide"
      width={720}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={onClose}
            disabled={
              isSupportFilesLoading || isUploadingFiles || updateSupportFilesMutation.isPending
            }
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            disabled={
              isSupportFilesLoading || isUploadingFiles || updateSupportFilesMutation.isPending
            }
            loading={isDownloading}
            onClick={() => handleDownloadFiles('bgFiles')}
          >
            Baixar Todos
          </Button>
        </>
      }
    >
      <S.Container>
        <S.Column>
          <div>
            <S.Typography>Anexar um novo arquivo</S.Typography>

            <S.UploadFilesContainer>
              <SupportFilesUploader
                showUploadList={false}
                beforeUpload={async (file: File, fileList: UploadFile[]) => {
                  const currentBgFiles = [
                    ...(bgFiles?.support_files.map((file) => file.filename) || []),
                    ...(bgFiles?.sku_files.map((file) => file.name) || []),
                  ]

                  const validationResult = validateFilesBeforeUpload(file, fileList, currentBgFiles)

                  if (validationResult) {
                    await handleUploadFile(file, fileList)

                    return file
                  }

                  return Upload.LIST_IGNORE
                }}
                disabled={
                  isSupportFilesLoading ||
                  isUploadingFiles ||
                  updateSupportFilesMutation.isPending ||
                  isDownloading
                }
              />
            </S.UploadFilesContainer>
          </div>

          <BgFileManagementTable
            isLoading={isSupportFilesLoading || isDownloading}
            data={bgFiles}
            handleRemoveFile={handleRemoveFile}
            handleDownload={handleDownloadFiles}
          />
        </S.Column>
      </S.Container>
    </BaseModal>
  )
}
