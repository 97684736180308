import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { ProductType, ProductTypeFieldGroup } from '../types'

import type { ProductTypeFilters } from '@/app'
import type { ListResponse } from '@/common'
import { api } from '@/services'

export const productTypeQueries = {
  all: () => ['product-types'],
  lists: () => [...productTypeQueries.all(), 'list'],
  list: (filters: ProductTypeFilters = {}) =>
    queryOptions({
      queryKey: [...productTypeQueries.lists(), filters],
      queryFn: () => fetchProductTypesList(filters),
      placeholderData: keepPreviousData,
    }),
  fieldGroups: (vtexId: number) =>
    queryOptions({
      queryKey: [...productTypeQueries.all(), 'field-groups', vtexId],
      queryFn: () => fetchProductTypeFieldGroups(vtexId),
      placeholderData: keepPreviousData,
    }),
}

export const fetchProductTypesList = async (filters: ProductTypeFilters) => {
  const { page, per_page } = filters

  const { data } = await api.get<ListResponse<ProductType, 'product_types'>>(
    `/product_types?per_page=${per_page}&page=${page}`,
  )

  return data
}

export const fetchProductTypeFieldGroups = async (vtexId: number) => {
  const { data } = await api.get<ProductTypeFieldGroup[]>(`product_types/${vtexId}/field_groups`)

  return data
}
