import type { UploadFile } from 'antd'
import { toast } from 'sonner'

import type { BgFileUploadData } from '../types'

export function validateFilesBeforeUpload(
  file: File,
  fileList: UploadFile[],
  uploadData?: BgFileUploadData[] | string[],
  maxFilesAccepted?: number,
  maxFileSize?: number,
) {
  const totalFiles = (uploadData?.length || 0) + fileList.length
  const currentFileIndex = fileList.indexOf(file as any)

  // validate maximum file size for all files
  if (maxFileSize) {
    const oversizedFiles = fileList.filter(
      (f) => f.originFileObj && f.originFileObj.size > maxFileSize,
    )

    if (file.size > maxFileSize) {
      oversizedFiles.push(file as unknown as UploadFile)
    }

    if (oversizedFiles.length > 0) {
      const filesMessage = oversizedFiles.map((f) => f.name).join(', ')

      console.log({ oversizedFiles })

      toast.error(`Os seguintes arquivos excedem o tamanho máximo permitido: ${filesMessage}`, {
        position: 'bottom-right',
      })

      return false
    }
  }

  // validate amount of files accepted
  if (maxFilesAccepted) {
    const remainingFileSlots = Math.max(maxFilesAccepted - (uploadData?.length || 0), 0)

    // If the total number of files exceeds the limit, ignore the file
    if (totalFiles > maxFilesAccepted) {
      if (currentFileIndex >= remainingFileSlots) {
        // This is the first file that exceeds the limit
        if (currentFileIndex === remainingFileSlots) {
          const excludedFiles = fileList
            .slice(remainingFileSlots)
            .map((f) => f.name)
            .join(', ')

          toast.error(
            `Limite de ${maxFilesAccepted} arquivos atingido. Os seguintes arquivos não foram adicionados: ${excludedFiles}`,
            {
              position: 'bottom-right',
            },
          )
        }

        return false
      }
    }
  }

  // Check for duplicate files
  if (uploadData && uploadData.length > 0) {
    let hasFile: BgFileUploadData | string | undefined

    if (Array.isArray(uploadData) && typeof uploadData[0] === 'string') {
      hasFile = uploadData.find((f) => f === file.name)
    } else {
      hasFile = uploadData.find((f) => (f as BgFileUploadData)?.file?.name === file.name)
    }

    if (hasFile) {
      toast.error(`Arquivo ${file.name} já adicionado.`)
      return false
    }
  }

  return true
}
