import { Navigate, Outlet } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { shallow } from 'zustand/shallow'

import { authStore as useAuthStore } from '@/app'
import { PageErrorBoundary } from '@/components'
import { AccessLayout } from '@/layouts'

const PublicRoute = () => {
  const { accessToken, refreshToken, userAuthenticated } = useAuthStore(
    (s) => ({
      accessToken: s.accessToken,
      refreshToken: s.refreshToken,
      userAuthenticated: s.isUserAuthenticated,
    }),
    shallow,
  )

  const isAuthenticated = accessToken && refreshToken && userAuthenticated

  if (isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return (
    <Sentry.ErrorBoundary
      fallback={
        <AccessLayout>
          <PageErrorBoundary />
        </AccessLayout>
      }
    >
      <Outlet />
    </Sentry.ErrorBoundary>
  )
}

export { PublicRoute }
