import { ProductStatus } from '../types'

export function canDeleteProduct(productStatus: ProductStatus): boolean {
  if (
    productStatus === 'EM RASCUNHO DO PRODUTO' ||
    productStatus === 'PENDENTE DE ENVIO PARA AGÊNCIA' ||
    productStatus === 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA'
  ) {
    return true
  }

  return false
}
