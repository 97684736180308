import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'

import * as S from './styles'

import { useDebounce } from '@/common'
import { categoryQueries } from '@/features/categories'

export const ProductTypeForm = () => {
  const [globalCategoryName, setGlobalCategoryName] = useState('')
  const debouncedGlobalCategory = useDebounce(globalCategoryName, 500)

  const {
    data: globalcategories,
    isLoading: isGlobalCategoriesLoading,
    isFetching: isGlobalCategoriesFetching,
  } = useQuery(categoryQueries.globalCategories(debouncedGlobalCategory))

  const globalCategoriesOptions = globalcategories?.google_global_categories.map((value) => ({
    value: value.google_id,
    label: value.name,
  }))

  return (
    <S.FormContainer>
      <Form.Item
        name={'name'}
        label={'Nome'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={'global_category_id'}
        label={'Categoria Global'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Select
          size="large"
          showSearch
          labelInValue
          placeholder="Selecione a categoria"
          options={globalCategoriesOptions}
          loading={isGlobalCategoriesLoading || isGlobalCategoriesFetching}
          onSearch={setGlobalCategoryName}
          filterOption={false}
        />
      </Form.Item>
    </S.FormContainer>
  )
}
