import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;

  padding-top: 42px;

  .ant-typography {
    line-height: initial;
  }

  .ant-form-vertical .ant-form-item-label > label {
    font-size: 14px;

    color: #6c6c6c;
  }
`

export const CkeckIcon = styled(CheckCircleOutlined)`
  font-size: 48px;
  color: #5ad8a6;
`

export const ExclamationTriangle = styled(WarningOutlined)`
  font-size: 16px;
  color: #faad14;
`

export const Text = styled(Typography.Text)`
  font-size: 16px;

  color: #6c6c6c;

  strong {
    font-weight: 700;
    color: #303030;
  }
`

export const ObsText = styled(Typography.Text)`
  text-align: center;
  font-size: 12px;

  color: #303030;

  strong {
    font-weight: 700;
  }
`
