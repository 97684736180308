import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Disclaimer = styled.span`
  font-size: ${rem(14)};
  color: rgba(0, 0, 0, 0.6);
`
