import { Button } from 'antd'

import * as S from './styles'

import { AccessTitleTabs } from '@/components/Layout/AccessLayout'
import { AccessLayout } from '@/layouts'

export function LoginPage() {
  const defaultLoginUrl = 'https://acer-loginunico.com/auth/login'

  return (
    <AccessLayout>
      <S.Container>
        <AccessTitleTabs title="" />

        <span>Para acessar o sistema, faça login no link abaixo.</span>

        <S.RedirectLink
          href={import.meta.env.VITE_LOGIN_URL || defaultLoginUrl}
          rel="noopener noreferrer"
        >
          <Button type="primary" block>
            Fazer login
          </Button>
        </S.RedirectLink>
      </S.Container>
    </AccessLayout>
  )
}
