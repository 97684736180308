import { useEffect } from 'react'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, BaseModalProps, filtersRemap, toastError, useToggle } from '@/common'
import { BaseModal } from '@/components'
import {
  type ProductType,
  ProductTypeFormData,
  ProductTypeForm,
  editProductTypeErrorMessages,
  useEditProductTypeMutation,
} from '@/features/product-types'

interface ViewProductTypeModalProps extends BaseModalProps {
  data: ProductType
}

export const ViewProductTypeModal = ({ isOpen, onClose, data }: ViewProductTypeModalProps) => {
  const [form] = Form.useForm<ProductTypeFormData>()

  const [isEditing, toggleEdit] = useToggle(false)

  const editProductTypeMutation = useEditProductTypeMutation()

  useEffect(() => {
    const initialData = {
      name: (filtersRemap.get(data.name) || data.name) as string,
      global_category_id: {
        label: data.global_category.name,
        value: data.global_category.google_id,
      },
    }

    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [form, data])

  async function handleEditProductType(values: ProductTypeFormData) {
    const payload = {
      id: data.id,
      name: values.name,
      global_category_id: values.global_category_id.value,
    }

    const promise = editProductTypeMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Editando tipo de produto...',
      success: () => {
        onClose()

        return (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Tipo de produto <span style={{ fontWeight: 'bold' }}>editado</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        )
      },
      className: 'toast-sucess',
      error: (error: ApiError) =>
        toastError({
          error,
          errorMessages: editProductTypeErrorMessages,
          defaultMessage: 'Erro ao editar tipo do produto.',
        }),
    })
  }

  const ProductTypeView = () => (
    <>
      <S.FieldRow>
        <S.ProductTypeData>
          <strong>Nome</strong>

          <span>{(filtersRemap.get(data?.name) as string) || data?.name}</span>
        </S.ProductTypeData>

        <S.ProductTypeData>
          <strong>Categoria Global</strong>

          <span>{data?.global_category?.name || data?.global_category?.google_id}</span>
        </S.ProductTypeData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais de Tipo de Produto"
      width={740}
      footer={
        <>
          <Button
            type="link"
            onClick={isEditing ? toggleEdit : onClose}
            htmlType="button"
            disabled={editProductTypeMutation.isPending}
          >
            Cancelar
          </Button>

          {!isEditing ? (
            <>
              <Button type="primary" onClick={toggleEdit}>
                Editar
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              disabled={editProductTypeMutation.isPending}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <ProductTypeView />
        ) : (
          <Form
            form={form}
            requiredMark
            layout="vertical"
            onFinish={handleEditProductType}
            disabled={editProductTypeMutation.isPending}
          >
            <ProductTypeForm />
          </Form>
        )}
      </S.Container>
    </BaseModal>
  )
}
