import { AxiosError } from 'axios'

export type BaseModalProps = {
  isOpen: boolean
  onClose: () => void
}

export type ApiError = AxiosError<{ detail: string }>

export type RefreshToken = {
  scope: string
  username: string
  'cognito:groups': string[]
}

export type SelectWithLabel<T> = {
  key: T
  value: T
  label: string
  disabled?: boolean
  title?: string
}

export type ListResponse<T, K extends string = string> = {
  [key in K]: T[]
} & {
  total: number
  per_page: number
  page: number
  total_pages: number
}

export enum ModuleKey {
  PRODUCT = 'produto',
  ECOMMERCE = 'ecommerce',
  COMMERCIAL = 'comercial',
}

export enum RoleKey {
  PRODUCT = 'product',
  ECOMMERCE = 'ecommerce',
  COMMERCIAL = 'commercial',
}

export type RolePermissions = {
  isAdmin: boolean
  canRead: boolean
  canCreate: boolean
  canUpdate: boolean
  canDelete: boolean
}
