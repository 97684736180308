import { Drawer as AntdDrawer, Button, Typography } from 'antd'
import styled from 'styled-components'

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-header {
    padding: 16px 20px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .ant-drawer-title {
    font-size: 15px;
    font-weight: 800;
  }

  .ant-drawer-extra {
    color: ${({ theme }) => theme.colors.darkGreen};

    :hover {
      color: rgba(94, 163, 32, 0.7);
    }
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: end;
  }
`

export const CloseButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};

  :hover {
    color: rgba(94, 163, 32, 0.6);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 16px;

  .ant-typography {
    line-height: initial;
  }
`

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`

export const DescriptionTitle = styled(Typography.Text)`
  color: rgba(48, 48, 48, 0.85);
  font-weight: 700;
`

export const DescriptionText = styled(Typography.Text)`
  color: rgba(48, 48, 48, 0.85);
`

export const DescriptionLink = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.darkGreen};
`
