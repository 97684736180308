import * as S from './styles'

export const BgRegisterThirdStepHelp = () => {
  return (
    <>
      <S.DescriptionContent>
        <S.DescriptionTitle>1. Upload das Imagens:</S.DescriptionTitle>

        <S.DescriptionText>
          Clique e arraste a(s) imagem(s) do produto ou clique no ícone para abrir a janela do
          dispositivo e selecione as imagem(s) desejada(s).
        </S.DescriptionText>
      </S.DescriptionContent>
    </>
  )
}
