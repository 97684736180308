import { useLocation, useNavigate } from 'react-router-dom'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { ButtonProps } from 'antd'

import * as S from './styles'

interface ReturnButtonProps extends ButtonProps {}

export function ReturnButton({ ...props }: ReturnButtonProps) {
  const location = useLocation()
  const navigate = useNavigate()

  function handleReturn() {
    if (location.state) {
      navigate(-1)
    } else {
      navigate('/dashboard')
    }
  }

  return (
    <S.BackButton type="link" icon={<ArrowLeftOutlined />} onClick={handleReturn} {...props}>
      Voltar
    </S.BackButton>
  )
}
