import { useEffect, useState } from 'react'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import {
  ApiError,
  BaseModalProps,
  RolePermissions,
  toastError,
  truncateString,
  useToggle,
} from '@/common'
import { BaseModal } from '@/components'
import { AgencyCard, AgencyForm } from '@/features/agency'
import { editAgencyErrorMessages, useEditAgencyMutation } from '@/features/agency/mutations'
import { Agency } from '@/features/agency/types'

interface ViewAgencyModalProps extends BaseModalProps {
  data: Agency
  permissions: RolePermissions
}

export const ViewAgencyModal = ({ isOpen, onClose, data, permissions }: ViewAgencyModalProps) => {
  const [form] = Form.useForm()

  const [isEditing, toggleEdit] = useToggle(false)
  const [agency, setAgencies] = useState<Agency>(data)
  const editAgencyMutation = useEditAgencyMutation()

  useEffect(() => {
    const initialData = {
      name: data.name,
    }

    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [form, data])

  const handleAddResponsibles = () => {
    form
      .validateFields(['name', 'responsible', 'email'])
      .then((values) => {
        const emailExists = agency.responsibles.some(
          (responsible) => responsible.email === values.email,
        )

        if (emailExists) {
          return form.setFields([
            {
              name: 'email',
              errors: ['Este e-mail já está cadastrado'],
            },
          ])
        }

        const newResponsibles = {
          name: values.responsible,
          email: values.email,
        }

        setAgencies((prevAgency) => ({
          ...prevAgency,
          name: values.name || prevAgency.name,
          responsibles: [...prevAgency.responsibles, newResponsibles],
        }))

        form.resetFields(['responsible', 'email'])
      })
      .catch((errorInfo) => {
        console.log('Erro ao validar:', errorInfo)
      })
  }

  const handleDeleteCard = (index: number) => {
    setAgencies((prevAgencies) => {
      const updatedAgencies = prevAgencies.responsibles.filter((_, i) => i !== index)

      return { ...agency, responsibles: updatedAgencies }
    })
  }

  const handleClearAllResponsibles = () => {
    setAgencies({ ...agency, responsibles: [] })
  }

  async function handleEditAgency() {
    const agencyNameValues = form.getFieldValue('name')

    try {
      const payload = {
        id: agency._id,
        name: agencyNameValues,
        responsibles: agency.responsibles,
      }

      const promise = editAgencyMutation.mutateAsync(payload)

      toast.promise(promise, {
        loading: 'Editando agência...',
        success: () => {
          onClose()

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Agência <span style={{ fontWeight: 'bold' }}>editada</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Erro ao editar tipo do produto.',
            errorMessages: editAgencyErrorMessages,
          }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AgenciesView = () => {
    return (
      <S.Container>
        {!isEditing && <S.AgencyLabel>{truncateString(agency.name || '-', 50)}</S.AgencyLabel>}
        {!isEditing && <span>Responsáveis:</span>}

        {isEditing && agency.responsibles.length > 0 && (
          <S.ClearAllButton type="link" onClick={handleClearAllResponsibles}>
            Limpar todos os responsáveis
          </S.ClearAllButton>
        )}

        <S.ScrollableResponsibles>
          <S.ViewAgencyCardsField>
            {agency.responsibles.map((_, index) => (
              <AgencyCard
                key={index}
                index={index}
                data={agency}
                isEditing={isEditing}
                isCreating={false}
                onDelete={() => handleDeleteCard(index)}
              />
            ))}
          </S.ViewAgencyCardsField>
        </S.ScrollableResponsibles>
      </S.Container>
    )
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Dados da Agência'}
      width={isEditing ? 862 : 700}
      footer={
        <>
          {!isEditing ? (
            <Button
              onClick={onClose}
              type="link"
              htmlType="button"
              disabled={editAgencyMutation.isPending}
            >
              Cancelar
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => {
                toggleEdit()
                setAgencies(data)
              }}
            >
              Voltar
            </Button>
          )}

          {!isEditing ? (
            permissions?.canUpdate || permissions?.isAdmin ? (
              <>
                <Button type="primary" onClick={toggleEdit}>
                  Editar
                </Button>
              </>
            ) : null
          ) : (
            <Button
              type="primary"
              htmlType="button"
              onClick={handleEditAgency}
              disabled={editAgencyMutation.isPending}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <AgenciesView />
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleAddResponsibles}
              disabled={editAgencyMutation.isPending}
            >
              <AgencyForm />
            </Form>

            <AgenciesView />
          </>
        )}
      </S.Container>
    </BaseModal>
  )
}
