import { CloseOutlined } from '@ant-design/icons'
import { Tag as AntdTag } from 'antd'
import styled from 'styled-components'

export const Tag = styled(AntdTag)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  position: relative;
  padding: 0 8px;

  margin: 0;

  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  font-size: 12px;

  cursor: default;
`

export const RemoveTagButton = styled(CloseOutlined)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 10px;
  background-color: transparent;

  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    filter: brightness(0.8);
  }
`
