import { Layout as AntdLayout } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

import illustration from '@/assets/auth-layout-illustration.jpg'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`

export const Layout = styled(AntdLayout)`
  display: flex;

  height: 100vh;
  width: 100vw;
`

export const Content = styled(AntdLayout.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  padding: 0 24px;

  main {
    width: 100%;
    max-width: ${rem(360)};
  }
`

export const ImageContainer = styled(Layout)`
  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`

export const IllustrationImage = styled(AntdLayout.Content)`
  width: 100%;
  height: 100%;

  background-image: url(${illustration});
  background-size: cover;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`
