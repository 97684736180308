import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const ResponsibleName = styled.span`
  font-size: 14px;
  color: #666666;
  font-weight: 600;
`

export const ResponsibleEmail = styled.span`
  font-size: 14px;
  color: #303030;
  font-style: italic;
`

export const AgencyLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
`

export const ViewAgencyCardsField = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 204px;
  flex-wrap: wrap;
`

export const AgencyCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 6px;
  width: 276px;
  height: 60px;
  padding: 12px;
  margin-top: 16px;
`

export const DeleteAgencyButton = styled(DeleteOutlined)`
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.brand};
`

export const ClearAllButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  padding-left: 2px;
`

export const ScrollableResponsibles = styled.div`
  overflow-y: auto;
  padding-right: 14px;
`
