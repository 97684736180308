import { CloseOutlined } from '@ant-design/icons'

import * as S from './styles'

type BgRegistrationDrawerProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const CreateProductHelpDrawer = ({ isOpen, onClose, children }: BgRegistrationDrawerProps) => {
  return (
    <S.Drawer
      title="Como preencher os campos de cadastro?"
      placement="right"
      onClose={onClose}
      open={isOpen}
      closable={false}
      extra={<CloseOutlined onClick={onClose} />}
      footer={<S.CloseButton onClick={onClose}>Fechar</S.CloseButton>}
    >
      <S.Container>{children}</S.Container>
    </S.Drawer>
  )
}

export { CreateProductHelpDrawer }
