import { CheckCircleOutlined } from '@ant-design/icons'
import { Radio, Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const ThirdStepFormContainer = styled.div<{ $uploaderEmpty: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .ant-btn-icon > .anticon {
    color: #80c343 !important;
  }
  .ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card {
    gap: 20px;
  }
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
    color: #434343;
    cursor: default;
  }

  .ant-upload-wrapper .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
  }
  .ant-upload-list-item.ant-upload-list-item-undefined {
    bottom: 8px;
  }
  .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    height: 154px !important;
  }

  .ant-upload.ant-upload-select {
    background: transparent !important;
    border-color: transparent !important;
    order: -1;
  }
  .ant-upload-list-item-thumbnail {
    height: 106px !important;
    width: 106px !important;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const UploadStatusContainer = styled.div<{ $isDone: boolean }>`
  display: ${({ $isDone }) => ($isDone ? 'none' : 'flex')};
  flex-direction: column;
  gap: 4px;
`

export const LoadingStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: ${rem(150)};
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ListedImageContainer = styled.div`
  display: flex;
  align-items: center !important;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding-left: 18px;
  padding-top: 24px;
  padding-bottom: 24px;
  .ant-upload-list-item.ant-upload-list-item-undefined {
    border: none !important;
  }
  /* .ant-upload-list-item-thumbnail {
    height: 104px !important;
    width: 120px !important;
  } */
`

export const UploaderIntruction = styled.span`
  color: rgba(48, 48, 48, 0.85);
`

export const ProductCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: initial;

  span {
    font-size: ${rem(16)};
  }

  strong {
    font-size: ${rem(16)};
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`

export const BGName = styled(Typography.Text)`
  display: flex;

  font-weight: 700;
  font-size: ${rem(20)};
  padding: 0;
  white-space: normal;
  text-align: left;
  line-height: normal;

  color: #434343;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`

export const UploadedImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ImageName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`

export const ImageCheckbox = styled(Radio)`
  .custom-radio {
    border-color: #1890ff; /* Cor personalizada da borda */
    border-width: 2px; /* Largura da borda */
  }
`

export const UploadSupportFilesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  .ant-upload-list-item-action.ant-btn {
    width: 100px;
  }
  .ant-upload-list-item.ant-upload-list-item-undefined {
    height: 32px;
    text-align: center;
    width: 588px;
  }
  .ant-upload-wrapper .ant-upload-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 8px;
    width: 592px;
  }
  .ant-upload-list-item-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 34px !important;
  }
  .ant-upload-list-item-name {
    text-align: start;
    padding-left: 8px;
    text-overflow: ellipsis;
  }
  .ant-upload-list.ant-upload-list-picture .ant-upload-list-item {
    height: 60px;
    margin: 0;
  }
  .ant-btn {
    width: ${rem(592)};
  }
  button {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px !important;
  }
`
