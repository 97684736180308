import { Button } from 'antd'
import styled from 'styled-components'

export const ImageContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  .ant-image-img {
    width: 62px;
    height: 52px;
    object-fit: cover;
  }
`

export const ActionTableRow = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DeleteButton = styled(Button)``

export const DownloadButton = styled(Button)``

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;

  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 40px;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TableContainer = styled.div`
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
`
