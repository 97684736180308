import { Collapse as AntdCollapse, Typography as AntdTypography, Button as AntdButton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${rem(606)};
  /* overflow-y: auto; */
  gap: 8px;

  .ant-collapse-borderless {
    background-color: transparent;
  }
`

export const Typography = styled(AntdTypography)`
  color: ${({ theme }) => theme.customTokens.text.lightGray};
  padding-left: 4px;
  font-size: 16px;
`

export const Collapse = styled(AntdCollapse)`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-bottom: 24px;

  .ant-collapse-header {
    font-weight: 600;
    font-size: 16px;
    color: #303030a6 !important;
  }

  .gray-collapse {
    background-color: #ededed;
  }

  .white-collapse {
    background-color: #ffffff;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`

export const ProductCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: initial;

  span {
    font-size: ${rem(16)};
  }

  strong {
    font-size: ${rem(16)};
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`

export const BGName = styled(Typography.Text)`
  display: flex;

  font-weight: 700;
  font-size: ${rem(20)};
  padding: 0 0 8px 0;
  white-space: normal;
  text-align: left;
  line-height: normal;

  color: #434343;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 32px;
`

export const UpgradeContainer = styled(Typography.Text)`
  display: flex;
  align-items: center;

  gap: 8px;

  .ant-form-item .ant-form-item-control-input {
    min-height: 0;
  }
`

export const UpgradeText = styled(Typography.Text)`
  color: #6c6c6c;

  strong {
    font-weight: 700;
  }
`

export const SpecificationsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SpecificationsButtons = styled(AntdButton)`
  border: 1px solid #80c343;
`

export const HelpButton = styled(AntdButton)`
  padding: 0;
  height: 19px;
`

export const ProductCategoryContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TooltipHelpIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1px;
  font-size: 14px;
  height: 16px;
  width: 16px;

  border-radius: 99999999px;

  color: ${({ theme }) => theme.colors.brand};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.brand};
`
