import { ExclamationCircleFilled, FileDoneOutlined } from '@ant-design/icons'
import { Button, Modal, Switch } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { filtersRemap, specificationsRemap } from '@/common'
import { BaseTable, TableAction, TableColumnButton } from '@/components'
import type { Specification } from '@/features/specifications'

type SpecificationsListTableProps = {
  data: Specification[] | undefined
  isLoading: boolean
  handleEditSpecifications: (specification: Specification) => void
  handleChangeSpecificationStatus: (specification: Specification, status: boolean) => void
}

export const SpecificationsListTable = ({
  data,
  isLoading,
  handleEditSpecifications,
  handleChangeSpecificationStatus,
}: SpecificationsListTableProps) => {
  const columns: ColumnsType<Specification> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 490,
      render: (_, record) => (
        <TableColumnButton onClick={() => handleEditSpecifications(record)}>
          {record.name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Tipo de Campo',
      dataIndex: 'camp_type',
      key: 'camp_type',
      align: 'center',
      width: 224,
      render: (_, value) => <p>{specificationsRemap.get(value.field_type_id) as string}</p>,
    },
    {
      title: 'Tipo de Produto',
      dataIndex: 'product_type',
      key: 'product_type',
      align: 'center',
      width: 224,
      render: (_, value) => (
        <p>{(filtersRemap.get(value.product_type.name) as string) || value.product_type.name}</p>
      ),
    },
    {
      title: 'Grupo de Especificação',
      dataIndex: 'SpecificationGroup',
      key: 'SpecificationGroup',
      align: 'center',
      width: 224,
      render: (_, record) => <p>{record.field_group.name}</p>,
    },
    {
      title: 'Posição do Campo',
      dataIndex: 'camp_position',
      key: 'camp_position',
      align: 'center',
      width: 153,
      render: (_, value) => <p>{value.position}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      align: 'center',
      width: 88,
      render: (isActive, specification) => (
        <Switch
          checked={isActive}
          onClick={() => confirmChangeStatus(specification, isActive)}
          checkedChildren={'Ativo'}
          unCheckedChildren={'Inativo'}
        />
      ),
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 55,
      render: (_, specification) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  icon={<FileDoneOutlined />}
                  type="link"
                  onClick={() => handleEditSpecifications(specification)}
                >
                  Dados cadastrais
                </Button>
              </S.ButtonContent>
            ),
          },
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  function confirmChangeStatus(specification: Specification, isActive: boolean) {
    Modal.confirm({
      title: `Deseja ${isActive === true ? 'Desativar' : 'Ativar'} Especificação?`,
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FAAD14' }} />,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => handleChangeSpecificationStatus(specification, !specification.is_active),
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
    })
  }

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      loading={isLoading}
    />
  )
}
