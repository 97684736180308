import { Form, FormInstance, Input } from 'antd'

import * as S from './styled'

export type DiscontinuedFormData = {
  description: string
}

interface DiscontinuedFormProps {
  onSubmit: (values: DiscontinuedFormData) => void
  form: FormInstance
  isLoading?: boolean
}

const DiscontinuedForm = ({ form, onSubmit, isLoading }: DiscontinuedFormProps) => {
  return (
    <S.Container>
      <Form
        form={form}
        onFinish={onSubmit}
        disabled={isLoading}
        layout="vertical"
        requiredMark={false}
        style={{ width: '100%' }}
      >
        <Form.Item
          name="description"
          label="Por favor, informe-nos o motivo"
          rules={[{ required: true, message: 'Por favor, insira o seu texto.' }]}
        >
          <Input.TextArea style={{ height: 95 }} />
        </Form.Item>
      </Form>
    </S.Container>
  )
}

export { DiscontinuedForm }
