import { HistoryOutlined } from '@ant-design/icons'
import type { ButtonProps } from 'antd'

import * as S from './styles'

interface ViewProductHistoryButtonProps extends ButtonProps {
  toggleHistory: () => void
}

export const ViewProductHistoryButton = ({
  toggleHistory,
  ...rest
}: ViewProductHistoryButtonProps) => (
  <S.Button type="default" icon={<HistoryOutlined />} onClick={toggleHistory} {...rest}>
    Ver histórico
  </S.Button>
)
