import { type Editor } from '@tiptap/react'
import { Bold, Italic, List, ListOrdered } from 'lucide-react'

import * as S from './styles'

type ToolbarProps = {
  editor: Editor | null
}

export const TiptapToolbar = ({ editor }: ToolbarProps) => {
  if (!editor) {
    return null
  }

  return (
    <S.Container>
      <S.ToolbarBox>
        <button
          type="button"
          className={editor.isActive('bold') ? 'is-button-active' : 'is-button-disabled'}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <Bold style={{ width: '18px', height: '18px' }} />
        </button>
        <button
          type="button"
          className={editor.isActive('italic') ? 'is-button-active' : 'is-button-disabled'}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <Italic style={{ width: '18px', height: '18px' }} />
        </button>
        <button
          type="button"
          className={editor.isActive('bulletList') ? 'is-button-active' : 'is-button-disabled'}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <List style={{ width: '18px', height: '18px' }} />
        </button>
        <button
          type="button"
          className={editor.isActive('orderedList') ? 'is-button-active' : 'is-button-disabled'}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <ListOrdered style={{ width: '18px', height: '18px' }} />
        </button>
      </S.ToolbarBox>
    </S.Container>
  )
}
