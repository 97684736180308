import { useEffect, useRef } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { InputProps } from 'antd'
import type { SearchProps } from 'antd/es/input'

import * as S from './styles'

import { useToggle } from '@/common'

interface SearchInputProps extends InputProps, SearchProps {
  initialValue?: string
  onClose: () => void
  defaultOpen?: boolean
}

export const SearchInput = ({ initialValue, onClose, defaultOpen, ...rest }: SearchInputProps) => {
  const [showInput, toggleInput] = useToggle(
    initialValue ? true : defaultOpen ? defaultOpen : false,
  )
  const lastActionByUser = useRef(false)

  useEffect(() => {
    if (showInput && !initialValue && !lastActionByUser.current) {
      toggleInput()
    }

    lastActionByUser.current = false
  }, [initialValue, showInput, toggleInput])

  function handleOpenInput() {
    lastActionByUser.current = true
    toggleInput()
  }

  function handleCloseInput() {
    onClose()
    toggleInput()
  }

  return (
    <S.Container>
      {!showInput ? (
        <S.InputTrigger onClick={handleOpenInput}>
          <SearchOutlined />
        </S.InputTrigger>
      ) : (
        <S.SearchInput
          defaultValue={initialValue}
          placeholder="Buscar..."
          suffix={<S.CloseIcon onClick={handleCloseInput} />}
          {...rest}
          onSearch={(value) => {
            // Se o usuário enviar o formulário sem digitar nada, fecha o input
            if (!value) {
              return handleCloseInput()
            }

            rest.onSearch && rest.onSearch(value)
          }}
        />
      )}
    </S.Container>
  )
}
