import { Typography } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-layout {
    background-color: #fff;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  .ant-typography {
    line-height: initial;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding-top: 40px;
  padding-bottom: 80px;
`

export const Text = styled(Typography.Text)`
  font-size: 24px;
  color: #434343;

  strong {
    font-weight: 600;
  }
`
