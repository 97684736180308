import { ReactNode } from 'react'

import { Dropdown, DropdownProps, MenuProps } from 'antd'

import * as S from './styles'

interface TableActionProps extends DropdownProps {
  dropdownOptions: MenuProps['items']
  icon?: ReactNode
  isDisabled?: boolean
  isCard?: boolean
}

export const TableAction = ({
  dropdownOptions,
  icon,
  isDisabled,
  isCard,
  ...props
}: TableActionProps) => {
  return (
    <Dropdown
      menu={{ items: dropdownOptions }}
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      placement={isCard === true ? 'bottom' : 'bottomRight'}
      disabled={isDisabled}
      {...props}
    >
      <div
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {icon ? icon : <S.DropdownIcon />}
      </div>
    </Dropdown>
  )
}
