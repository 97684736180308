// import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
interface Authorization {
  isUserAuthenticated: boolean
  accessToken: string | undefined
  refreshToken: string | undefined
  confirmationToken: string | undefined
  idToken: string | undefined
}

interface AuthStoreState extends Authorization {
  signOut: () => void
}

const INITIAL_STATE: Authorization = {
  accessToken: undefined,
  refreshToken: undefined,
  idToken: undefined,
  confirmationToken: undefined,
  isUserAuthenticated: false,
}

const authStore = createWithEqualityFn<AuthStoreState>()(
  devtools(
    persist(
      (set) => ({
        ...INITIAL_STATE,

        signOut: () => {
          set({ ...INITIAL_STATE }, false, 'sign-out')
        },
      }),
      { name: 'mktplace-auth-store' },
    ),
    { name: 'mktplace-auth-store' },
  ),
  shallow,
)

export const signOut = authStore.getState().signOut

export default authStore
