import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/es/locale/pt_BR'
import { Toaster } from 'sonner'
import { ThemeProvider } from 'styled-components'

import { Routes } from './routes'
import { GlobalStyle, antdTheme, styledTheme } from './styles'

import { queryClient } from '@/services'

import './styles/fonts.css'

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={antdTheme} locale={ptBR}>
        <ThemeProvider theme={styledTheme}>
          <GlobalStyle />
          <Routes />
          <Toaster
            richColors
            closeButton
            position="top-right"
            toastOptions={{ style: { fontFamily: 'AcerFoco' } }}
          />
        </ThemeProvider>
      </ConfigProvider>
      <ReactQueryDevtools buttonPosition="bottom-right" />
    </QueryClientProvider>
  )
}

export { App }
