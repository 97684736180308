import { useMutation } from '@tanstack/react-query'

import { agenciesQueries } from '../queries'
import { AgencyMutation } from '../types'

import { api, queryClient } from '@/services'

export const createAgencyErrorMessages = new Map([
  ['There is already an agency with this name', 'Já existe uma agência com este nome.'],
])

export const useCreateAgencyMutation = () => {
  return useMutation({
    mutationFn: async (data: AgencyMutation) => {
      return api.post('agencies', data)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: agenciesQueries.all() }),
  })
}
