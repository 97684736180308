import { Skeleton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 16px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const DisplayInfo = styled.h3`
  width: 100%;
  font-weight: 700;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.brand};
`

export const DisplayFreightInfo = styled.h3`
  width: calc(100% / 4.5);
  height: ${rem(0)};
  font-weight: 700;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.brand};
`

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;

  padding: ${rem(14)};
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.colors.white};
`

export const Summary = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  font-size: ${rem(12)};
  background-color: ${({ theme }) => theme.colors.white};
`

export const ItensContainer = styled.div`
  display: flex;
  height: ${rem(224)};
  gap: 16px;

  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.colors.white};

  padding: ${rem(14)};
`

export const ImagesContainer = styled.div`
  display: flex;
  height: fit-content;
  height: ${rem(174)};
  gap: 16px;

  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.colors.white};

  padding: ${rem(14)};
`

export const ItemLoading = styled(Skeleton.Input)`
  width: 100% !important;
  min-width: ${rem(100)} !important;
  height: ${rem(196)} !important;
`

export const ImagesLoading = styled(Skeleton.Input)`
  width: 100% !important;
  min-width: ${rem(100)} !important;
  height: ${rem(130)} !important;
`
