import { UserOutlined } from '@ant-design/icons'
import { Avatar, Table, TableProps } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'

import * as S from './styles'

import { BgStatusTags, type Timeline } from '@/features/BG'

interface BgHistoryTableProps extends TableProps {
  data: Timeline[]
}

export const BgHistoryTable = ({ data, ...props }: BgHistoryTableProps) => {
  const columns: ColumnsType<Timeline> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 212,
      className: 'status-column',
      render: (status) => <BgStatusTags showDescription status={status} />,
    },
    {
      title: 'Data/Hora',
      dataIndex: 'date',
      key: 'date',
      width: 290,
      render: (date) => (
        <S.TableDataContainer>
          <S.DescriptionDate>{dayjs(date).format('DD/MM/YYYY')}</S.DescriptionDate>
          <S.DescriptionText>
            <i>{dayjs(date).format('HH:mm')}</i>
          </S.DescriptionText>
        </S.TableDataContainer>
      ),
    },
    {
      title: 'Usuário',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (user_id) => (
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Avatar icon={<UserOutlined />} />
          <S.DescriptionText>{user_id}</S.DescriptionText>
        </div>
      ),
    },
  ]

  return (
    <S.Container>
      <Table
        dataSource={data}
        columns={columns}
        rowClassName="custom-row"
        footer={() => null}
        {...props}
        scroll={{ y: 248 }}
      />
    </S.Container>
  )
}
