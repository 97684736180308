import { createGlobalStyle } from 'styled-components'

import AcerFocoBlack from '../../assets/fonts/AcerFoco-Black.ttf'
import AcerFocoBold from '../../assets/fonts/AcerFoco-Bold.ttf'
import AcerFocoLigth from '../../assets/fonts/AcerFoco-Light.ttf'
import AcerFocoRegular from '../../assets/fonts/AcerFoco-Regular.ttf'
import AcerFocoSemiBold from '../../assets/fonts/AcerFoco-Semibold.ttf'

import CloseCircleOutlined from '@/assets/close-circle-outlined.svg'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoLigth}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
  }

  pre {
    font-family: 'AcerFoco';
    src: url(${AcerFocoBold}) format('truetype');
    font-style: normal;
    font-display: auto;
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'AcerFoco', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: ${({ theme }) => theme.colors.text};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
  }

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ul, li {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .ant-form-item-explain-error {
    background: url(${CloseCircleOutlined}) no-repeat left center;
    background-position: 8px center;
    color: ${({ theme }) => theme.colors.error};
    border: 1px solid ${({ theme }) => theme.colors.error};
    background-color: rgba(254, 32, 0, 0.1);
    padding: 0 12px 0 24px;
    border-radius: 6px;
    margin: 4px 0 8px;
  }

  .ant-form-show-help-item {
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    display: none !important;
    opacity: 0 !important;
  }

  .toast-sucess {
    [data-sonner-toast][data-type=success] {
      background: #E9FFD4 !important;
      border: 1px solid #7BBA25 !important;
      color: #000000D9 !important;
      margin-right: 0px !important;
    }

    --toast-icon-margin-end: 0px !important;
    --toast-icon-margin-start: 0px !important;
  }


`
