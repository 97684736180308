export function translateFileExistsError(errorMessage: string) {
  const fileNameRegex = /A file with name (.*?) already exists/

  // Extract the file name from the error message
  const match = errorMessage.match(fileNameRegex)

  if (match && match[1]) {
    const fileName = match[1]
    return {
      success: true,
      message: `O arquivo ${fileName} já existe`,
    }
  }

  // Return original message if pattern doesn't match
  return {
    success: false,
    message: errorMessage,
  }
}
