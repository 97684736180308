import * as Sentry from '@sentry/react'
import { QueryClient } from '@tanstack/react-query'

import { clearRolesStore } from '@/app'
import { signOut } from '@/app/stores/auth'

export async function clearAllCaches(queryClient?: QueryClient): Promise<void> {
  signOut()
  clearRolesStore()
  localStorage.clear()
  sessionStorage.clear()

  queryClient?.clear()

  if (import.meta.env.PROD) {
    Sentry.setUser(null)
  }

  try {
    const cacheNames = await caches.keys()
    await Promise.all(cacheNames.map((name) => caches.delete(name)))
  } catch (error) {
    console.error('Failed to clear caches:', error)
  }
}
