import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Button as AntdButton,
  Typography,
  Divider as AntdDivider,
  Skeleton,
  Button,
  Segmented,
} from 'antd'
import { rem, rgba } from 'polished'
import styled, { css } from 'styled-components'

import type { ProductStatus } from '@/features/BG'

interface InfoCardProps {
  $type: ProductStatus
}

interface InfoCardValueProps {
  $isClickActive: boolean
}

export const FooterSidebar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  height: 100%;
  font-size: 16px;
  .ant-typography {
    text-align: center;
  }
`

export const CleanButton = styled(AntdButton)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
`

export const ExclamationIcon = styled(ExclamationCircleOutlined)`
  font-size: 56px;
  color: #ff4d4d;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-weight: 400;
    font-size: ${rem(24)};
    color: ${({ theme }) => theme.colors.bgColor};
    margin: 0;
  }
`

export const Title = styled(Typography.Title)`
  font-weight: 400;
  margin-bottom: 0 !important;
`

export const Divider = styled(AntdDivider)<{ color?: string; thickness?: number }>`
  color: ${({ color }) => (color ? color : '#414243')};
  border-left: ${({ thickness }) => (thickness ? `${thickness}px` : '1px')} solid
    ${({ color }) => (color ? color : '#414243')};
  border-color: #414243;
  height: 18px;
  margin: 0 12px;
`

export const TotalBgs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding-right: 8px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`
export const CreateKitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.brand};
`

export const RegisterButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.brand};
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`

export const TotalBgsValue = styled.strong`
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.colors.blue};
`

export const TotalBgsText = styled(Typography.Text)`
  font-size: ${rem(20)};
  line-height: normal;
`

export const InfoCardsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  padding-bottom: 8px;
`

export const InfoCard = styled.div<InfoCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  flex: 1;
  height: ${rem(98)};

  border: 1px solid ${({ theme }) => theme.colors.colorBorderPrimary};
  border-radius: 6px;

  background: ${({ theme }) => theme.colors.white};

  border-bottom: ${({ $type, theme }) => {
    if ($type === 'EM RASCUNHO DO PRODUTO') return `5px solid ${rgba('#945FB9', 0.5)}`
    if ($type === 'PENDENTE DE ENVIO PARA AGÊNCIA') return `5px solid #733D99`
    if ($type === 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA') return `5px solid #FAAD14`
    if ($type === 'PENDENTE DE CADASTRO NO PROTHEUS') return `5px solid #EA7B26`
    if ($type === 'EM RASCUNHO NO E-COMMERCE') return `5px solid #B44E00`
    if ($type === 'PENDENTE DE PRECIFICAÇÃO') return `5px solid #004FC7`
    if ($type === 'PUBLICADO') return `5px solid #1E9493`
    if ($type === 'DESPUBLICADO') return `5px solid #666666`
    if ($type === 'DESCONTINUADO') return `5px solid ${rgba('#999', 0.5)}`

    return `5px solid ${theme.colors.colorBorderPrimary}`
  }};

  /* transition: box-shadow 0.2s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brand};
  } */
`

export const InfoCardValue = styled.span<InfoCardValueProps>`
  font-size: ${rem(32)};
  font-weight: 300;
  color: #999;
  text-align: center;

  cursor: default;
  transition: all 0.2s ease;

  ${({ $isClickActive }) =>
    $isClickActive &&
    css`
      cursor: pointer;
      color: ${({ theme }) => theme.colors.brand};

      &:hover {
        filter: brightness(1.2);
      }
    `}
`

export const NoValueText = styled(Typography.Text)`
  font-size: ${rem(40)};
  color: #999;
  line-height: initial;
`

export const InfoCardStatus = styled.strong`
  font-size: ${rem(10)};
  text-align: center;
  font-weight: 700;
`

export const ActiveFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
`

export const PageActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`

export const ProductViewSegment = styled(Segmented)`
  /* padding: 4px; */
  background-color: white;

  .ant-segmented-item {
    color: ${({ theme }) => theme.colors.brand};

    border: 1px solid white;

    transition:
      border 0.15s ease-in,
      border-radius 0.15s ease-in;

    :hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }

  .ant-segmented-item-selected {
    font-weight: 600;

    border: 1px solid ${({ theme }) => theme.colors.brand};
  }

  &.ant-segmented
    .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const GridCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 16px;
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`

export const InfoCardLoading = styled(Skeleton.Input)`
  width: 100% !important;
  min-width: ${rem(100)} !important;
  height: ${rem(98)} !important;
`

export const ActiveFiltersListLoading = styled.span``

export const ChangeViewButtonLoading = styled(Skeleton.Avatar)`
  width: ${rem(30)};
  height: ${rem(30)};
`

export const BgCardLoading = styled(Skeleton.Input)`
  width: 100% !important;
  min-width: ${rem(240)} !important;
  height: ${rem(338)} !important;
`

export const LayoutErrorContainer = styled.div`
  height: 50vh;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`

export const PaginationContainer = styled.div`
  margin-top: auto;
`
