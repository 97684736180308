import { BgFileUploadData } from '@/features/BG'

export function getUploadedImageUrl(imageFile: BgFileUploadData): string {
  if (imageFile.file && imageFile.file.size > 0) {
    const url = URL.createObjectURL(imageFile.file)
    return url
  } else {
    return imageFile.url
  }
}
