import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, toastError } from '@/common'
import { BaseModal } from '@/components'
import {
  createProductTypeErrorMessages,
  useCreateProductTypeMutation,
  ProductTypeForm,
  type ProductTypeFormData,
} from '@/features/product-types'

type CreateProductTypeModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateProductTypeModal = ({ isOpen, onClose }: CreateProductTypeModalProps) => {
  const [form] = Form.useForm<ProductTypeFormData>()

  const createProductTypeMutation = useCreateProductTypeMutation()

  async function handleCreateProductType(data: ProductTypeFormData) {
    try {
      const promise = createProductTypeMutation.mutateAsync({
        name: data.name,
        global_category_id: data.global_category_id.value,
      })

      toast.promise(promise, {
        loading: 'Salvando tipo de produto...',
        success: () => {
          onClose()

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Tipo de Produto <span style={{ fontWeight: 'bold' }}>criado</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            errorMessages: createProductTypeErrorMessages,
            defaultMessage: 'Erro ao salvar tipo de produto.',
          }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Criar Tipo de Produto'}
      width={717}
      height={'auto'}
      footer={
        <>
          <S.CancelButton
            type="link"
            htmlType="button"
            onClick={onClose}
            disabled={createProductTypeMutation.isPending}
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
            disabled={createProductTypeMutation.isPending}
          >
            Salvar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        requiredMark
        layout="vertical"
        name="create-product-type"
        onFinish={handleCreateProductType}
        validateTrigger={['onSubmit']}
      >
        <ProductTypeForm />
      </Form>
    </BaseModal>
  )
}

export { CreateProductTypeModal }
