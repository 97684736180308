import { useMemo } from 'react'

import { ModuleKey, RoleKey, RolePermissions } from '../types'
import { useAuth } from './useAuth'

import { useRolesStore } from '@/app'

export const useRolePermissions = (moduleKey: ModuleKey, roleKey: RoleKey): RolePermissions => {
  const { formatPagePermissions } = useAuth()
  const getPagePermissions = useRolesStore((state) => state.getPagePermissions)
  const userRoles = useRolesStore((state) => state.userRoles)

  return useMemo(() => {
    // console.log('MEMO USER ROLES', { userRoles })
    const permissions = getPagePermissions({ moduleKey, roleKey })
    return formatPagePermissions(permissions)
  }, [formatPagePermissions, getPagePermissions, moduleKey, roleKey, userRoles])
}
