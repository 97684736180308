import { useState } from 'react'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { AgencyCard } from '../../AgencyCard'
import { AgencyForm } from '../../Form'
import * as S from './styles'

import { ApiError, BaseModalProps, toastError } from '@/common'
import { BaseModal } from '@/components'
import {
  createAgencyErrorMessages,
  useCreateAgencyMutation,
} from '@/features/agency/mutations/create-agency'
import { Responsible } from '@/features/agency/types'

const CreateAgencyModal = ({ isOpen, onClose }: BaseModalProps) => {
  const [form] = Form.useForm()

  const createAgencyMutation = useCreateAgencyMutation()

  const [responsibles, setResponsibles] = useState<Responsible[]>([])
  const [isAgencyNameDisabled, setIsAgencyNameDisabled] = useState(false)

  const handleAddResponsible = () => {
    form
      .validateFields(['name', 'responsible', 'email'])
      .then((values) => {
        const emailExists = responsibles.some((agency) => agency.email === values.email)

        if (emailExists) {
          return form.setFields([
            {
              name: 'email',
              errors: ['Este e-mail já está cadastrado'],
            },
          ])
        }

        const newAgency = {
          name: values.responsible,
          email: values.email,
        }

        setResponsibles((prevAgencies) => [...prevAgencies, newAgency])

        setIsAgencyNameDisabled(true)

        form.resetFields(['responsible', 'email'])
      })
      .catch((errorInfo) => {
        console.log('Erro ao validar:', errorInfo)
      })
  }

  async function handleCreateAgency() {
    const newAgency = form.getFieldsValue()
    try {
      const promise = createAgencyMutation.mutateAsync({
        name: newAgency.name,
        responsibles: [
          ...responsibles.map((agency) => ({ name: agency.name, email: agency.email })),
        ],
      })

      toast.promise(promise, {
        loading: 'Salvando agência...',
        success: () => {
          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Agência <span style={{ fontWeight: 'bold' }}>criada</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Erro ao cadastrar agência',
            errorMessages: createAgencyErrorMessages,
          }),
      })

      await promise

      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    setResponsibles([])
    form.resetFields()
    setIsAgencyNameDisabled(false)
    onClose()
  }

  const handleDeleteCard = (index: number) => {
    setResponsibles((prevAgencies) => {
      const updatedAgencies = prevAgencies.filter((_, i) => i !== index)

      if (updatedAgencies.length === 0) {
        setIsAgencyNameDisabled(false)
        form.resetFields()
      }
      return updatedAgencies
    })
  }

  const handleClearAllAgencies = () => {
    setResponsibles([])
    setIsAgencyNameDisabled(false)
    form.resetFields()
  }

  const agenciesData = {
    name: form.getFieldValue('name'),
    responsibles: responsibles,
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Cadastrar Agência'}
      width={862}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={handleCancel}
            disabled={createAgencyMutation.isPending}
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="button"
            onClick={handleCreateAgency}
            disabled={responsibles.length === 0 || createAgencyMutation.isPending}
          >
            Cadastrar
          </Button>
        </>
      }
    >
      <S.Container>
        <Form
          form={form}
          onFinish={handleAddResponsible}
          disabled={createAgencyMutation.isPending}
          layout="vertical"
          requiredMark
          autoComplete="off"
        >
          <AgencyForm isAgencyNameDisabled={isAgencyNameDisabled} />
        </Form>

        {responsibles.length > 0 && (
          <S.ClearAllButton type="link" onClick={() => handleClearAllAgencies()}>
            Limpar todos os responsáveis
          </S.ClearAllButton>
        )}
        <S.ScrollableResponsibles>
          <S.Row>
            {agenciesData.responsibles.map((_, index) => (
              <AgencyCard
                isCreating
                key={index}
                index={index}
                data={agenciesData}
                onDelete={() => handleDeleteCard(index)}
              />
            ))}
          </S.Row>
        </S.ScrollableResponsibles>
      </S.Container>
    </BaseModal>
  )
}

export { CreateAgencyModal }
