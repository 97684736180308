import { useEffect, useRef } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

import { Image, Spin } from 'antd'

import * as S from './styles'

import acerLogo from '@/assets/acer-logo-green.svg'
import redirectImage from '@/assets/redirect-image.svg'
import { useRefreshLoginToken } from '@/features/auth'

const RedirectLoginPage = () => {
  const [searchParams] = useSearchParams()
  const refreshLoginTokenMutation = useRefreshLoginToken()
  const executedMutation = useRef(false)

  const refreshToken = searchParams.get('refresh-token')
  const userId = searchParams.get('user-id')
  const redirectPage = searchParams.get('page')

  useEffect(() => {
    if (
      refreshToken &&
      userId &&
      !refreshLoginTokenMutation.isPending &&
      !executedMutation.current
    ) {
      refreshLoginTokenMutation.mutateAsync({ refreshToken, userId, redirectPage })
      executedMutation.current = true
    }
    // only want this effect to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!refreshToken || !userId) {
    return <Navigate to="/auth/login" replace />
  }

  return (
    <S.Container>
      <S.Content>
        <Image src={acerLogo} alt={'Acer logo'} width={330} height={80} preview={false} />

        <S.TextContainer>
          <S.Text>
            Você está sendo redirecionado para o <strong>Cadastro de Produto</strong>
          </S.Text>

          <Spin size="small" />
        </S.TextContainer>

        <Image
          src={redirectImage}
          alt={'Redirect Login'}
          width={400}
          height={338}
          preview={false}
        />
      </S.Content>
    </S.Container>
  )
}

export { RedirectLoginPage }
