import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;

  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 2px;
  }
`
