import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '@/features/BG'
import { api } from '@/services'

interface SendBgToAgenciesMutation {
  productId: string
  agencies: string[]
}

export const useSendBgToAgenciesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, agencies }: SendBgToAgenciesMutation) => {
      return api.post(`/agencies/send_email/${productId}`, agencies)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
