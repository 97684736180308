import { Button } from 'antd'

import * as S from './styles'

import { BaseModalProps, filtersRemap, useToggle, specificationsRemap } from '@/common'
import { BaseModal } from '@/components'
import { useEditSpecificationsMutation, type Specification } from '@/features/specifications'

interface ViewSpecificationModalProps extends BaseModalProps {
  data: Specification
}

export const ViewSpecificationModal = ({ isOpen, onClose, data }: ViewSpecificationModalProps) => {
  const [isEditing] = useToggle(false)
  const editSpecificationsMutation = useEditSpecificationsMutation()

  // async function handleEditSpecifications(values: SpecificationFormData) {
  //   const payload = {
  //     id: data.id,
  //     field_type_id: values.field_type_id,
  //     product_type_vtex_id: values.product_type_vtex_id,
  //     field_group_id: values.field_group_id,
  //     name: values.name,
  //     position: values.position,
  //   }

  //   const promise = editSpecificationsMutation.mutateAsync(payload)

  //   toast.promise(promise, {
  //     loading: 'Editando especificação...',
  //     success: () => {
  //       onClose()

  //       return (
  //         <S.ToastContainer>
  //           <S.CircleOutlined />

  //           <div>
  //             Especificação <span style={{ fontWeight: 'bold' }}>editada</span> com{' '}
  //             <span style={{ fontWeight: 'bold' }}>sucesso</span>!
  //           </div>
  //         </S.ToastContainer>
  //       )
  //     },
  //     className: 'toast-sucess',
  //     error: (error: ApiError) =>
  //       toastError({
  //         error,
  //         errorMessages: editSpecificationsErrorMessages,
  //         defaultMessage: 'Erro ao salvar especificação.',
  //       }),
  //   })
  // }

  const SpecificationsView = () => (
    <>
      <S.FieldRow>
        <S.SpecificationsData>
          <strong>Tipo de Campo</strong>

          <span>
            {(specificationsRemap.get(data?.field_type_id) as string) || data?.field_type_id}
          </span>
        </S.SpecificationsData>

        <S.SpecificationsData>
          <strong>Tipo de Produto</strong>

          <span>
            {(filtersRemap.get(data?.product_type?.name) as string) || data?.product_type?.name}
          </span>
        </S.SpecificationsData>

        <S.SpecificationsData>
          <strong>Grupo de Especificações</strong>

          <span>{data?.field_group?.name}</span>
        </S.SpecificationsData>
      </S.FieldRow>

      <S.FieldRow>
        <S.SpecificationsData>
          <strong>Nome</strong>

          <span>{data?.name}</span>
        </S.SpecificationsData>
      </S.FieldRow>

      <S.FieldRow>
        <S.SpecificationsData>
          <strong>Posição do Campo</strong>

          <span>{data?.position}</span>
        </S.SpecificationsData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais da Especificação"
      width={717}
      footer={
        <Button
          onClick={onClose}
          type="link"
          htmlType="button"
          disabled={editSpecificationsMutation.isPending}
        >
          Fechar
        </Button>
      }
    >
      <S.Container>{!isEditing ? <SpecificationsView /> : null}</S.Container>
    </BaseModal>
  )
}
