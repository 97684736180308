import { FileSearchOutlined } from '@ant-design/icons'
import type { ButtonProps } from 'antd'

import * as S from './styles'

interface ViewBgFilesButtonProps extends ButtonProps {
  toggleBgFiles: () => void
}

export const ViewBgFilesButton = ({ toggleBgFiles, ...rest }: ViewBgFilesButtonProps) => (
  <S.Button type="default" icon={<FileSearchOutlined />} onClick={toggleBgFiles} {...rest}>
    Acessar Arquivos
  </S.Button>
)
