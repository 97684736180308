import { Tabs } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DisplayWindow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(16)};
  height: ${rem(455)};
  flex: 1;
`

export const PictureDisplay = styled.div`
  width: ${rem(280)};
  height: ${rem(280)};
  margin: ${rem(5)};
  border-radius: ${rem(6)};
  border: 1px solid ${({ theme }) => theme.customTokens.divider.lightGray};

  background-color: #fff;
  flex-shrink: 0;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SpecificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: ${rem(235)};
  overflow-y: auto;
  margin-bottom: ${rem(5)};
  margin-top: ${rem(10)};
  padding: ${rem(16)};
  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
  border-radius: ${rem(6)};
  border: 1px solid ${({ theme }) => theme.customTokens.divider.lightGray};
  gap: ${rem(12)};
`

export const PublicationStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${rem(14)};
  height: ${rem(30)};
  width: ${rem(190)};
  background-color: ${({ theme }) => theme.colors.white};
  color: #5274ab;
  box-sizing: border-box;
  border-radius: ${rem(6)};
  border: 1px solid #5274ab;
`

export const ProductTag = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.customTokens.text.darkGray};
  .ant-divider {
    margin-left: ${rem(5)};
    margin-right: ${rem(5)};
  }
`

export const DisplayName = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-weight: 700;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.link};
`

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`

export const ProductSpecifications = styled.ul`
  width: 100%;
  color: #00000060;
  font-size: ${rem(14)};
  line-height: ${rem(18)};
`

export const PlatformInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  align-self: center;
`

export const PlatformContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${rem(8)};
`

export const PlatformStatus = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(4)};
  color: ${({ theme }) => theme.customTokens.listBox.bgScrollBar};
`

export const PlatformLabel = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.customTokens.text.darkGray};
`

export const DataTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  .ant-collapse-expand-icon {
    color: ${({ theme }) => theme.colors.brand};
  }
  .ant-input {
    height: 32px;
  }
  .ant-input-search {
    width: 200px;
  }
  .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.brand};
  }
  .ant-collapse-header {
    color: #00000060;
    font-weight: 600;
    font-size: 14px;
    height: 30px;
    margin-bottom: 8px;
  }
  .ant-collapse-borderless {
    border-bottom: 1px solid ${({ theme }) => theme.customTokens.divider.lightGray};
  }
`
export const CollapseFormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-form-item {
    width: 100%;
    margin: 0;
  }

  .ant-checkbox-group {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: center;
    column-gap: 32px;
    row-gap: 16px;

    margin: 0;
  }
`
export const TabsTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.customTokens.input.label};
`

export const StyledTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    align-items: flex-end;
    justify-content: space-between;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 0 0 auto;
  }

  .ant-tabs-nav-list {
    /* padding-top: ${rem(16)}; */
  }

  .ant-tabs-tab {
    font-size: ${rem(16)};
    line-height: ${rem(18)};
    font-weight: 400;
    font-family: ${({ theme }) => theme.base.fontFamily};
    color: ${({ theme }) => theme.base.colorPrimary};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.customTokens.tab.selectedColor};
  }

  .ant-tabs-ink-bar {
    height: 1px !important;
    background: ${({ theme }) => theme.customTokens.tab.selectedColor} !important;
  }

  .ant-tabs-nav::before {
    border-bottom: transparent !important;
  }

  .ant-tabs-extra-content {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    min-height: ${rem(40)};
  }

  .ant-collapse-header {
    padding-left: 0 !important;
  }
`
