import { Badge } from 'antd'
import styled from 'styled-components'

export const ButtonContent = styled.div`
  .ant-btn {
    padding: 0;
  }
`
export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ResponsibleCount = styled(Badge)`
  .ant-scroll-number {
    background-color: #52c41a;
    margin-left: 6px;
    width: 10px;
    bottom: 1px;
    span {
      right: 5px;
    }
  }
`
