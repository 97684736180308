import { persist, devtools, createJSONStorage } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface UserFilters {
  name_or_email?: string
  user_type?: string
  enabled?: boolean
  groups?: string[]
}

interface UserFilterStore {
  filters: UserFilters
  resetFilters: () => void
  setFilters: (filters: UserFilters) => void
}

const userFilterStore = createWithEqualityFn<UserFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          name_or_email: undefined,
          user_type: undefined,
          enabled: undefined,
          groups: undefined,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-filters',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                name_or_email: undefined,
                user_type: undefined,
                enabled: undefined,
                groups: undefined,
              },
            },
            false,
            'reset-filters',
          )
        },
      }),
      { name: 'user-filter-store', storage: createJSONStorage(() => sessionStorage) },
    ),
    { name: 'user-filter-store' },
  ),
  shallow,
)

export const resetUserFilters = userFilterStore.getState().resetFilters

export { userFilterStore, userFilterStore as useUserFilters }

export default userFilterStore
