import type { TabsProps } from 'antd'

import * as S from './styles'

type AccessTitleTabProps = {
  title: string
} & TabsProps

export const AccessTitleTabs = ({ title, ...rest }: AccessTitleTabProps) => {
  const items: TabsProps['items'] = [
    {
      key: title,
      label: title,
    },
  ]

  return <S.Tabs items={items} {...rest} />
}
