import { useEffect } from 'react'

import { useQueries } from '@tanstack/react-query'
import { Form, Select, Input } from 'antd'

import * as S from './styles'

import { filtersRemap, specificationsRemap } from '@/common'
import { productTypeQueries } from '@/features/product-types'
import { type SpecificationFormData, specificationQueries } from '@/features/specifications'

export const SpecificationForm = () => {
  const form = Form.useFormInstance<SpecificationFormData>()

  const selectedProductType = Form.useWatch('product_type_vtex_id', form)

  useEffect(() => {
    // Clear field_group_id when selectedProductType changes
    form.setFieldValue('field_group_id', '')
  }, [form, selectedProductType])

  const [
    { data: productTypes, isLoading: isProductTypesLoading },
    { data: specificationsFieldTypes, isLoading: isSpecificationsFieldTypesLoading },
    { data: groupSpecifications, isLoading: isGroupSpecificationsLoading },
  ] = useQueries({
    queries: [
      { ...productTypeQueries.list({ page: 1, per_page: 100 }) },
      { ...specificationQueries.fieldTypes() },
      { ...productTypeQueries.fieldGroups(selectedProductType), enabled: !!selectedProductType },
    ],
  })

  const productTypeOptions = productTypes?.product_types?.map((value) => ({
    value: value.vtex_id,
    label: (filtersRemap.get(value.name) as string) || value.name,
  }))

  const specificationsFieldTypeOptions = specificationsFieldTypes?.map((value) => ({
    value: value.id,
    label: (specificationsRemap.get(value.name) as string) || value.name,
  }))

  const GroupSpecifications = groupSpecifications?.map((value) => ({
    value: value.id,
    label: value.name,
  }))

  return (
    <S.FormContainer>
      <S.FormRow>
        <Form.Item
          name={'field_type_id'}
          label={'Tipo de Campo'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            loading={isSpecificationsFieldTypesLoading}
            options={specificationsFieldTypeOptions}
          />
        </Form.Item>

        <Form.Item
          name={'product_type_vtex_id'}
          label={'Tipo de Produto'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select size="large" loading={isProductTypesLoading} options={productTypeOptions} />
        </Form.Item>

        <Form.Item
          name={'field_group_id'}
          label={'Grupo de Especificações'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            disabled={!selectedProductType}
            loading={!!selectedProductType && isGroupSpecificationsLoading}
            options={GroupSpecifications}
          />
        </Form.Item>
      </S.FormRow>

      <S.FormRow>
        <Form.Item
          name={'name'}
          label={'Nome'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
      </S.FormRow>

      <S.FormRow>
        <Form.Item
          name={'position'}
          label={'Posição do Campo'}
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            {
              pattern: /[0-9]/,
              message: 'Este campo aceita apenas valores númericos!',
            },
            {
              min: 0,
              message: 'Este campo aceita apenas valores positivos!',
              validator: (_, value) => {
                if (Number(value) < 0) {
                  return Promise.reject('Este campo aceita apenas valores positivos!')
                }

                return Promise.resolve()
              },
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>
      </S.FormRow>
    </S.FormContainer>
  )
}
