import { Skeleton } from 'antd'

import * as S from './styles'

export const KitPageSkeleton = () => {
  return (
    <>
      <S.StatusContainer>
        <Skeleton.Input active />
      </S.StatusContainer>
      <Skeleton.Input active style={{ width: '100%' }} />

      <S.Container>
        <S.InfoContainer>
          <S.SummaryContainer>
            <S.DisplayInfo>
              <Skeleton.Input active block />
            </S.DisplayInfo>

            <S.DisplayInfo>
              <Skeleton.Input active block />
            </S.DisplayInfo>

            <S.DisplayFreightInfo>
              <Skeleton.Input active block />
            </S.DisplayFreightInfo>

            <S.Summary>
              <Skeleton.Input active style={{ height: 200 }} block />
            </S.Summary>
          </S.SummaryContainer>
        </S.InfoContainer>
      </S.Container>

      <S.ItensContainer>
        {Array.from({ length: 8 }).map((_, index) => (
          <S.ItemLoading key={index} active />
        ))}
      </S.ItensContainer>

      <S.ImagesContainer>
        {Array.from({ length: 8 }).map((_, index) => (
          <S.ImagesLoading key={index} active />
        ))}
      </S.ImagesContainer>
    </>
  )
}
