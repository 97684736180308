import styled from 'styled-components'

export const ButtonContent = styled.div`
  .ant-btn {
    padding: 0;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`
