import { Navigate, Outlet } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { clearAllCaches, useAuth } from '@/common'
import { PageErrorBoundary } from '@/components'
import { PageLayout } from '@/layouts'

interface AuthenticatedRouteProps {
  allowedRoles?: string[]
}

export const AuthenticatedRoute = ({ allowedRoles }: AuthenticatedRouteProps) => {
  const { userAllowedModules } = useAuth()

  if (allowedRoles && userAllowedModules) {
    if (!userAllowedModules || userAllowedModules.length === 0) {
      console.error('No roles')

      clearAllCaches()
      return <Navigate to="/auth/login" replace />
    }

    const isAllowed =
      userAllowedModules.includes('*') ||
      userAllowedModules.some((role) => allowedRoles.includes(role))

    if (!isAllowed) {
      console.error('Not allowed')

      return <Navigate to="/" replace />
    }

    return (
      <Sentry.ErrorBoundary
        fallback={
          <PageLayout>
            <PageErrorBoundary />
          </PageLayout>
        }
      >
        <Outlet />
      </Sentry.ErrorBoundary>
    )
  }

  return (
    <Sentry.ErrorBoundary
      fallback={
        <PageLayout>
          <PageErrorBoundary />
        </PageLayout>
      }
    >
      <Outlet />
    </Sentry.ErrorBoundary>
  )
}
