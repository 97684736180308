import { useQueryClient, useMutation } from '@tanstack/react-query'

import { agenciesQueries } from '../queries'
import { Responsible } from '../types'

import { api } from '@/services'

interface EditAgencyMutation {
  id: string
  name: string
  responsibles: Responsible[]
}

export const editAgencyErrorMessages = new Map([
  ['Those responsible cannot be empty', 'É necessário que haja pelo menos um responsável.'],
  ['There is already an agency with this name', 'Já existe uma agência com este nome.'],
])

export const useEditAgencyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditAgencyMutation) => api.put(`/agencies/${data.id}`, data),
    onMutate: async (editedAgency) => {
      await queryClient.cancelQueries({ queryKey: agenciesQueries.all() })

      const cachedAgenciesList = queryClient.getQueryData(agenciesQueries.list().queryKey)

      if (cachedAgenciesList) {
        const updatedAgenciesList = cachedAgenciesList.agencies.map((agency) => {
          if (agency._id === editedAgency.id) {
            return {
              ...agency,
              ...editedAgency,
            }
          }

          return agency
        })

        queryClient.setQueryData(agenciesQueries.list().queryKey, {
          ...cachedAgenciesList,
          agencies: updatedAgenciesList,
        })
      }
    },
    onError: (error, _, previousAgencies) => {
      console.log(error)

      if (previousAgencies) {
        queryClient.setQueryData(agenciesQueries.list().queryKey, previousAgencies)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: agenciesQueries.all() }),
  })
}
