import { useCallback } from 'react'

import type { BgFileUploadData } from '../types'

import { api } from '@/services'

export function useS3Url() {
  const fetchS3Url = useCallback(async (file: File) => {
    const fileExtension = file.name.split('.').pop()

    const { data } = await api.get<Omit<BgFileUploadData, 'file'>>(
      `/s3/upload/presigned_url?file_type=${fileExtension}`,
    )

    return { ...data, file }
  }, [])

  return fetchS3Url
}
