import styled from 'styled-components'

export const TooltipHelpIcon = styled.div`
  padding: 1px;
  font-size: 14px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 99999999px;

  color: ${({ theme }) => theme.colors.brand};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.brand};
`
