import { Button } from 'antd'
import { rem } from 'polished'
import styled, { css } from 'styled-components'

type ItemCardProps = {
  $isInSelection?: boolean
  $isSelected?: boolean
}

export const Container = styled.div<ItemCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: calc(100% / 7);
  height: ${rem(194)};
  gap: 6px;
  border-radius: 8px;
  border-right: 1px solid #d9d9d9;

  &:nth-child(8n) {
    border-right: none;
  }

  ${({ $isInSelection }) =>
    $isInSelection &&
    css`
      cursor: pointer;
      &:hover {
        background-color: rgba(128, 195, 67, 0.1);
        transition: all 0.3s;
      }
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      position: relative;
      border: 1px solid rgba(128, 195, 67, 1);
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        opacity: 0.9;
        height: 100%;
        width: 100%;
        background-color: rgba(243, 249, 237, 0.9);
        border-radius: 8px;
      }
    `}
`

export const SelectedContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
`

export const SelectedText = styled.span`
  color: rgba(128, 195, 67, 1);
  font-size: ${rem(14)};
  font-weight: 600;
  margin-top: 0;
`

export const DeleteButton = styled(Button)`
  position: absolute;
  right: -4px;
  bottom: 156px;
  color: ${({ theme }) => theme.colors.link};

  :hover {
    color: ${({ theme }) => theme.colors.linkHover};
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    margin: 4px;
    object-fit: cover !important;
    max-height: 64px;
  }
`

export const ProductType = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: #8e8e8e;
  font-size: ${rem(12)};
`

export const ProductCategory = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: #434343;
  font-weight: 700;
  font-size: ${rem(14)};
`

export const PartNumber = styled.div`
  font-size: ${rem(12)};
  margin-top: -4px;
  text-align: center;
  color: #8e8e8e;
`

export const BGModel = styled.strong`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: ${rem(12)};
  font-weight: 700;
  line-height: 12px;
  padding-right: 10px;
  padding-left: 10px;
  color: #434343;
`
