import { DeleteOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { rem } from 'polished'
import styled, { css } from 'styled-components'

type isColumnProps = {
  $isColumn?: boolean
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  width: 47%;
  height: ${rem(74)};
  padding: 12px;
  margin-top: 16px;
`

export const AgencyName = styled(Typography.Text)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div<isColumnProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $isColumn }) =>
    $isColumn &&
    css`
      flex-direction: column;
      align-items: normal;
    `}
`

export const ResponsibleName = styled.span`
  font-size: 14px;
  color: #666666;
  font-weight: 600;
`

export const ResponsibleEmail = styled.span`
  font-size: 14px;
  color: #303030;
  font-style: italic;
`

export const DeleteAgencyButton = styled(DeleteOutlined)`
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.brand};
`
