import React, { useEffect, useState } from 'react'
import { BsQuestionLg } from 'react-icons/bs'

import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Form, Image, Radio, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { BaseTable } from '@/components'
import { SkuFile } from '@/features/BG'

interface SkuFileProps {
  data: SkuFile[]
  isLoading?: boolean
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

export const ImagePackTable = ({ data, isLoading = false }: SkuFileProps) => {
  const form = Form.useFormInstance()

  const [tableData, setTableData] = useState(data)
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState(false)

  useEffect(() => {
    const selectedRadio = tableData.find((item) => item.main === true)

    setCurrentlySelectedRow(selectedRadio?.order as any)
    form.setFieldValue('showcase', tableData)
  }, [tableData, form])

  const columns: ColumnsType<any> = [
    {
      title: 'Vitrine',
      dataIndex: 'image',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Radio
            onClick={() => handleRowSelection(record)}
            checked={currentlySelectedRow === record.order}
          />
        )
      },
    },
    {
      title: 'Label',
      dataIndex: 'label',
      render: (_, record: SkuFile) => {
        return (
          <S.ShowcaseContainer>
            <Image preview={false} src={record?.filename_url as string} />
            {record.name}
          </S.ShowcaseContainer>
        )
      },
    },
    {
      title: () => {
        return (
          <S.TooltipContainer>
            <S.InputName>Ordenar</S.InputName>

            <Tooltip title="Clique nos pontos e arraste para reordenar as imagens.">
              <S.TooltipIcon >
                <BsQuestionLg />
              </S.TooltipIcon>
            </Tooltip>
          </S.TooltipContainer>
        )
      },
      dataIndex: '',
      key: 'sort',
      width: 100,
      align: 'center',
    },
  ]

  const handleRowSelection = (selectedRow: any) => {
    // Helper Function to update the 'main' attribute in each table data item
    const updateMainPropertyForAllItems = (shouldBeMain: boolean) => {
      tableData.forEach((item) => {
        item.main = item.order === selectedRow.order ? shouldBeMain : false
      })
    }

    if (currentlySelectedRow === selectedRow.order) {
      updateMainPropertyForAllItems(false)
      setCurrentlySelectedRow(false)
    } else {
      updateMainPropertyForAllItems(true)
      setCurrentlySelectedRow(selectedRow.order)
    }

    // Always update 'showcase' field value regardless of the condition
    form.setFieldValue('showcase', tableData)
  }

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    }

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === 'sort') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <S.DraggableIcon
                  ref={setActivatorNodeRef}
                  style={{ touchAction: 'none', cursor: 'move' }}
                  {...listeners}
                />
              ),
            })
          }
          return child
        })}
      </tr>
    )
  }

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active?.id !== over?.id) {
      const tableLine = (previous: SkuFile[]) => {
        const activeIndex = previous.findIndex((i) => i.order === active.id)
        const overIndex = previous.findIndex((i) => i.order === over?.id)
        return arrayMove(previous, activeIndex, overIndex)
      }

      const newTableData = tableLine(tableData)
      const sortedTableData = newTableData
        .map((item, i) => ({
          ...item,
          order: i.toString(),
        }))
        .sort((a, b) => Number(a.order) - Number(b.order))

      form.setFieldValue('showcase', sortedTableData)
      setTableData(sortedTableData)
    }
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
      <SortableContext items={tableData.map((i) => i.order)} strategy={verticalListSortingStrategy}>
        <BaseTable
          components={{
            body: {
              row: Row,
            },
          }}
          dataSource={tableData}
          columns={columns}
          pagination={false}
          loading={isLoading}
          rowKey="order"
        />
      </SortableContext>
    </DndContext>
  )
}
