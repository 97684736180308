import { useEffect, useState } from 'react'

import { Checkbox } from 'antd'

import * as S from './styles'

import { useDebounce } from '@/common/hooks'

interface CheckboxFilterProps {
  onChange: (e: string[]) => void
  originalData: string[]
  selectedOptions: any[]
}

export const CheckboxFilter = ({
  onChange,
  originalData,
  selectedOptions,
}: CheckboxFilterProps) => {
  const [data] = useState(originalData)
  const [searchValue, setSearchValue] = useState('')
  const [options, setOptions] = useState(data)
  const [checkedItems, setCheckedItems] = useState<any[]>(() =>
    selectedOptions ? selectedOptions : [],
  )

  const debouncedSearch = useDebounce(searchValue, 300)

  useEffect(() => {
    let filteredOptions

    if (searchValue === '') {
      filteredOptions = data
    } else {
      filteredOptions = data.filter(
        (item) =>
          item.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          item.toLowerCase() === debouncedSearch.toLowerCase(),
      )
    }

    setOptions(filteredOptions)
  }, [data, debouncedSearch, searchValue])

  function handleChange(checkedValues: string[]) {
    // Create a new set from all currently checked items
    const updatedCheckedItems = new Set(checkedItems)

    // For visible checkbox items (filtered by the current search term), update their checked state
    options.forEach((value) => {
      if (checkedValues.includes(value)) {
        // If the checkbox value is checked and it's not included in the set, add it
        updatedCheckedItems.add(value)
      } else if (updatedCheckedItems.has(value)) {
        // If the checkbox value is unchecked and it's included in the set, remove it
        updatedCheckedItems.delete(value)
      }
    })

    const uniqueCheckedItems = Array.from(updatedCheckedItems)

    setCheckedItems(uniqueCheckedItems)
    onChange(uniqueCheckedItems)
  }

  return (
    <S.Container>
      <S.SearchBox onChange={(e) => setSearchValue(e?.target?.value)} />
      <S.CheckboxContainer>
        <Checkbox.Group
          value={checkedItems}
          options={options}
          defaultValue={selectedOptions !== undefined ? selectedOptions : []}
          onChange={(e) => handleChange(e as string[])}
        />
      </S.CheckboxContainer>
    </S.Container>
  )
}
