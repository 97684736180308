import { Layout } from 'antd'
import styled from 'styled-components'

export const Container = styled(Layout)`
  min-height: 100vh;
  height: 100%;
`

export const SidebarContainer = styled(Layout)`
  height: 100%;
  flex-direction: row;

  .ant-layout-sider {
    background-color: #fff;
  }

  .ant-layout-content {
    width: 0;
  }
`

export const ContentContainer = styled(Layout)`
  padding: 16px 24px 24px;

  .ant-layout-content {
    width: 100%;
  }
`

export const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`
