import { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { BaseModalProps } from '@/common'
import { BaseModal, CustomPagination } from '@/components'
import {
  agenciesQueries,
  SelectAgenciesTable,
  useSendBgToAgenciesMutation,
} from '@/features/agency'
import { Agency } from '@/features/agency'

interface SelectAgenciesModalProps extends BaseModalProps {
  toggleCreateAgency: () => void
  productId: string
}

export const SelectAgenciesModal = ({
  isOpen,
  onClose,
  toggleCreateAgency,
  productId,
}: SelectAgenciesModalProps) => {
  const [selectedAgencies, setSelectedAgencies] = useState<Agency[]>([])
  const [pagination, setPagination] = useState(1)
  const [agencyName, setAgencyName] = useState('')

  const {
    data: agenciesData,
    isLoading: isAgenciesLoading,
    isFetching: isAgenciesFetching,
  } = useQuery(
    agenciesQueries.list({
      page: pagination,
      per_page: 5,
      name: agencyName,
    }),
  )

  const sendBgMutation = useSendBgToAgenciesMutation()

  const handleSelectAgencies = (agencies: string[]) => {
    const selectedAgencies =
      agenciesData?.agencies.filter((agency) => agencies.includes(agency._id)) ?? []

    setSelectedAgencies((prevSelected) => {
      const previousAgencies = prevSelected.filter(
        (agency) => !agenciesData?.agencies.some((a) => a._id === agency._id),
      )

      return [...previousAgencies, ...selectedAgencies]
    })
  }

  function handleSearch(search: string) {
    setAgencyName(search)
  }

  function handlePagination(page: number) {
    setPagination((prev) => {
      if (prev === page) return prev
      return page
    })
  }

  async function handleSubmit() {
    if (selectedAgencies.length === 0) {
      console.error('Nenhuma agência selecionada')
      toast.error('Selecione pelo menos uma agência')
      return
    }

    try {
      const agencyIds = selectedAgencies.map((item) => item._id)

      const promise = sendBgMutation.mutateAsync({ productId, agencies: agencyIds })

      const hasMultipleAgencies = agencyIds.length > 1

      toast.promise(promise, {
        loading: `Enviando para ${hasMultipleAgencies ? 'agências' : 'agência'}`,
        success: (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Envio para agência <span style={{ fontWeight: 'bold' }}>confirmado</span>!
            </div>
          </S.ToastContainer>
        ),
        className: 'toast-sucess',
        error: `Não foi possível enviar para ${hasMultipleAgencies ? 'agências' : 'agência'}`,
      })

      await promise

      onClose()
    } catch (err) {
      console.error('Error sending to agencies', err)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Seleção de Agência(s)'}
      width={716}
      footer={
        <>
          <S.CancelButton type="link" onClick={onClose} disabled={sendBgMutation.isPending}>
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit}
            disabled={selectedAgencies.length <= 0}
            loading={sendBgMutation.isPending}
          >
            Enviar BG
          </Button>
        </>
      }
    >
      <S.Container>
        <S.Row>
          <S.Column>
            <S.Disclaimer>
              Selecione a(s) agência(s) para enviar o Buyer Guide cadastrado
            </S.Disclaimer>

            <S.SearchInput
              allowClear
              placeholder="Nome da Agência"
              onSearch={handleSearch}
              onClear={() => handleSearch('')}
              loading={isAgenciesFetching}
              onChange={(e) => {
                if (e.target.value === '') {
                  handleSearch('')
                }
              }}
            />
          </S.Column>

          <S.AddAgencyButton type="default" icon={<PlusOutlined />} onClick={toggleCreateAgency}>
            Adicionar Agência
          </S.AddAgencyButton>
        </S.Row>

        <SelectAgenciesTable
          data={agenciesData?.agencies}
          onSelectAgencies={handleSelectAgencies}
          isLoading={isAgenciesLoading || sendBgMutation.isPending}
        />

        <S.PaginationContainer>
          <CustomPagination
            scrollToTop
            deselecting
            page={pagination}
            pageSize={5}
            totalItems={agenciesData?.total || 0}
            totalPages={agenciesData?.total_pages || 1}
            isLoading={isAgenciesLoading}
            changePageValue={(page) => handlePagination(page)}
          />
        </S.PaginationContainer>
      </S.Container>
    </BaseModal>
  )
}
