import { Input } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .anticon {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-btn-icon-only {
    border-color: ${({ theme }) => theme.colors.brand};
  }
`

export const CheckboxContainer = styled.div`
  .ant-form-item {
    width: 100%;
    margin: 0;
  }

  .ant-checkbox-group {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: center;
    column-gap: 32px;
    row-gap: 16px;

    margin: 0;
  }
`

export const SearchBox = styled(Input.Search)``
