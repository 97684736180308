import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload, type UploadFile, type UploadProps } from 'antd'

import { validateFilesBeforeUpload } from '@/features/BG'

interface SupportFilesUploaderProps extends UploadProps {
  handleUploadFile?: (file: File) => Promise<void>
}

export const SupportFilesUploader = ({ handleUploadFile, ...props }: SupportFilesUploaderProps) => {
  const { disabled } = props

  const uploaderProps: UploadProps = {
    multiple: true,
    action: '',
    showUploadList: true,
    onPreview: undefined,
    accept:
      '.docx, .doc, .pdf, .ppt, .pptx, .pps, .ppsx, .pot, .potx, .key, .odp, .otp, .sxi, .sti, .png, .jpg, .jpeg, .svg, .webp, .zip, .rar, .xls, .xlsx, .csv, .psd, .tif, .tiff',
    beforeUpload: (file: File, fileList: UploadFile[]) => {
      const validationResult = validateFilesBeforeUpload(file, fileList)

      if (validationResult) {
        handleUploadFile?.(file)
        return false
      }

      return Upload.LIST_IGNORE
    },
    ...props,
  }

  return (
    <Upload {...uploaderProps}>
      <Button icon={<UploadOutlined />} disabled={disabled} />
    </Upload>
  )
}
