import { CheckCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const Container = styled.div`
  padding-bottom: 38px;
`

export const ScrollableResponsibles = styled.div`
  overflow-y: auto;
  padding-right: 14px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 204px;
  flex-wrap: wrap;
`

export const ClearAllButton = styled(Button)`
  padding-top: 10px;
  padding-left: 2px;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
