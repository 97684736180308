import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { Specification, SpecificationFieldType } from '../types'

import type { SpecificationFilters } from '@/app'
import { generateSearchParams, ListResponse } from '@/common'
import { api } from '@/services'

export const specificationQueries = {
  all: () => ['specification'],
  lists: () => [...specificationQueries.all(), 'list'],
  list: (filters: SpecificationFilters = {}) =>
    queryOptions({
      queryKey: [...specificationQueries.lists(), filters],
      queryFn: () => fetchSpecificationsList(filters),
      placeholderData: keepPreviousData,
    }),
  fieldTypes: () =>
    queryOptions({
      queryKey: [specificationQueries.all, 'fieldTypes'],
      queryFn: fetchSpecificationsFieldTypes,
      placeholderData: keepPreviousData,
    }),
}

export const fetchSpecificationsList = async (filters: SpecificationFilters) => {
  const { page, per_page, ...activeFilters } = filters

  const params = generateSearchParams(activeFilters)

  const { data } = await api.get<ListResponse<Specification, 'specifications'>>(
    `/specifications?page=${page}&per_page=${per_page}&${params}`,
  )

  return data
}

export const fetchSpecificationsFieldTypes = async () => {
  const { data } = await api.get<SpecificationFieldType[]>('/specifications/field_types')

  return data
}
