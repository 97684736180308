import type { ProductStatus } from '../types'

export function hasHeroImage(productStatus?: ProductStatus) {
  if (productStatus) return false

  const hasHeroImage =
    productStatus !== 'EM RASCUNHO DO PRODUTO' &&
    productStatus !== 'PENDENTE DE ENVIO PARA AGÊNCIA' &&
    productStatus !== 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA'

  return hasHeroImage
}
