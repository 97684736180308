import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-form-item {
    margin: 0;
  }

  .ant-form-item-label {
    padding: 0 0 2px 0;
  }
`

export const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #6c6c6c;
`
