import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { AnymarketCategory, Category, GlobalCategory } from '../types'

import type { CategoryFilters } from '@/app'
import { generateSearchParams, ListResponse } from '@/common'
import { api } from '@/services'

export const categoryQueries = {
  all: () => ['categories'],
  lists: () => [...categoryQueries.all(), 'list'],
  list: (filters: CategoryFilters = {}) =>
    queryOptions({
      queryKey: [...categoryQueries.lists(), filters],
      queryFn: () => fetchCategoriesList(filters),
      placeholderData: keepPreviousData,
    }),
  details: () => [...categoryQueries.all(), 'detail'],
  detail: (id: string) =>
    queryOptions({
      queryKey: [...categoryQueries.details(), id],
      queryFn: () => fetchCategoryDetails(id),
      placeholderData: keepPreviousData,
    }),
  anymarketCategories: () =>
    queryOptions({
      queryKey: [...categoryQueries.all(), 'anymarketCategories'],
      queryFn: () => fetchAnymarketCategories(),
    }),
  globalCategories: (name: string) =>
    queryOptions({
      queryKey: [...categoryQueries.all(), 'globalCategories', name],
      queryFn: () => fetchGlobalCategories(name),
      placeholderData: keepPreviousData,
    }),
}

async function fetchCategoriesList(filters: CategoryFilters) {
  const { page, per_page, ...activeFilters } = filters

  const params = generateSearchParams(activeFilters)

  const { data } = await api.get<ListResponse<Category, 'categories'>>(
    `/categories?per_page=${per_page}&page=${page}&${params}`,
  )

  return data
}

async function fetchCategoryDetails(id: string) {
  const { data } = await api.get<Category>(`/categories/${id}`)

  return data
}

async function fetchAnymarketCategories() {
  const { data } = await api.get<AnymarketCategory[]>('/categories/anymarket')

  return data
}

async function fetchGlobalCategories(name = '') {
  // Base URL with mandatory query parameters
  let url = '/google_global_categories?per_page=20&page=1'

  // Append the 'name' query parameter only if it is not empty
  if (name) {
    url += `&name=${encodeURIComponent(name)}`
  }

  // only add name to the query if it's not empty
  const { data } = await api.get<ListResponse<GlobalCategory, 'google_global_categories'>>(url)

  return data
}
