import type {
  UserFilters,
  UserLogsFilter,
  BGProductFilters,
  CategoryFilters,
  AgenciesFilters,
} from '@/app'

type GenericFilters = Record<string, string | string[] | undefined>

type Filters =
  | UserFilters
  | GenericFilters
  | UserLogsFilter
  | BGProductFilters
  | CategoryFilters
  | AgenciesFilters

const generateSearchParams = (filters: Filters) => {
  const urlParams = new URLSearchParams(
    Object.entries(filters)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => [key, v])
        }
        if (typeof value !== 'undefined') {
          return [[key, value]]
        }
        return []
      })
      .flat(),
  )

  // console.log({ urlParams })

  return urlParams.toString()
  // return encodeURIComponent(urlParams.toString())
}

export { generateSearchParams }
