import { Navigate, Outlet } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { shallow } from 'zustand/shallow'

import { authStore as useAuthStore } from '@/app'
import { clearAllCaches } from '@/common'
import { PageErrorBoundary } from '@/components'
import { PageLayout } from '@/layouts'

const PrivateRoute = () => {
  const { idToken, refreshToken, userAuthenticated } = useAuthStore(
    (s) => ({
      idToken: s.idToken,
      refreshToken: s.refreshToken,
      userAuthenticated: s.isUserAuthenticated,
    }),
    shallow,
  )

  const isAuthenticated = idToken && refreshToken && userAuthenticated

  if (!isAuthenticated) {
    clearAllCaches()
    return <Navigate to="/auth/login" replace />
  }

  return (
    <Sentry.ErrorBoundary
      fallback={
        <PageLayout>
          <PageErrorBoundary />
        </PageLayout>
      }
    >
      <Outlet />
    </Sentry.ErrorBoundary>
  )
}

export { PrivateRoute }
