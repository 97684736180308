import { Tag } from 'antd'
import styled from 'styled-components'

export const ActiveFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  .ant-tag .ant-tag-close-icon {
    color: #5ea320;

    padding-top: 20px;
  }
`

export const TagClearAll = styled(Tag)`
  border: 1px solid #5ea320;
  color: #5ea320;
`
