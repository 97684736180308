import { infiniteQueryOptions, keepPreviousData } from '@tanstack/react-query'

import type { Notification } from '../types'

import type { ListResponse } from '@/common'
import { api } from '@/services'

const DEFAULT_PAGE = 1

const fetchNotificationsList = async ({ pageParam = DEFAULT_PAGE }) => {
  const page = pageParam || DEFAULT_PAGE

  const { data } = await api.get<ListResponse<Notification, 'notifications'>>(
    `/notifications?per_page=20&page=${page}`,
  )

  return data
}

export const notificationQueries = {
  all: () => ['notification'],
  lists: () => [...notificationQueries.all(), 'list'],
  list: () =>
    infiniteQueryOptions({
      queryKey: [...notificationQueries.lists()],
      queryFn: fetchNotificationsList,
      initialPageParam: DEFAULT_PAGE,
      getNextPageParam: (lastPage) => {
        const isLastPage = lastPage.page === lastPage.total_pages

        if (!isLastPage) {
          return lastPage.page + 1
        }

        return null
      },
      placeholderData: keepPreviousData,
    }),
}
