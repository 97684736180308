import { RxExclamationTriangle } from 'react-icons/rx'

import { CameraOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import { rem } from 'polished'
import styled, { css } from 'styled-components'

interface PriceProps {
  $isUnpublished: boolean
  $hasMarketplacePrice: boolean
}

interface SketchPublishedTagProps {
  $hasBackground: string
}

export const TagsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CircleTag = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

export const BgFamily = styled.span`
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`

export const BgName = styled.span`
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: normal;

  color: ${({ theme }) => theme.colors.darkGreen};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

export const ButtonContent = styled.div`
  .ant-btn {
    padding: 0;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BgCategoryDate = styled.span`
  font-weight: 700;
  font-size: ${rem(14)};
  color: #00000080;
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`

export const Price = styled.div<PriceProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  font-weight: 400;
  font-size: ${rem(14)};
  color: ${({ theme, $isUnpublished }) => ($isUnpublished ? '#99999980' : theme.colors.black)};

  img {
    border: 1px solid rgba(153, 153, 153, 0.6);
    border-radius: 50%;
    padding: 6px;
  }

  ${({ $hasMarketplacePrice }) =>
    !$hasMarketplacePrice &&
    css`
      color: ${({ theme }) => theme.colors.lightGray};

      img {
        opacity: 0.2;
      }
    `}
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SketchPublishedTag = styled(Tag)<SketchPublishedTagProps>`
  display: flex;
  justify-content: center;

  height: ${rem(21)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.matteBlue};
  border: 1px solid ${({ theme }) => theme.colors.matteBlue};
  border-radius: 6px;

  background-color: ${({ $hasBackground }) =>
    $hasBackground === 'RASCUNHO' ? '#fff' : 'rgba(110, 165, 248, 0.1)'};

  font-size: 12px;
`

export const NpiTag = styled(Tag)`
  display: flex;
  justify-content: center;

  height: ${rem(21)};
  width: fit-content;

  color: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 6px;

  background-color: #fef5e5;

  font-size: 12px;

  margin-top: auto;
`

export const PendentPublishTag = styled(Tag)`
  display: flex;
  justify-content: center;

  height: ${rem(21)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.navyBlue};
  border: 1px solid ${({ theme }) => theme.colors.navyBlue};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};

  font-size: 12px;
`

export const UnpublishedTag = styled(Tag)`
  display: flex;
  justify-content: center;

  height: ${rem(21)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};

  font-size: 12px;
`
export const DeactivatedButton = styled(Button)`
  color: ${({ theme }) => theme.colors.lightGray};
  pointer-events: none;
  cursor: default;
`

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 6px;
`

export const ExclamationTriangle = styled(RxExclamationTriangle)`
  font-size: 18px;
  color: #faad14;
  margin-left: 8px;
`

export const AlertButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 4px 6px;

  height: ${rem(21)};
  width: ${rem(116)};

  font-size: 12px;
`

export const FallbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f6f6f6;

  height: ${rem(62)};
  width: ${rem(62)};
`

export const FallbackIcon = styled(CameraOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.darkGreen};
`
