import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
} from '@ant-design/icons'
import { Image, Modal, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { fileTypeRemap, imgFallback, truncateString } from '@/common'
import { BaseTable } from '@/components'
import type {
  ProductDownloadType,
  SkuInspectFile,
  SupportFile,
  SupportFilesResponse,
} from '@/features/BG'

interface BgFileManagementTableProps {
  data: SupportFilesResponse | undefined
  isLoading?: boolean
  handleRemoveFile: (file: SupportFile) => void
  handleDownload: (downloadType: ProductDownloadType) => void
}

type CombinedFileData =
  | (SupportFile & { file_type: 'support_file'; key: string })
  | (SkuInspectFile & { filename: string; file_type: 'sku_file'; key: string })
  | { filename: string; filename_url: string; file_type: 'docx'; key: string }

export const BgFileManagementTable = ({
  data,
  isLoading,
  handleRemoveFile,
  handleDownload,
}: BgFileManagementTableProps) => {
  function organizeTableData(data: SupportFilesResponse | undefined): CombinedFileData[] {
    const combinedData: CombinedFileData[] = [
      ...(data?.support_files || []).map((file) => ({
        ...file,
        file_type: 'support_file' as const,
        key: `support-${file.s3_filename}`,
      })),
      ...(data?.sku_files || []).map((file) => ({
        ...file,
        filename: file.name,
        file_type: 'sku_file' as const,
        key: `sku-${file.s3_filename}`,
      })),
    ]

    if (data?.docx) {
      combinedData.push({
        filename: 'Docx',
        filename_url: data?.docx,
        file_type: 'docx' as const,
        key: 'docx',
      })
    }

    return combinedData
  }

  function confirmDeletion(file: SupportFile) {
    Modal.confirm({
      title: `Deseja realmente excluir este arquivo?`,
      content: (
        <span>
          O arquivo <strong>{truncateString(file.filename, 20)}</strong> será excluido.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
      onOk: () => handleRemoveFile(file),
    })
  }

  const columns: ColumnsType<CombinedFileData> = [
    {
      title: '',
      dataIndex: 'filename_url',
      key: 'filename_url',
      width: 10,
      render: (_, record) => {
        // DOCX
        if (record.file_type === 'docx')
          return (
            <S.IconContainer>
              <FileWordOutlined />
            </S.IconContainer>
          )

        // SUPPORT_FILES
        if (record.file_type === 'support_file') {
          const fileExtension = record.filename.split('.')[1]

          if (fileExtension === 'pdf') {
            return (
              <S.IconContainer>
                <FilePdfOutlined />
              </S.IconContainer>
            )
          }

          if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
            return (
              <S.ImageContainer>
                <Image preview={false} src={record.filename_url} fallback={imgFallback} />
              </S.ImageContainer>
            )
          }

          return (
            <S.IconContainer>
              <FileOutlined />
            </S.IconContainer>
          )
        }

        // SKU_FILES
        return (
          <S.ImageContainer>
            <Image preview={false} src={record.filename_url} fallback={imgFallback} />
          </S.ImageContainer>
        )
      },
    },
    {
      title: 'Nome',
      dataIndex: 'filename',
      key: 'filename',
      render: (_, record) => {
        if (record.file_type === 'docx') {
          return 'Arquivo Docx'
        }

        return <Tooltip title={record.filename}>{truncateString(record.filename, 50)}</Tooltip>
      },
    },
    {
      title: 'Tipo de arquivo',
      dataIndex: 'file_type',
      key: 'file_type',
      align: 'center',
      render: (_, record) => fileTypeRemap.get(record.file_type) || record.file_type,
    },
    {
      title: 'Ações',
      align: 'center',
      render: (_, record) => {
        return (
          <S.ActionTableRow>
            {record.file_type === 'support_file' ? (
              <Tooltip title="Excluir arquivo">
                <S.DeleteButton
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => confirmDeletion(record)}
                  disabled={isLoading}
                />
              </Tooltip>
            ) : null}
            <Tooltip title="Baixar arquivo">
              {record.file_type !== 'docx' ? (
                <a download href={record.download_url}>
                  <S.DownloadButton type="link" icon={<DownloadOutlined />} />
                </a>
              ) : (
                <S.DownloadButton
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload('docx')}
                />
              )}
            </Tooltip>
          </S.ActionTableRow>
        )
      },
    },
  ]

  return (
    <S.TableContainer>
      <BaseTable
        dataSource={organizeTableData(data)}
        columns={columns}
        pagination={false}
        loading={isLoading}
      />
    </S.TableContainer>
  )
}
