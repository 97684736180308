import { Skeleton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: fit-content;
  gap: 16px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  padding: ${rem(14)};
`

export const Summary = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  /* color: rgba(0, 0, 0, 0.6); */
  font-size: ${rem(14)};

  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const DisplayName = styled.h3`
  width: 100%;
  font-weight: 700;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.brand};
`

export const SkeletonTab = styled(Skeleton.Input)`
  width: 250px !important;

  padding-top: 28px;
`
