import { FileDoneOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { filtersRemap } from '@/common'
import { BaseTable, TableAction, TableColumnButton } from '@/components'
import type { ProductType } from '@/features/product-types'

type ProductTypeListTableProps = {
  data: ProductType[] | undefined
  isLoading: boolean
  handleEditProductType: (productType: ProductType) => void
}

export const ProductTypeListTable = ({
  data,
  isLoading,
  handleEditProductType,
}: ProductTypeListTableProps) => {
  const columns: ColumnsType<ProductType> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 620,
      render: (name, record) => (
        <TableColumnButton onClick={() => handleEditProductType(record)}>
          {filtersRemap.get(name) ? filtersRemap.get(name) : name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Categoria Global',
      dataIndex: 'global_category',
      key: 'global_category',
      width: 620,
      render: (_, record) => <p>{record.global_category.name}</p>,
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      width: 55,
      render: (_, productType) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  icon={<FileDoneOutlined />}
                  type="link"
                  onClick={() => handleEditProductType(productType)}
                >
                  Dados cadastrais
                </Button>
              </S.ButtonContent>
            ),
          },
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      loading={isLoading}
    />
  )
}
