import { Typography } from 'antd'
import styled from 'styled-components'

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`

export const DescriptionTitle = styled(Typography.Text)`
  color: rgba(48, 48, 48, 0.85);
  font-weight: 700;
`

export const DescriptionText = styled(Typography.Text)`
  color: rgba(48, 48, 48, 0.85);
`

export const ExampleText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.blue};
  margin-top: 8px;

  span {
    font-weight: 700;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 4px;

    li {
      margin: 0;
    }
  }
`
