import { Table, TableProps } from 'antd'

import * as S from './styles'

export const BaseTable = (props: TableProps<any>) => {
  return (
    <S.Container>
      <Table
        {...props}
        rowClassName={(_, index) => (index % 2 === 0 ? 'light-row' : 'dark-row')}
        footer={() => null}
      />
    </S.Container>
  )
}
