import { useState } from 'react'
import { FiFastForward } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { ArrowLeftOutlined, FileProtectOutlined } from '@ant-design/icons'
import { Form, Steps } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError } from '@/common'
import { BgFileUploadData, UploadStatus, useUploadFiles } from '@/features/BG'
import {
  createKitErrorMessages,
  CreateKitFormValues,
  InformationsItems,
  KitImageUpload,
  KitMountingSummary,
  useCreateProductKitMutation,
} from '@/features/kits'
import { PageLayout } from '@/layouts'

export const CreateKitPage = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { uploadFiles, isUploadingFiles } = useUploadFiles()

  const [currentStep, setCurrentStep] = useState(0)
  const [kitUploadStatus, setKitUploadStatus] = useState<UploadStatus>()
  const [kitImagesData, setKitImagesData] = useState<BgFileUploadData[]>([])
  const [formValues, setFormValues] = useState<CreateKitFormValues>({} as CreateKitFormValues)

  const createProductKitMutation = useCreateProductKitMutation()

  const steps = [
    {
      title: 'Informações e Itens',
      content: <InformationsItems formValues={formValues} />,
      disabled: currentStep === 0 || isUploadingFiles || createProductKitMutation.isPending,
    },
    {
      title: 'Upload de Imagens',
      content: (
        <KitImageUpload
          kitImagesData={kitImagesData}
          setKitImagesData={setKitImagesData}
          kitUploadStatus={kitUploadStatus}
          setKitUploadStatus={setKitUploadStatus}
        />
      ),
      disabled: currentStep <= 1 || isUploadingFiles || createProductKitMutation.isPending,
    },
    {
      title: 'Resumo',
      content: <KitMountingSummary formValues={formValues} kitImagesData={kitImagesData} />,
      disabled: currentStep <= 2 || isUploadingFiles || createProductKitMutation.isPending,
    },
  ]

  async function handleNextStep() {
    try {
      const formValues = await form.validateFields()

      setFormValues((old) => ({ ...old, ...formValues }))

      if (currentStep !== 2) {
        setCurrentStep((currentStep) => currentStep + 1)
      }
    } catch (err) {
      console.error('Error updating step', err)
    }
  }

  function handleReturnStep() {
    if (currentStep !== 0) {
      return setCurrentStep((old) => old - 1)
    }

    return navigate('/')
  }

  async function handleCreateKit() {
    const toastId = toast.loading('Salvando kit...')

    try {
      const validImages = kitImagesData.filter((data) => data.file && data.file.size > 0)

      const imagesToUpload = validImages.map((data) => ({ url: data.url, file: data.file as File }))

      if (imagesToUpload.length > 0) {
        await uploadFiles(imagesToUpload)
      }

      const skuFiles = validImages.map((data) => data.filename)
      const kitFiles = formValues.selectedProducts.map((item: any) => item.sku.id)

      const kitDataFormValues = {
        name: formValues.name,
        title: formValues.title,
        key_words: formValues.key_words.join(', '),
        link_id: formValues.link_id,
        description: formValues.description,
        meta_tag_description: formValues.meta_tag_description,
        ref_id: formValues.ref_id,
        packaged_height: formValues.packaged_height,
        packaged_length: formValues.packaged_length,
        packaged_width: formValues.packaged_width,
        packaged_weight_kg: formValues.packaged_weight_kg,
        sku_kits: kitFiles,
        sku_files: skuFiles,
        dealer_page_url: formValues.dealer_page_url,
        category_id: formValues.category_id.value,
      }

      await createProductKitMutation.mutateAsync(kitDataFormValues)

      toast.success('Kit salvo com sucesso!', { id: toastId })

      navigate('/')
    } catch (err) {
      toast.error(
        createKitErrorMessages.get((err as ApiError).response?.data?.detail as string) ||
          'Erro ao salvar kit',
        { id: toastId },
      )
      console.error('Error creating KIT:', err)
    }
  }

  const ButtonsContainer = () => {
    return (
      <S.PageButtons>
        <S.BackButton
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={handleReturnStep}
          loading={kitUploadStatus === 'uploading'}
        >
          Voltar
        </S.BackButton>

        <S.HeaderActions>
          {currentStep === 2 ? (
            <S.Button
              type="primary"
              icon={<FileProtectOutlined />}
              onClick={() => form.submit()}
              loading={isUploadingFiles || createProductKitMutation.isPending}
            >
              Salvar Kit
            </S.Button>
          ) : (
            <S.Button
              type="primary"
              icon={<FiFastForward />}
              onClick={handleNextStep}
              loading={kitUploadStatus === 'uploading'}
            >
              Continuar
            </S.Button>
          )}
        </S.HeaderActions>
      </S.PageButtons>
    )
  }

  return (
    <PageLayout title="Monte seu Kit">
      <S.Container>
        <ButtonsContainer />

        <S.PageTitle>Monte seu Kit</S.PageTitle>
        <Steps current={currentStep} items={steps} onChange={(step) => setCurrentStep(step)} />

        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateKit}
          disabled={isUploadingFiles || createProductKitMutation.isPending}
        >
          {steps[currentStep]?.content}
        </Form>
      </S.Container>
    </PageLayout>
  )
}
