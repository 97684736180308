import { CheckCircleOutlined } from '@ant-design/icons'
import { Card as AntdCard } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Card = styled(AntdCard)`
  padding: 0px;
`

export const CardContent = styled.div`
  overflow: auto;
  min-height: 300px;
  padding: 24px;
`

export const SecondStepFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 36px;
`

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 162px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 2px 24px 0 24px;

  .ant-btn-icon > .anticon {
    color: #80c343 !important;
  }

  .ant-upload.ant-upload-select {
    background: transparent !important;
    border-color: transparent !important;
    order: -1;
  }
`

export const UploadedImageContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  .ant-form-item {
    margin: 0;
  }
`

export const UploadStatusContainer = styled.div<{ $isDone: boolean }>`
  display: ${({ $isDone }) => ($isDone ? 'none' : 'flex')};
  flex-direction: column;
  gap: 4px;
`

export const ImageName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`

export const LoadingStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: ${rem(150)};
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const UploaderIntruction = styled.span`
  strong {
    color: rgba(48, 48, 48, 0.85);
  }
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
