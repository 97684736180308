import { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form, Space } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { SpecificationFilters, useSpecificationFilters } from '@/app'
import { getActiveFiltersList, removeActiveFilter, useToggle } from '@/common'
import { ActiveFiltersList, CustomPagination, FilterSidebar, LoadingSpinner } from '@/components'
import {
  CreateSpecificationsModal,
  specificationQueries,
  type Specification,
  ViewSpecificationModal,
  SpecificationsListTable,
  useChangeSpecificationVisibilityMutation,
  SpecificationsFilterForm,
} from '@/features/specifications'
import { PageLayout } from '@/layouts'

const SpecificationsPage = () => {
  const [form] = Form.useForm()

  const changeSpecificationVisibilityMutation = useChangeSpecificationVisibilityMutation()

  const [selectedSpecification, setSelectedSpecification] = useState<Specification | undefined>(
    undefined,
  )

  const [isCreateSpecificationsModalOpen, toggleCreateSpecificationModal] = useToggle(false)
  const [isSpecificationsModalOpen, toggleSpecificationModal] = useToggle(false)

  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)

  const { specificationsFilters, setFilters, resetSpecificationsFilters } = useSpecificationFilters(
    (state) => ({
      specificationsFilters: state.filters,
      setFilters: state.setFilters,
      resetSpecificationsFilters: state.resetFilters,
    }),
    shallow,
  )

  const {
    data: specifications,
    isLoading: isSpecificationsLoading,
    isFetching: isSpecificationsFetching,
  } = useQuery(specificationQueries.list(specificationsFilters))

  function handleFilter() {
    const values = form.getFieldsValue()

    setFilters({ ...values, page: 1 })
  }

  function submitFilter() {
    form.submit()
    toggleFilterDrawer()
  }

  function handleClearFilters() {
    form.resetFields()

    form.setFieldsValue({
      product_type_vtex_name: undefined,
    })

    resetSpecificationsFilters()
    toggleFilterDrawer()
  }

  function handlePagination(page: number, pageSize?: number) {
    setFilters({ page, per_page: pageSize })
  }

  function handleRemoveFilter(filterKey: keyof SpecificationFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter<SpecificationFilters>({
      activeFilters: specificationsFilters,
      filterKey,
      filterValue,
    })

    setFilters({ ...updatedFilters })
  }

  function handleEditSpecifications(specification: Specification | undefined) {
    setSelectedSpecification(specification)
    toggleSpecificationModal()
  }

  function handleChangeStatus(specification: Specification, status: boolean) {
    const promise = changeSpecificationVisibilityMutation.mutateAsync({
      specification_id: specification.id,
      status,
    })

    toast.promise(promise, {
      loading: 'Alterando status...',
      success: (
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            Status <span style={{ fontWeight: 'bold' }}>alterado</span> com{' '}
            <span style={{ fontWeight: 'bold' }}>sucesso</span>!
          </div>
        </S.ToastContainer>
      ),
      className: 'toast-sucess',
      error: 'Falha ao alterar status. Tente novamente mais tarde.',
    })
  }

  const PageHeader = () => {
    return (
      <S.Header>
        <S.TitleContainer>
          <S.Title level={4}>Especificações</S.Title>

          <S.Divider thickness={2} type="vertical" />

          {specifications && (
            <S.TotalSpecificationsContent>
              <S.TotalSpecificationsValue>{specifications.total}</S.TotalSpecificationsValue>
              {specifications.total > 1 ? 'encontrados' : 'encontrado'}
            </S.TotalSpecificationsContent>
          )}

          {(isSpecificationsLoading || isSpecificationsFetching) && <LoadingSpinner />}
        </S.TitleContainer>

        <Space>
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateSpecificationModal}>
            Criar Especificação
          </Button>
        </Space>
      </S.Header>
    )
  }

  return (
    <PageLayout
      title="Especificações"
      sidebar={
        <FilterSidebar
          isFilterDrawerOpen={isFilterDrawerOpen}
          onClose={toggleFilterDrawer}
          filterFooter={
            <S.FooterSidebar>
              <S.CleanButton onClick={handleClearFilters}>Limpar</S.CleanButton>

              <Button type="primary" htmlType="submit" onClick={submitFilter}>
                Filtrar
              </Button>
            </S.FooterSidebar>
          }
        >
          <SpecificationsFilterForm onSubmit={handleFilter} form={form} />
        </FilterSidebar>
      }
    >
      {isCreateSpecificationsModalOpen && (
        <CreateSpecificationsModal
          isOpen={isCreateSpecificationsModalOpen}
          onClose={toggleCreateSpecificationModal}
        />
      )}

      {isSpecificationsModalOpen && (
        <ViewSpecificationModal
          isOpen={isSpecificationsModalOpen}
          onClose={() => handleEditSpecifications(undefined)}
          data={selectedSpecification as Specification}
        />
      )}

      <S.Container>
        <PageHeader />

        <ActiveFiltersList
          filtersList={getActiveFiltersList(specificationsFilters)}
          removeFilter={handleRemoveFilter}
          cleanFiltersList={handleClearFilters}
        />

        <SpecificationsListTable
          data={specifications?.specifications || []}
          isLoading={isSpecificationsLoading}
          handleEditSpecifications={handleEditSpecifications}
          handleChangeSpecificationStatus={handleChangeStatus}
        />

        <S.PaginationContainer>
          <CustomPagination
            scrollToTop
            page={specificationsFilters.page || 1}
            pageSize={specificationsFilters.per_page || 20}
            totalItems={specifications?.total || 0}
            totalPages={specifications?.total_pages || 1}
            isLoading={isSpecificationsLoading}
            changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
          />
        </S.PaginationContainer>
      </S.Container>
    </PageLayout>
  )
}

export { SpecificationsPage }
