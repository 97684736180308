import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Disclaimer = styled.span`
  font-size: ${rem(14)};
  color: rgba(0, 0, 0, 0.6);
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const AddAgencyButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
  background-color: white;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
`

export const SearchInput = styled(Input.Search)`
  width: ${rem(200)};
  .anticon-search {
    color: ${({ theme }) => theme.colors.linkActive};
  }
`

export const PaginationContainer = styled.div`
  margin-top: auto;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
