import { useCallback, useRef, useState } from 'react'

import axios from 'axios'
import { toast } from 'sonner'

interface FileUploadData {
  url: string
  file: File
}

interface UploadResults {
  success: number
  errors: number
  failedMessages: string[]
}

export const useUploadFiles = () => {
  const isUploadingRef = useRef(false)
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)

  const uploadFiles = useCallback(async (filesData: FileUploadData[]) => {
    if (isUploadingRef.current) {
      console.log('Already uploading. Skipping')
      return
    }

    if (filesData.length === 0) {
      toast.error('Nenhum arquivo encontrado.')
      throw new Error('Nenhum arquivo encontrado.')
    }

    const newUploadResults: UploadResults = {
      success: 0,
      errors: 0,
      failedMessages: [],
    }

    try {
      isUploadingRef.current = true
      setIsUploadingFiles(true)

      console.log('INICIANDO UPLOADS')

      const promises = filesData.map((data) => {
        return axios
          .put(data.url, data.file, {
            headers: {
              'Content-Type': data.file.type,
            },
          })
          .then(() => {
            console.log('SUCESSO UPLOAD', data.file)
            newUploadResults.success++
          })
          .catch((error) => {
            newUploadResults.errors++
            newUploadResults.failedMessages.push(
              `Falha no arquivo ${data.file.name}: ${error.message}`,
            )
            console.error(`ERROR during file upload ${data.file.name}:`, error)
          })
      })

      console.log('FINALIZANDO UPLOADS')

      await Promise.all(promises)

      if (newUploadResults.errors > 0) {
        toast.error(`Alguns arquivos não foram enviados corretamente.`)
        newUploadResults.failedMessages.forEach((msg) => console.log(msg))
        throw new Error('Erro ao enviar alguns arquivos.')
      }

      return
    } finally {
      isUploadingRef.current = false
      setIsUploadingFiles(false)
    }
  }, [])

  return { uploadFiles, isUploadingFiles }
}
