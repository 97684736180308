import { useMutation, useQueryClient } from '@tanstack/react-query'

import { categoryQueries } from '../queries'

import { useCategoryFilters } from '@/app'
import { api } from '@/services'

interface EditCategoryMutation {
  id: string
  name: string
  keywords: string
  title: string
  description: string
  google_global_category_id: number
  anymarket_id: number
}

export const editCategoryErrorMessages = new Map([
  ['Category name already exists.', 'O nome desta categoria já existe.'],
  [
    'Value error, Keywords must be separated by comma',
    'Palavras Similares precisam ser separadas por vírgula.',
  ],
])

export const useEditCategoryMutation = () => {
  const queryClient = useQueryClient()
  const { filters } = useCategoryFilters()

  return useMutation({
    mutationFn: async (data: EditCategoryMutation) => {
      return api.put(`/categories/${data.id}`, data)
    },
    onMutate: async (editedCategory) => {
      await queryClient.cancelQueries({ queryKey: categoryQueries.all() })

      const cachedCategoriesList = queryClient.getQueryData(categoryQueries.list(filters).queryKey)

      if (cachedCategoriesList) {
        const updatedCategoriesList = cachedCategoriesList.categories.map((category) => {
          if (category.id === editedCategory.id) {
            return {
              ...category,
              ...editedCategory,
            }
          }

          return category
        })

        queryClient.setQueryData(categoryQueries.list(filters).queryKey, {
          ...cachedCategoriesList,
          categories: updatedCategoriesList,
        })
      }

      return cachedCategoriesList
    },
    onError: (error, _, cachedCategories) => {
      console.log(error)

      if (cachedCategories) {
        queryClient.setQueryData(categoryQueries.list(filters).queryKey, cachedCategories)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: categoryQueries.all() }),
  })
}
