import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productTypeQueries } from '../queries'

import { api } from '@/services'

interface EditProductTypeMutation {
  id: string
  name: string
  global_category_id: number
}

export const editProductTypeErrorMessages = new Map([['Error value', 'Error!']])

export const useEditProductTypeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditProductTypeMutation) => {
      return api.patch(`/product_types/${data.id}`, data)
    },
    onMutate: async (editedProductType) => {
      await queryClient.cancelQueries({ queryKey: productTypeQueries.all() })

      const cachedProductTypesList = queryClient.getQueryData(productTypeQueries.list().queryKey)

      if (cachedProductTypesList) {
        const updatedProductTypesList = cachedProductTypesList.product_types.map((productType) => {
          if (productType.id === editedProductType.id) {
            return {
              ...productType,
              ...editedProductType,
            }
          }

          return productType
        })

        queryClient.setQueryData(productTypeQueries.list().queryKey, {
          ...cachedProductTypesList,
          product_types: updatedProductTypesList,
        })
      }

      return cachedProductTypesList
    },
    onError: (error, _, previousProductTypes) => {
      console.log(error)

      if (previousProductTypes) {
        queryClient.setQueryData(productTypeQueries.list().queryKey, previousProductTypes)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: productTypeQueries.all() }),
  })
}
