import { WarningOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 12px;

  .ant-typography {
    line-height: initial;
  }

  .ant-form-vertical .ant-form-item-label > label {
    font-size: 14px;

    color: #6c6c6c;
  }
`

export const ExclamationTriangle = styled(WarningOutlined)`
  font-size: 55px;
  color: #faad14;
`

export const Text = styled(Typography.Text)`
  font-size: 16px;
  text-align: center;

  color: #6c6c6c;
`
