import { persist, devtools, createJSONStorage } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface GroupFilters {
  name?: string
}

interface GroupFilterStore {
  filters: GroupFilters
  resetFilters: () => void
  setFilters: (filters: GroupFilters) => void
}

const groupFilterStore = createWithEqualityFn<GroupFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          name: undefined,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-filters',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                name: undefined,
              },
            },
            false,
            'reset-filters',
          )
        },
      }),
      { name: 'group-filter-store', storage: createJSONStorage(() => sessionStorage) },
    ),
    { name: 'group-filter-store' },
  ),
  shallow,
)

export const resetGroupFilters = groupFilterStore.getState().resetFilters

export { groupFilterStore, groupFilterStore as useGroupFilters }
