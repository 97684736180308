import { useCallback, useEffect, useState } from 'react'

import { FileOutlined } from '@ant-design/icons'
import { Badge, Divider, Form, Image, Switch } from 'antd'
import DOMPurify from 'dompurify'
import { Masonry } from 'masonic'

import * as S from './styles'

import { getUploadedImageUrl, imgFallback } from '@/common'
import {
  BgStatusTags,
  ProductStatus,
  type BgFileUploadData,
  type CreateProductFormValues,
} from '@/features/BG'

interface ProductSpecs {
  formValues: CreateProductFormValues
  status: ProductStatus | undefined
  imagePackData: BgFileUploadData[]
  supportFilesData: BgFileUploadData[]
}

type ProductSpecifications = Array<[string, string[]]>

export const DataValidation = ({
  formValues,
  status,
  imagePackData,
  supportFilesData,
}: ProductSpecs) => {
  const [productDetails, setProductDetails] = useState<Record<string, any>[]>([])
  const [productImages] = useState<Record<string, string>[]>([])

  const specSheet: Record<string, string>[] = Object.entries(formValues).map(([key, value]) => {
    return {
      [key]: value,
    }
  })

  const selectedProductType = formValues?.product_type?.label

  function replaceWithDash(obj: Record<string, string>): Record<string, string> {
    const result: Record<string, string> = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key] = obj[key] === '' ? '-' : obj[key]
      }
    }
    return result
  }

  const getSpecInfo = useCallback(() => {
    const filteredItems = specSheet.filter((item: Record<string, string>) => {
      if (item !== undefined) {
        const key = Object.keys(item)
        if (
          key[0] !== 'product_type' &&
          key[0] !== 'category' &&
          key[0] !== 'family' &&
          key[0] !== 'model' &&
          key[0] !== 'template' &&
          key[0] !== 'bgFile'
        ) {
          return item
        }
      }
    })

    const result = filteredItems.map((item) => replaceWithDash(item))

    setProductDetails(result)
    return filteredItems
  }, [specSheet])

  useEffect(() => {
    if (productDetails.length === 0) {
      getSpecInfo()
    }
    // if (productImages.length === 0) {
    //   getSkuImages()
    // }
  }, [formValues, productDetails.length, productImages.length, getSpecInfo])

  return (
    <div>
      <S.BGInfoContainer>
        <S.imageContainer>
          <Image
            height={274}
            width={274}
            preview={false}
            fallback={imgFallback}
            alt={`Hero image de ${imagePackData.filter((item) => item.url[0])}`}
            // src={imagesSrc?.[0]}
            src={getUploadedImageUrl(imagePackData[0])}
            style={{ objectFit: 'contain', borderRadius: 6 }}
          />
        </S.imageContainer>

        <S.InfoContainer>
          {formValues?.category && (
            <S.SummaryContainer>
              <S.StatusTemplateContainer>
                <BgStatusTags status={status} isBgView />

                <S.TemplateContent>
                  <S.TemplateText>
                    Salvar como template para <strong>futuros cadastros</strong>
                  </S.TemplateText>

                  <Form.Item name={'save_as_template'} style={{ margin: 0 }} initialValue={false}>
                    <Switch checkedChildren="Salvar" unCheckedChildren="Não" defaultValue={false} />
                  </Form.Item>
                </S.TemplateContent>
              </S.StatusTemplateContainer>

              <S.ProductCategory>
                <span>{selectedProductType || '--'}</span>

                <Divider type="vertical" style={{ margin: 0 }} />

                <strong>{formValues?.category?.label || '--'}</strong>

                <Divider type="vertical" style={{ margin: 0 }} />

                <span>{formValues.ref_id || '--'}</span>

                <Divider type="vertical" style={{ margin: 0 }} />

                <Badge
                  count={formValues?.has_upgrade ? 'Possui upgrade' : 'Não possui upgrade'}
                  color={formValues?.has_upgrade ? 'green' : 'red'}
                />
              </S.ProductCategory>

              <S.DisplayName>{formValues?.model}</S.DisplayName>

              <S.Summary>
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formValues.overview) }}
                />
              </S.Summary>
            </S.SummaryContainer>
          )}
        </S.InfoContainer>
      </S.BGInfoContainer>

      <S.SpecsContent>
        <S.Title>Detalhes do Produto</S.Title>

        <S.ProductSpecsContainer>
          <S.ExternalCharacCard>
            <strong>Características Externas:</strong>

            <S.WrapingText
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  !formValues.external_characteristics ||
                    formValues.external_characteristics === '<p></p>'
                    ? '-'
                    : formValues.external_characteristics,
                ),
              }}
            />
          </S.ExternalCharacCard>

          <Masonry
            items={Object.entries(formValues.specifications as ProductSpecifications)}
            render={(data) => {
              const key = data.data[0]
              const values = data.data[1]

              return (
                <S.Card key={key}>
                  <span className="strong-title">{key}:</span>

                  <div
                    style={{ overflowY: 'auto', maxHeight: '200px' }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(String(values?.length > 0 ? values : '-')),
                    }}
                  />
                </S.Card>
              )
            }}
            rowGutter={20}
            columnGutter={20}
            maxColumnCount={2}
            overscanBy={10}
          />

          {imagePackData.length > 1 && <S.PackImageTitle>Pack de Imagens</S.PackImageTitle>}

          <S.Title>Arquivos do Produto</S.Title>

          <S.Subtitle>Pack de Imagens:</S.Subtitle>

          <S.ImageViewer>
            {imagePackData?.map((imageFile, index: number) => {
              return (
                <div key={index}>
                  <Image
                    height={164}
                    width={164}
                    key={index}
                    src={getUploadedImageUrl(imageFile)}
                    alt={`Imagem de ${imageFile.file?.name || imageFile.filename}`}
                    preview={false}
                    style={{
                      objectFit: 'contain',
                      borderRadius: 6,
                      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  />
                </div>
              )
            })}
          </S.ImageViewer>

          <S.Subtitle>Arquivos adicionais:</S.Subtitle>

          <S.SupportFilesContainer>
            {supportFilesData.map((file, index) => {
              return (
                <div key={`${index}-${file.filename}`}>
                  <FileOutlined />
                  <span>{(file?.file?.name as string) || file.filename}</span>
                </div>
              )
            })}
          </S.SupportFilesContainer>
        </S.ProductSpecsContainer>
      </S.SpecsContent>
    </div>
  )
}
