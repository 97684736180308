import type { FilterAndIconsResponse, FilterAndIcons } from '../types'

export const separateFiltersAndIcons = (filtersAndIcons?: FilterAndIconsResponse) => {
  const filters: FilterAndIcons[] = []
  const icons: FilterAndIcons[] = []

  filtersAndIcons?.values?.forEach((item) => {
    if (item.field_group_name === 'Filtros') {
      filters.push(item)
    } else if (item.field_group_name === 'Icones') {
      icons.push(item)
    }
  })

  return { filters, icons }
}
