import { ExclamationCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;

  .ant-typography {
    text-align: center;
    font-size: 20px;
  }
`

export const ExclamationIcon = styled(ExclamationCircleOutlined)`
  font-size: 48px;
  color: #ff4d4d;
`
