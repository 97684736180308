import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import { Agency } from '../types'

import { AgenciesFilters } from '@/app'
import { generateSearchParams, ListResponse } from '@/common'
import { api } from '@/services'

export const agenciesQueries = {
  all: () => ['agencies'],
  lists: () => [...agenciesQueries.all(), 'list'],
  list: (filters: AgenciesFilters = {}) =>
    queryOptions({
      queryKey: [...agenciesQueries.lists(), filters],
      queryFn: () => fetchAgenciesList(filters),
      placeholderData: keepPreviousData,
    }),
  details: () => [...agenciesQueries.all(), 'detail'],
  detail: (agencyId: string) =>
    queryOptions({
      queryKey: [...agenciesQueries.details(), agencyId],
      queryFn: () => fetchAgencyDetail(agencyId),
      placeholderData: keepPreviousData,
    }),
}

export const fetchAgenciesList = async (filters: AgenciesFilters) => {
  const { page, per_page, ...activeFilters } = filters

  const url = generateSearchParams(activeFilters)

  const { data } = await api.get<ListResponse<Agency, 'agencies'>>(
    `/agencies?page=${page}&per_page=${per_page}&${url}`,
  )

  return data
}

const fetchAgencyDetail = async (agencyId: string) => {
  const { data } = await api.get<Agency>(`/products/${agencyId}`)

  return data
}
