import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../../queries'

import { api } from '@/services'

export interface UpdateBgDataMutation {
  productId: string
  name: string
  title: string
  key_words: string
  description: string
  meta_tag_description: string
  text_link: string
  part_number: string
  brand_id: number
  sku_id: string
  sku_name: string
  category_id: string
  filters: Array<{
    name: string
    field_id: number
    values: string[]
  }>
  icons: Array<{
    name: string
    field_id: number
    values: string[]
  }>
  integrate_with_anymarket: boolean
}

export const useUpdateBgDataMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: UpdateBgDataMutation) => {
      return api.patch(`/products/${data.productId}`, data)
    },
    onSettled: (_, __, { productId }) => queryClient.invalidateQueries(bgQueries.detail(productId)),
  })
}
