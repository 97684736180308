import { Card as AntdCard, Empty, Input, InputNumber } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Card = styled(AntdCard)`
  padding: 0px;
`

export const CardContent = styled.div`
  overflow: auto;
  min-height: 300px;
  padding: 24px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .ant-form-item {
    margin: 0;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;

  .ant-form-item {
    flex: 1;
  }

  &.name-category .ant-form-item:nth-child(3) {
    flex: 2.2;
  }
`

export const ListedItensContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  height: ${rem(276)};
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  gap: 16px;
`

export const ItensContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: ${rem(212)};
  row-gap: 34px;
  align-content: flex-start;
  padding-top: 16px;
  padding-top: 12px;
  padding-right: 16px;
`

export const SimilarTextField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  .ant-form-item {
    width: 100%;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 12px;
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`

export const SearchInput = styled(Input.Search)`
  width: ${rem(400)};
  height: 30px;
  transition: all 0.6s ease-in-out;
  .ant-input-search-button {
    display: none;
  }
  .ant-input-suffix {
    color: ${({ theme }) => theme.colors.link};
  }
  .ant-input-affix-wrapper {
    border-radius: 6px !important;
  }
`

export const TextAreaContainer = styled(Input.TextArea)`
  && {
    height: ${rem(114)} !important;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    overflow-y: auto;
    word-wrap: break-word;
    white-space: normal;
  }
  &&:hover {
    border-color: ${({ theme }) => theme.colors.link};
  }
  &&:focus {
    border-color: ${({ theme }) => theme.colors.link};
    box-shadow: none;
  }
`

export const EmptyContent = styled(Empty)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  .ant-empty-image {
    svg {
      width: 84px;
      height: auto;
    }
    path {
      stroke: ${({ theme }) => theme.colors.link};
    }
  }
  .ant-empty-description {
    font-size: 16px;
    color: #00000060;
  }
`

export const MaxLength = styled.span`
  font-size: 10px;
  color: #999999;
`

export const NumberInput = styled(InputNumber)`
  width: 100%;
`
