import jwtDecode from "jwt-decode"

import { authStore as useAuthStore} from "@/app"

export const useUserId = () => {
  const idToken = useAuthStore((state) => state.idToken)

  const userId = jwtDecode<{ 'cognito:username': string }>(idToken as string)?.['cognito:username']

  return userId || ''
}
