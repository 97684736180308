import { useLayoutEffect } from 'react'

export const useDocumentTitle = (pageTitle: string | undefined) => {
  const defaultTitle = 'Acer - Cadastro de Produto'

  const title = pageTitle ? `${pageTitle} | Acer - Cadastro de Produto` : defaultTitle

  useLayoutEffect(() => {
    document.title = `${title}`
  }, [title])
}
