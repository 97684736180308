import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../../queries'
import type { UploadSupportFile } from '../../types'

import { api } from '@/services'

export type UpdateSupportFilesMutation = {
  productId: string
  files_excludeds: Array<UploadSupportFile>
  files_includeds: Array<UploadSupportFile>
}

export const useUpdateSupportFilesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, ...data }: UpdateSupportFilesMutation) => {
      return api.patch(`/products/support_files/${productId}`, data)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
