import { FilterOutlined } from '@ant-design/icons'
import { Divider } from 'antd'

import * as S from './styles'

type FilterSidebarProps = {
  children: React.ReactNode
  filterFooter: React.ReactNode
  title?: string | React.ReactNode
  isFiltersLoading?: boolean
  isFilterDrawerOpen: boolean
  onClose: () => void
}

export const FilterSidebar = ({
  children,
  filterFooter,
  title = 'Filtros',
  isFiltersLoading,
  onClose,
  isFilterDrawerOpen,
}: FilterSidebarProps) => {
  return (
    <S.Container>
      <S.SidebarTrigger
        type="text"
        onClick={onClose}
        disabled={isFiltersLoading}
        data-testid="filter-trigger"
      >
        <FilterOutlined />
      </S.SidebarTrigger>

      <Divider style={{ marginTop: '8px' }} />

      <S.FilterDrawer
        title={title}
        open={isFilterDrawerOpen}
        placement="left"
        width={420}
        onClose={onClose}
        footer={filterFooter}
        forceRender={true}
      >
        {children}
      </S.FilterDrawer>
    </S.Container>
  )
}
