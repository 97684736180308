import { useReducer } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { AgenciesFilters, useAgenciesFilters } from '@/app'
import {
  getActiveFiltersList,
  ModuleKey,
  removeActiveFilter,
  RoleKey,
  useRolePermissions,
  useToggle,
} from '@/common'
import { ActiveFiltersList, CustomPagination, LoadingSpinner } from '@/components'
import { useDeleteAgencyMutation } from '@/features/agency'
import { ViewAgencyModal, CreateAgencyModal } from '@/features/agency/components'
import { AgenciesTable } from '@/features/agency/components/Table/AgenciesTable'
import { agenciesQueries } from '@/features/agency/queries'
import { Agency } from '@/features/agency/types'
import { PageLayout } from '@/layouts'

export interface SelectAgencyActions {
  agency: Agency
  type: 'AGENCY_DATA' | 'DELETE_AGENCY'
}

const AgenciesPage = () => {
  const [isEditAgencyModalOpen, toggleEditAgencyModal] = useToggle(false)
  const [isCreateAgencyModalOpen, toggleCreateAgencyModal] = useToggle(false)

  const agencyPermissions = useRolePermissions(ModuleKey.PRODUCT, RoleKey.PRODUCT)

  const { agenciesFilters, setFilters } = useAgenciesFilters(
    (state) => ({
      agenciesFilters: state.filters,
      setFilters: state.setFilters,
    }),
    shallow,
  )

  const {
    data: agenciesData,
    isLoading: isAgenciesLoading,
    isFetching: isAgenciesFetching,
  } = useQuery(agenciesQueries.list(agenciesFilters))

  const deleteAgencyMutation = useDeleteAgencyMutation()

  const [selectedAgency, selectAgencyDispatch] = useReducer(
    (state: Agency, action: SelectAgencyActions) => {
      switch (action.type) {
        case 'AGENCY_DATA':
          toggleEditAgencyModal()
          return action.agency
        case 'DELETE_AGENCY':
          handleDeleteAgency(action?.agency?._id as string)
          return state
        // case 'FINISH_REGISTRATION':
        //   return action.agency
        default:
          return {} as Agency
      }
    },
    {} as Agency,
  )

  function handleSearch(search: string) {
    setFilters({ name: search })
  }

  function handlePagination(page: number, pageSize?: number) {
    setFilters({ page, per_page: pageSize })
  }

  function handleRemoveFilter(filterKey: keyof AgenciesFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter<AgenciesFilters>({
      activeFilters: agenciesFilters,
      filterKey,
      filterValue,
    })
    setFilters({ ...updatedFilters })
  }

  async function handleDeleteAgency(agencyId: string) {
    if (deleteAgencyMutation.isPending) return

    const promise = deleteAgencyMutation.mutateAsync(agencyId)

    return toast.promise(promise, {
      loading: 'Excluindo...',
      success: (
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            Agência <span style={{ fontWeight: 'bold' }}>excluída</span> com{' '}
            <span style={{ fontWeight: 'bold' }}>sucesso</span>!
          </div>
        </S.ToastContainer>
      ),
      className: 'toast-sucess',
      error: 'Não foi possível excluir a agência',
    })
  }

  const PageHeader = () => {
    return (
      <S.Header>
        <S.TitleContainer>
          <S.Title level={4}>Agências</S.Title>

          <S.Divider thickness={2} type="vertical" />

          {agenciesData && (
            <S.TotalAgenciesContent>
              <S.TotalAgenciesValue>{agenciesData.total}</S.TotalAgenciesValue>
              {agenciesData.total > 1 ? 'encontrados' : 'encontrado'}
            </S.TotalAgenciesContent>
          )}

          {(isAgenciesLoading || isAgenciesFetching) && <LoadingSpinner />}
        </S.TitleContainer>
        <S.Row>
          <S.SearchInput
            allowClear
            placeholder="Nome da Agência"
            defaultValue={agenciesFilters.name}
            onSearch={handleSearch}
          />

          {agencyPermissions?.canCreate || agencyPermissions?.isAdmin ? (
            <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateAgencyModal}>
              Cadastrar Agência
            </Button>
          ) : null}
        </S.Row>
      </S.Header>
    )
  }

  return (
    <PageLayout title="Agências">
      {isCreateAgencyModalOpen && (
        <CreateAgencyModal isOpen={isCreateAgencyModalOpen} onClose={toggleCreateAgencyModal} />
      )}

      {isEditAgencyModalOpen && (
        <ViewAgencyModal
          isOpen={isEditAgencyModalOpen}
          onClose={() => selectAgencyDispatch({ type: 'AGENCY_DATA', agency: {} as Agency })}
          permissions={agencyPermissions}
          data={selectedAgency}
        />
      )}

      <S.Container>
        <PageHeader />

        <ActiveFiltersList
          filtersList={getActiveFiltersList(agenciesFilters)}
          removeFilter={handleRemoveFilter}
          cleanFiltersList={() => {}}
        />

        <AgenciesTable
          data={agenciesData?.agencies}
          isLoading={isAgenciesLoading}
          permissions={agencyPermissions}
          handleSelectAgency={selectAgencyDispatch}
        />

        <S.PaginationContainer>
          <CustomPagination
            scrollToTop
            page={agenciesFilters.page || 1}
            pageSize={agenciesFilters.per_page || 10}
            totalItems={agenciesData?.total || 0}
            totalPages={agenciesData?.total_pages || 1}
            isLoading={isAgenciesLoading}
            changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
          />
        </S.PaginationContainer>
      </S.Container>
    </PageLayout>
  )
}

export { AgenciesPage }
