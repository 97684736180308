import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productTypeQueries } from '../queries'

import { api } from '@/services'

interface CreateProductType {
  name: string
  global_category_id: number
}

export const createProductTypeErrorMessages = new Map([['Error Message.', 'Mensagem de Erro']])

export const useCreateProductTypeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: CreateProductType) => {
      return api.post('product_types', data)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: productTypeQueries.all() }),
  })
}
