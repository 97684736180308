import { useMutation } from '@tanstack/react-query'

import { api } from '@/services'

export type PatchProductSpecificationsMutation = {
  productId: string
  saveAsTemplate: boolean
  has_upgrade: boolean
  overview: string
  external_characteristics: string
  technical_specification: Record<string, string>
}

export const usePatchProductSpecificationsMutation = () => {
  return useMutation({
    mutationFn: async ({
      productId,
      saveAsTemplate,
      ...rest
    }: PatchProductSpecificationsMutation) => {
      return api.patch(
        `/products/${productId}/technical_specification?save_as_template=${saveAsTemplate}`,
        rest,
      )
    },
  })
}
