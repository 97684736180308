import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface CategoryFilters {
  per_page?: number
  page?: number
  product_type_name?: string
  product_type_vtex_id?: number
}

interface CategoryFilterStore {
  filters: CategoryFilters
  resetFilters: () => void
  setFilters: (filters: CategoryFilters) => void
}

const categoriesFilterStore = createWithEqualityFn<CategoryFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          per_page: 20,
          page: 1,
          product_type_name: undefined,
          product_type_vtex_id: undefined,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-category-filters',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                per_page: 20,
                page: 1,
                product_type_name: undefined,
                product_type_vtex_id: undefined,
              },
            },
            false,
            'reset-category-filters',
          )
        },
      }),
      { name: 'category-filter-store', storage: createJSONStorage(() => sessionStorage) },
    ),
    { name: 'category-filter-store' },
  ),
  shallow,
)

export const resetCategoriesFilters = categoriesFilterStore.getState().resetFilters

export { categoriesFilterStore, categoriesFilterStore as useCategoryFilters }
