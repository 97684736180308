import { Tabs as AntdTabs } from 'antd'
import styled from 'styled-components'

export const Tabs = styled(AntdTabs)`
  width: 100%;

  .ant-tabs-nav-list {
    cursor: pointer;
    pointer-events: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #242425;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.darkGreen};
  }
`
