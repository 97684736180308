import { Button } from 'antd'

import * as S from './styles'

import { BaseModal } from '@/components'

type ErrorRegisteringBGModalProps = {
  isOpen: boolean
  bgSku: string
  onClose: () => void
  onConfirm: () => void
}

export const ErrorRegisteringBGModal = ({
  isOpen,
  onClose,
  bgSku,
  onConfirm,
}: ErrorRegisteringBGModalProps) => {
  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Ops! Tivemos problemas com este cadastro"
      width={720}
      footer={
        <>
          <S.CancelButton onClick={onClose}>Cancelar</S.CancelButton>

          <Button type="primary" onClick={onConfirm}>
            Tentar novamente
          </Button>
        </>
      }
    >
      <S.Container>
        <S.ExclamationIcon />
        <S.ModalMessage>
          Tivemos um problema ao cadastrar o BG <strong>{bgSku}</strong>. <br />
          Por favor, tente novamente.
        </S.ModalMessage>
      </S.Container>
    </BaseModal>
  )
}
