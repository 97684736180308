import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface AgenciesFilters {
  name?: string
  is_active?: boolean
  page?: number
  per_page?: number
}

interface AgenciesFilterStore {
  filters: AgenciesFilters
  resetFilters: () => void
  setFilters: (filters: AgenciesFilters) => void
}

const agenciesFilterStore = createWithEqualityFn<AgenciesFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          name: undefined,
          is_active: undefined,
          page: 1,
          per_page: 20,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-agencies-filters',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                name: undefined,
                is_active: undefined,
                page: 1,
                per_page: 20,
              },
            },
            false,
            'reset-agencies-filters',
          )
        },
      }),
      { name: 'agencies-filter-store', storage: createJSONStorage(() => sessionStorage) },
    ),
    { name: 'agencies-filter-store' },
  ),
  shallow,
)

export const resetAgenciesFilters = agenciesFilterStore.getState().resetFilters

export { agenciesFilterStore, agenciesFilterStore as useAgenciesFilters }
