import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Product = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 20px;

  .ant-divider {
    margin: 0;
    border-color: ${({ theme }) => theme.customTokens.listBox.bgScrollBar};
    height: 18px;
  }
`

export const Instructions = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #30303099;
`
