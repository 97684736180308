import { BsQuestionLg } from 'react-icons/bs'

import { Tooltip, TooltipProps } from 'antd'

import * as S from './styles'

export const HelpTooltip = (props: TooltipProps) => {
  return (
    <Tooltip {...props}>
      <S.TooltipHelpIcon>
        <BsQuestionLg />
      </S.TooltipHelpIcon>
    </Tooltip>
  )
}
