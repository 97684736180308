interface RemoveActiveFilterProps<T> {
  activeFilters: T
  filterKey: keyof T
  filterValue?: string
}

export function removeActiveFilter<T extends Record<string, any>>({
  activeFilters,
  filterKey,
  filterValue,
}: RemoveActiveFilterProps<T>) {
  const updatedFilters = { ...activeFilters }

  const isArray = Array.isArray(updatedFilters[filterKey])

  if (isArray) {
    const currentFiltersList = updatedFilters[filterKey] as string[]

    if (currentFiltersList.length <= 0) {
      delete updatedFilters[filterKey]
      return updatedFilters
    }

    const updatedFiltersList = currentFiltersList.filter((filter) => filter !== filterValue)

    updatedFilters[filterKey] = updatedFiltersList as unknown as T[keyof T]

    return updatedFilters
  } else {
    updatedFilters[filterKey] = undefined as unknown as T[keyof T]
  }

  return updatedFilters
}
