import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../../queries'
import { ProductStatus } from '../../types'

import { api } from '@/services'

interface ChangeStatusMutation {
  product_id: string
  status: boolean
}

export const useChangeStatusMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ product_id, status }: ChangeStatusMutation) => {
      return api.patch(`products/${product_id}/published_or_unpublished`, {
        is_active: status,
      })
    },
    onMutate: async (editedStatus) => {
      await queryClient.cancelQueries({ queryKey: bgQueries.all() })

      const cachedBg = queryClient.getQueryData(bgQueries.detail(editedStatus.product_id).queryKey)

      if (cachedBg) {
        const newBgStatus = (editedStatus.status ? 'PUBLICADO' : 'DESPUBLICADO') as ProductStatus

        const updatedBg = {
          ...cachedBg,
          is_active: editedStatus.status,
          status: newBgStatus,
        }

        queryClient.setQueryData(bgQueries.detail(editedStatus.product_id).queryKey, updatedBg)
      }

      return cachedBg
    },
    onError: (error, { product_id }, cachedBg) => {
      console.log(error)

      if (cachedBg) {
        queryClient.setQueryData(bgQueries.detail(product_id).queryKey, cachedBg)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
