import { Tabs } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const StyledTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    align-items: flex-end;
    justify-content: space-between;
    margin: 0;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: 0 0 auto;
    margin: 0 1px 0 1px;
  }

  .ant-tabs-nav-list {
    /* padding-top: 16px; */
  }

  .ant-tabs-tab {
    font-size: ${rem(20)};
    line-height: ${rem(24)};
    font-weight: 400;
    font-family: ${({ theme }) => theme.base.fontFamily};
    color: ${({ theme }) => theme.base.colorPrimary};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.customTokens.tab.selectedColor};
  }

  .ant-tabs-ink-bar {
    height: 1px !important;
    background: ${({ theme }) => theme.customTokens.tab.selectedColor} !important;
  }

  .ant-tabs-nav::before {
    border-bottom: transparent !important;
  }

  .ant-tabs-extra-content {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    min-height: ${rem(40)};
  }
`
