import { useEffect } from 'react'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { FormInstance, Collapse, CollapseProps, Form, Select } from 'antd'

import * as S from './styles'

import { useCategoryFilters } from '@/app'
import { filtersRemap } from '@/common'
import { productTypeQueries } from '@/features/product-types'

export type CategoriesFilterFormData = {
  product_type_name?: string | undefined
}

type CategoriesFilterFormProps = {
  form: FormInstance
  onSubmit: (data: CategoriesFilterFormData) => void
}

export const CategoriesFilterForm = ({ form, onSubmit }: CategoriesFilterFormProps) => {
  const categoriesFilter = useCategoryFilters((state) => state.filters)

  useEffect(() => {
    form.setFieldsValue(categoriesFilter)
  }, [form, categoriesFilter])

  const { data: types, isLoading: isProductTypesLoading } = useQuery(
    productTypeQueries.list({
      page: 1,
      per_page: 100,
    }),
  )

  const productTypesSelectOptions = types?.product_types?.map((productType) => ({
    label: filtersRemap.get(productType.name) || productType.name,
    value: productType.name,
  }))

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Tipo de produto',
      children: (
        <Form.Item name="product_type_name">
          <Select
            size="large"
            allowClear
            placeholder="Selecione"
            options={productTypesSelectOptions}
          />
        </Form.Item>
      ),
    },
  ]

  return (
    <S.Form onFinish={onSubmit} layout="vertical" disabled={isProductTypesLoading} form={form}>
      <Collapse
        accordion
        defaultActiveKey={'1'}
        expandIconPosition="end"
        bordered={false}
        style={{ background: 'transparent' }}
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
        items={items}
      />
    </S.Form>
  )
}
