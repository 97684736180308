import { useMutation } from '@tanstack/react-query'

import { api } from '@/services'

export interface CreateProductMutation {
  model: string
  family: string
  category_id: string
  ref_id: string
}

export const createProductErrorMessages = new Map([
  ['Product model or ref_id already exists', 'Modelo ou Part Number já cadastrado'],
])

export const useCreateProductMutation = () => {
  return useMutation({
    mutationFn: async (data: CreateProductMutation) => {
      return api.post('/products', data)
    },
  })
}
