import { Button as AntdButton } from 'antd'
import styled from 'styled-components'

export const Button = styled(AntdButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  padding: 4px 15px;

  color: ${(props) =>
    props.type === 'default' ? props.theme.colors.darkGreen : props.theme.colors.white};
  border: ${(props) =>
    props.type === 'default'
      ? `1px solid ${props.theme.colors.darkGreen}`
      : props.theme.colors.brand};
`
