import { Pagination as AntdPagination } from 'antd'
import styled from 'styled-components'

export const CustomPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Pagination = styled(AntdPagination)`
  display: flex;
  width: 100%;
  margin: 16px 0;
  /* margin-left: 10px; */

  .ant-pagination {
    display: flex;
    align-items: center;
    flex-direction: space-between;
  }

  .ant-pagination-options {
    margin: 0;
  }

  .ant-pagination-total-text {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .ant-pagination-item-link:not([disabled]) {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-pagination-item a {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-pagination-item-active {
    background-color: transparent;

    a {
      color: ${({ theme }) => theme.colors.black} !important;
      font-weight: 900;
    }
  }
`

export const PaginationRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TotalPages = styled.strong`
  color: ${({ theme }) => theme.colors.matteBlue};
`
