import { Button as AntdButton, Input as AntdInput, Form as AntdForm } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(8)};
  .ant-select {
    height: ${rem(40)};
  }
`

export const TextAreaBox = styled.div`
  display: flex;
  flex-direction: column;
  .ant-form-item {
    margin-bottom: 4px;
  }
`
interface FormItemProps {
  width: string
}

export const FormItem = styled(AntdForm.Item)<FormItemProps>`
  width: ${(props) => props.width};
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`

export const Disclaimer = styled.span`
  font-size: ${rem(12)};
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`
export const CharactersLeft = styled.span`
  font-size: ${rem(10)};
  line-height: 22px;
  font-weight: 400;
  color: ${(props) => props.theme.customTokens.input.label};
`

export const Input = styled(AntdInput)`
  text-align: left;
  border: 1px solid ${(theme) => theme.theme.colors.colorBorderPrimary};
  margin-top: 0;
  margin-bottom: 0;
  border-radius: ${rem(6)};
  height: ${rem(40)};
`
export const TextArea = styled(AntdInput.TextArea)`
  border: 1px solid ${(theme) => theme.theme.colors.colorBorderPrimary};
  margin-top: 0;
  margin-bottom: 0;
  border-radius: ${rem(6)};
`

export const Button = styled(AntdButton)`
  width: ${rem(200)};
  border-radius: ${rem(6)};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  background-color: ${(theme) => theme.theme.colors.brand};
  color: ${(theme) => theme.theme.colors.white};
  border: 0;
`
