import { Fragment, ReactNode } from 'react'

import * as S from './styles'

import { filterStatusRemap, filtersRemap, formatDate } from '@/common'

import { FilterTag } from '..'

import { ProductStatus } from '@/features/BG'

interface ActiveFiltersListProps<T> {
  filtersList: [string, any][]
  removeFilter: (filterKey: keyof T, filterValue?: string) => void
  cleanFiltersList: () => void
}

interface FormatFilterProps {
  filterValue: string | boolean
  filterKey?: string
}

export function ActiveFiltersList<T extends Record<string, any>>({
  filtersList,
  removeFilter,
  cleanFiltersList,
}: ActiveFiltersListProps<T>) {
  const excludedFilterKeys = ['page', 'logs_per_page', 'is_ordered_by_date_descending', 'per_page']

  function formatFilters({ filterValue, filterKey }: FormatFilterProps): string {
    if (typeof filterValue === 'boolean') {
      return filterValue
        ? `${filterKey ? filtersRemap.get(filterKey) : ''}: Sim`
        : `${filterKey ? filtersRemap.get(filterKey) : ''}: Não`
    }

    if (filterKey) {
      const formatter = filtersRemap.get(filterKey)

      if (formatter) {
        if (typeof formatter === 'function') {
          return formatter(filterValue)
        }

        return formatter as string
      }
    }

    const formattedFilter =
      filtersRemap.get(filterValue as string) || filterStatusRemap.get(filterValue as ProductStatus)

    if (formattedFilter) {
      return formattedFilter as string
    }

    return filterValue as string
    // return filterValue.toString()
  }

  return (
    <S.ActiveFiltersContainer>
      {(() => {
        let filterTagsRendered = 0

        const totalValidTags = filtersList.reduce((count, [filterKey, filterValue]) => {
          if (excludedFilterKeys.includes(filterKey) || filterValue === undefined || !filterValue) {
            return count
          }

          return count + (Array.isArray(filterValue) ? filterValue.length : 1)
        }, 0)

        return filtersList.map(([filterKey, filterValue], i) => {
          if (excludedFilterKeys.includes(filterKey) || filterValue === undefined) {
            return null
          }

          const isArray = Array.isArray(filterValue)
          const isDate = filterKey === 'initial_date' || filterKey === 'final_date'

          let tags: ReactNode[] = []

          if (isDate) {
            tags.push(
              <FilterTag
                closable
                handleRemoveTag={() => removeFilter(filterKey)}
                key={`${filterKey}-${i}`}
              >
                {`${filterKey === 'initial_date' ? 'Data inicial' : 'Data final'}: ${formatDate({
                  date: filterValue,
                  dateFormat: 'DD/MM/YYYY',
                })}`}
              </FilterTag>,
            )
          } else if (typeof filterValue === 'boolean') {
            tags.push(
              <FilterTag
                closable
                handleRemoveTag={() => removeFilter(filterKey)}
                key={`${filterKey}-${i}`}
              >
                {formatFilters({ filterKey, filterValue })}
              </FilterTag>,
            )
          } else if (isArray) {
            tags = [
              ...tags,
              ...filterValue.map((filter, i) => (
                <FilterTag
                  closable
                  handleRemoveTag={() => removeFilter(filterKey, filter)}
                  key={`${filterKey}-${i}`}
                >
                  {formatFilters({ filterValue: filter })}
                </FilterTag>
              )),
            ]
          } else {
            tags.push(
              <FilterTag
                closable
                handleRemoveTag={() => removeFilter(filterKey)}
                key={`${filterKey}-${i}`}
              >
                {formatFilters({ filterValue: filterValue })}
              </FilterTag>,
            )
          }

          filterTagsRendered += tags.length

          if (filterTagsRendered === totalValidTags && totalValidTags > 1) {
            tags.push(
              <S.TagClearAll
                key={filterValue}
                onClick={cleanFiltersList}
                style={{ cursor: 'pointer' }}
              >
                Limpar filtros
              </S.TagClearAll>,
            )
          }

          return <Fragment key={i}>{tags}</Fragment>
        })
      })()}
    </S.ActiveFiltersContainer>
  )

  // return (
  //   <S.ActiveFiltersContainer>
  //     {filtersList.map(([filterKey, filterValue], i) => {
  //       const isArray = Array.isArray(filterValue)
  //       const isDate = filterKey === 'initial_date' || filterKey === 'final_date'

  //       const activeFiltersTags = filtersList.map((value) =>
  //         value[1] !== undefined && value[1].length !== 0 ? value[1] : null,
  //       )

  //       const activeFilteredArray = activeFiltersTags.filter((item) => item !== null)
  //       const activeFiltersLength = activeFilteredArray.length

  //       const internalArrays = activeFiltersTags.filter((value) => Array.isArray(value))
  //       const internalArraysLength = internalArrays.reduce(
  //         (total, array) => total + array.length,
  //         0,
  //       )

  //       const validArraySize = filtersList.filter(
  //         ([filterKey, filterValue]) => excludedFilterKeys.includes(filterKey) || !!filterValue,
  //       ).length

  //       if (excludedFilterKeys.includes(filterKey) || filterValue === undefined || !filterValue) {
  //         return null
  //       }

  //       console.log({
  //         ultimo: i === filtersList.length - 1,
  //         activeFiltersLength,
  //         internalArraysLength,
  //         filtersList: filtersList.length,
  //         i,
  //       })

  //       return (
  //         <React.Fragment key={i}>
  //           {isDate ? (
  //             <FilterTag
  //               closable
  //               handleRemoveTag={() => removeFilter(filterKey)}
  //               key={`${filterKey}-${i}`}
  //             >
  //               {`${filterKey === 'initial_date' ? 'Data inicial' : 'Data final'}: ${formatDate({
  //                 date: filterValue,
  //                 dateFormat: 'DD/MM/YYYY',
  //               })}`}
  //             </FilterTag>
  //           ) : typeof filterValue === 'boolean' ? (
  //             <FilterTag
  //               closable
  //               handleRemoveTag={() => removeFilter(filterKey)}
  //               key={`${filterKey}-${i}`}
  //             >
  //               {formatFilters({ filterKey, filterValue })}
  //             </FilterTag>
  //           ) : isArray ? (
  //             <>
  //               {filterValue.map((filter, i) => (
  //                 <FilterTag
  //                   closable
  //                   handleRemoveTag={() => removeFilter(filterKey, filter)}
  //                   key={`${filterKey}-${i}`}
  //                 >
  //                   {formatFilters({ filterValue: filter })}
  //                 </FilterTag>
  //               ))}
  //             </>
  //           ) : (
  //             <FilterTag
  //               closable
  //               handleRemoveTag={() => removeFilter(filterKey)}
  //               key={`${filterKey}-${i}`}
  //             >
  //               {formatFilters({ filterValue: filterValue })}
  //             </FilterTag>
  //           )}

  //           {i === validArraySize && (activeFiltersLength > 1 || internalArraysLength > 1) && (
  //             <S.TagClearAll
  //               closable
  //               closeIcon={<FiTrash />}
  //               key={filterValue}
  //               onClick={cleanFiltersList}
  //               style={{ cursor: 'pointer' }}
  //             >
  //               Limpar filtros
  //             </S.TagClearAll>
  //           )}
  //         </React.Fragment>
  //       )
  //     })}
  //   </S.ActiveFiltersContainer>
  // )
}
