import { HolderOutlined } from '@ant-design/icons'
import { rem } from 'polished'
import styled from 'styled-components'

export const ShowcaseContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  .ant-image-img {
    width: 62px;
    height: 52px;
    object-fit: cover;
  }
`
export const DraggableIcon = styled(HolderOutlined)`
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.colors.link};
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TooltipIcon = styled.div`
  padding: 1px;
  font-size: 14px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999999px;

  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.white};
`

export const InputName = styled.span``
