import { MoreOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const DropdownIcon = styled(MoreOutlined)`
  color: ${({ theme }) => theme.colors.darkGreen};
  font-size: 30px;
  height: 0;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease;

  :hover {
    filter: brightness(0.8);
  }
`
