import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '@/services'
import { notificationQueries } from '../queries'

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationId: string) => {
      return api.patch(`/notifications/${notificationId}/mark_as_read`)
    },
    onMutate: async () => await queryClient.cancelQueries({ queryKey: notificationQueries.all() }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: notificationQueries.all() }),
  })
}
