import { DeleteOutlined, ExclamationCircleFilled, FileDoneOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { formatDate, RolePermissions, truncateString } from '@/common'
import { BaseTable, TableAction, TableColumnButton, TooltipTable } from '@/components'
import { Agency, Responsible } from '@/features/agency/types'
import { SelectAgencyActions } from '@/pages'

type AgencyTableProps = {
  data: Agency[] | undefined
  isLoading: boolean
  permissions: RolePermissions
  handleSelectAgency: ({ type, agency }: SelectAgencyActions) => void
}

const AgenciesTable = ({ data, isLoading, permissions, handleSelectAgency }: AgencyTableProps) => {
  const columns: ColumnsType<Agency> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 50,
      render: (name, agency) => (
        <TableColumnButton onClick={() => handleSelectAgency({ agency, type: 'AGENCY_DATA' })}>
          {truncateString(name, 50)}
        </TableColumnButton>
      ),
    },
    {
      title: 'Responsável',
      key: 'responsibles',
      dataIndex: 'responsibles',
      align: 'center',
      width: 100,
      render: (responsibles: Responsible[], agency) => {
        return (
          <TooltipTable
            displayItem={responsibles[0]?.name}
            data={responsibles}
            record={agency}
            columns={[
              {
                title: 'Responsável',
                key: 'name',
                dataIndex: 'name',
                width: 150,
                render: (name) => truncateString(name, 42),
              },
              {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                width: 150,
                render: (email) => truncateString(email, 42),
              },
            ]}
          />
        )
      },
    },
    {
      title: 'Data de Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 125,
      render: (createdAt) => formatDate({ date: createdAt, dateFormat: 'DD/MM/YY HH:mm' }),
    },
    {
      title: 'Data de Atualização',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      width: 125,
      render: (updatedAt) => formatDate({ date: updatedAt, dateFormat: 'DD/MM/YY HH:mm' }),
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      width: 50,
      render: (_, agency) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  icon={<FileDoneOutlined />}
                  type="link"
                  onClick={() => handleSelectAgency({ agency, type: 'AGENCY_DATA' })}
                >
                  Dados da Agência
                </Button>
              </S.ButtonContent>
            ),
          },
          permissions?.isAdmin || permissions?.canDelete
            ? {
                key: '2',
                label: (
                  <S.ButtonContent>
                    <Button
                      danger
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={(e) => {
                        e.preventDefault()
                        confirmDeletion(agency)
                      }}
                    >
                      Excluir
                    </Button>
                  </S.ButtonContent>
                ),
              }
            : null,
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  function confirmDeletion(agency: Agency) {
    Modal.confirm({
      title: `Deseja realmente excluir esta agência?`,
      content: (
        <span>
          A agência <strong>{truncateString(agency.name, 90)}</strong> será excluida
          permanentemente.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
      onOk() {
        handleSelectAgency({ agency, type: 'DELETE_AGENCY' })
      },
    })
  }

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record) => record._id}
      pagination={false}
      loading={isLoading}
    />
  )
}

export { AgenciesTable }
